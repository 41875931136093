import { extendTheme } from "@mui/joy";

export const theme = extendTheme({
  fontFamily: {
    body: "Pretendard Variable",
    display: "Pretendard Variable",
  },
  components: {
    JoyModal: {
      defaultProps: {
        sx: { mt: "var(--Header-height)" },
      },
    },
  },
});
