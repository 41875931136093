import {
  Autocomplete,
  Box,
  Button,
  FormControl,
  FormLabel,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { FormModal } from "components/form/formModals";
import { DateRange, DayPicker } from "react-day-picker";
import { addDays } from "date-fns";
import { ko } from "date-fns/locale";
import { useState } from "react";
import { ArrowRight } from "react-feather";
import { areDatesEqual } from "utils/manageTime";
import toast from "react-hot-toast";
import { createOutstay } from "features/outstay/services";
import { getUserRole, useAuth } from "features/auth";

export default function OutstayApplicationForm(
  props: OutstayApplicationFormProps
) {
  const [dateRange, setDateRange] = useState<DateRange | undefined>(
    initialDateRange
  );
  const [timeRange, setTimeRange] = useState<TimeRange>(initialTimeRange);
  const [reason, setReason] = useState<string>("");

  const [isSubmitting, setIsSubmitting] = useState(false);

  const { user } = useAuth();

  const handleTimeChange = (
    period: "start" | "end",
    variant: "hour" | "minute"
  ) => {
    return (e: React.SyntheticEvent<Element, Event>, value: string | null) => {
      const newValue = value ?? "";

      setTimeRange((prev) => ({
        ...prev,
        [period]: {
          ...prev[period],
          [variant]: +newValue,
        },
      }));
    };
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleApply();
  };

  const handleApply = async () => {
    if (!dateRange?.from) {
      toast.error("외출 날짜를 선택해주세요.");
      return;
    }
    if (!dateRange?.to) {
      toast.error("외출 종료 날짜를 선택해주세요.");
      return;
    }

    const from = new Date(
      dateRange.from.getFullYear(),
      dateRange.from.getMonth(),
      dateRange.from.getDate(),
      timeRange.start.hour,
      timeRange.start.minute
    );
    const to = new Date(
      dateRange.to.getFullYear(),
      dateRange.to.getMonth(),
      dateRange.to.getDate(),
      timeRange.end.hour,
      timeRange.end.minute
    );

    if (from >= to) {
      toast.error("시간을 다시 확인해주세요.");
      return;
    }

    if (!(user && getUserRole(user) === "student")) {
      toast.error("로그아웃 상태이거나, 학생이 아닙니다.");
      return;
    }

    try {
      setIsSubmitting(true);
      await createOutstay({ student: user.id, from, to, reason });
      await props.fireReload();
      toast.success("신청 완료!");
      props.handleClose();
    } catch (error) {
      toast.error("신청에 실패했습니다.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <FormModal
      title="외출·외박 신청"
      {...props}
      actions={[
        <Button
          key="close"
          variant="plain"
          color="neutral"
          onClick={props.handleClose}
        >
          잠시 닫기
        </Button>,
        <Button key="apply" onClick={handleApply} loading={isSubmitting}>
          신청하기
        </Button>,
      ]}
    >
      <Sheet sx={{ overflow: "auto", p: 0.5 }}>
        <form onSubmit={handleSubmit}>
          <Stack gap={2}>
            <FormControl>
              <FormLabel>외출·외박 사유</FormLabel>
              <Autocomplete
                freeSolo
                placeholder="사유 입력..."
                options={sampleReasons}
                onChange={(e, newValue) => setReason(newValue ?? "")}
                value={reason}
              />
            </FormControl>
            <FormControl>
              <FormLabel>외출·외박 날짜</FormLabel>
              <Sheet variant="soft" sx={{ borderRadius: "8px", p: 2 }}>
                <Stack alignItems="center">
                  <DayPicker
                    mode="range"
                    locale={ko}
                    style={{ margin: 0 }}
                    selected={dateRange}
                    onSelect={setDateRange}
                  />
                </Stack>
              </Sheet>
            </FormControl>
            <FormControl>
              <FormLabel>외출·외박 일정</FormLabel>
              <Sheet variant="soft" sx={{ borderRadius: "8px", p: 1 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Stack direction="column">
                    <Typography level="body-xs">
                      {dateRange?.from
                        ? dateRange.from.toLocaleDateString("ko-KR", {
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                        : "????년 ??월 ??일"}
                    </Typography>
                    <Stack direction="row">
                      <Autocomplete
                        required
                        disableClearable
                        freeSolo
                        type="tel"
                        value={timeRange.start.hour.toString().padStart(2, "0")}
                        onChange={handleTimeChange("start", "hour")}
                        options={sampleHours}
                        endDecorator="시"
                        sx={{ width: "70px" }}
                      />
                      <Autocomplete
                        required
                        disableClearable
                        freeSolo
                        type="tel"
                        value={timeRange.start.minute
                          .toString()
                          .padStart(2, "0")}
                        onChange={handleTimeChange("start", "minute")}
                        options={sampleMinutes}
                        endDecorator="분"
                        sx={{ width: "70px" }}
                      />
                    </Stack>
                  </Stack>
                  <Box sx={{ mx: 1 }}>
                    <ArrowRight style={{ marginTop: 18 }} />
                  </Box>
                  <Stack direction="column">
                    <Typography level="body-xs">
                      {" "}
                      {dateRange?.to
                        ? dateRange.from &&
                          areDatesEqual(dateRange.from, dateRange.to)
                          ? "같은 날"
                          : dateRange.to.toLocaleDateString("ko-KR", {
                              year: "numeric",
                              month: "long",
                              day: "numeric",
                            })
                        : "????년 ??월 ??일"}
                    </Typography>
                    <Stack direction="row">
                      <Autocomplete
                        required
                        disableClearable
                        freeSolo
                        type="tel"
                        value={timeRange.end.hour.toString().padStart(2, "0")}
                        onChange={handleTimeChange("end", "hour")}
                        options={sampleHours}
                        endDecorator="시"
                        sx={{ width: "70px" }}
                      />
                      <Autocomplete
                        required
                        disableClearable
                        freeSolo
                        type="tel"
                        value={timeRange.end.minute.toString().padStart(2, "0")}
                        onChange={handleTimeChange("end", "minute")}
                        options={sampleMinutes}
                        endDecorator="분"
                        sx={{ width: "70px" }}
                      />
                    </Stack>
                  </Stack>
                </Stack>
              </Sheet>
            </FormControl>
          </Stack>
        </form>
      </Sheet>
    </FormModal>
  );
}

type OutstayApplicationFormProps = {
  open: boolean;
  handleClose: () => void;
  fireReload: () => Promise<void>;
};

type Time = {
  hour: number;
  minute: number;
};

type TimeRange = {
  start: Time;
  end: Time;
};

const initialTimeRange: TimeRange = {
  start: {
    hour: 16,
    minute: 30,
  },
  end: {
    hour: 22,
    minute: 0,
  },
};

const initialDateRange: DateRange = {
  from: new Date(),
  to: addDays(new Date(), 1),
};

const sampleReasons = ["주말 귀가", "병원 통원", "교외체험학습", "대입 면접"];

const sampleHours = Array.from({ length: 24 }, (_, i) => `${i}`);
const sampleMinutes = ["00", "15", "30", "45"];
