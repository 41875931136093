import { supabase } from "lib/supabase";
import { dateStr2date } from "utils/manageTime";
import type {
  ApplicationConfig,
  ApplicationSessionConfig,
  ApplicationTimeConfig,
  Session,
  TimePeriod,
} from "..";

export async function getApplicationConfig(): Promise<ApplicationConfig> {
  //TODO: Add error handling
  const { data, error } = await supabase
    .from("config")
    .select("value")
    .eq("id", "application")
    .single();

  if (error) throw error;

  const config = data?.value as ApplicationConfig;
  return config;
}

export function getTimePeriod(
  dateStr: string,
  applyTimeConfig: ApplicationTimeConfig
): TimePeriod[] {
  if (applyTimeConfig.custom[dateStr]) return applyTimeConfig.custom[dateStr];

  const day = dateStr2date(dateStr).getDay();
  return applyTimeConfig.default[day];
}

export function getSessionSet(
  dateStr: string,
  sessionConfig: ApplicationSessionConfig
): Session[] {
  const customSessionId = sessionConfig.assign.custom[dateStr];
  if (customSessionId !== undefined)
    return sessionConfig.preset[customSessionId];

  const dayOfToday = dateStr2date(dateStr).getDay();
  const defaultSessionId = sessionConfig.assign.default[dayOfToday];
  return sessionConfig.preset[defaultSessionId];
}

export function getMaxSession(
  dateStr: string,
  sessionConfig: ApplicationSessionConfig
): number {
  const sessionSet = getSessionSet(dateStr, sessionConfig);
  return sessionSet.length;
}
