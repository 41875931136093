import { useEffect, useState } from "react";
import { DateStr } from "typings";
import { supabase } from "lib/supabase";

const defaultQuery = `*, student (*), teacher!application_teacher_fkey(*), room(*), proxy_teacher(*)`;

export const useApplications = (
  id: string,
  dateStr: DateStr,
  query?: string
) => {
  const [applications, setApplications] = useState<
    Record<string, any>[] | undefined
  >(undefined);

  const fetchApplications = async () => {
    const { data, error } = await supabase
      .from("application")
      .select(query ?? defaultQuery)
      .eq("date", dateStr)
      .eq("student", id);

    if (error) throw error;
    setApplications(data);
  };

  useEffect(() => {
    fetchApplications();
  }, [id, dateStr]);

  return { applications, fetchApplications };
};
