import { useEffect, useState } from "react";

export const useNow = () => {
  const [now, setNow] = useState<Date>(new Date());

  useEffect(() => {
    const setTime = () => {
      setNow(new Date());
    };

    const intervalId = setInterval(setTime, 1000);
    return () => clearInterval(intervalId);
  }, []);

  return { now };
};
