import { Box, Button, Checkbox, Link, Stack, Table } from "@mui/joy";
import { getSid } from "features/auth";
import { statusMap } from "features/outstay";
import OutstayStatusChip from "features/outstay/components/OutstayStatusChip";
import { visuallyHidden } from "@mui/utils";

import { OutstayForTeacher } from "features/outstay/types";
import { useMemo, useState } from "react";
import { ArrowDown } from "react-feather";
import toast from "react-hot-toast";
import { arraysAreEqual } from "utils/manageObjects";
import _ from "lodash";

export default function OutstayTable({
  data,
  selected,
  setSelected,
  handleApprovalUpdate,
}: {
  data: OutstayForTeacher[];
  selected: number[];
  setSelected: (ids: number[]) => void;
  handleApprovalUpdate: (
    ids: number[],
    status: "approved" | "rejected"
  ) => Promise<void>;
}) {
  const handleChecked = (checked: boolean, id: number) => {
    if (checked) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter((item) => item !== id));
    }
  };

  const [sortOption, setSortOption] = useState<{
    criteria: string | string[];
    direction: "asc" | "desc";
  }>({ criteria: ["from", "to"], direction: "desc" });
  const handleSortClick = (id: string | string[]) => {
    return () =>
      setSortOption((prev) => {
        if (isSameCriteria(prev.criteria, id)) {
          return {
            ...prev,
            direction: prev.direction === "asc" ? "desc" : "asc",
          };
        }
        return { criteria: id, direction: "asc" };
      });
  };

  const isSameCriteria = (a: string | string[], b: string | string[]) => {
    if (typeof a === "object" && typeof b === "object") {
      return arraysAreEqual(a, b);
    } else if (typeof a === "string" && typeof b === "string") {
      return a === b;
    }
    return false;
  };

  const sortedData = useMemo(() => {
    const baseData = data.sort((a, b) => b.id - a.id);
    return _.orderBy(baseData, sortOption.criteria, sortOption.direction);
  }, [data, sortOption]);

  function OutstayHead() {
    function SortableHeader({
      sortBy,
      label,
      style,
    }: {
      sortBy: string | string[];
      label: string;
      style?: React.CSSProperties;
    }) {
      const active = isSameCriteria(sortOption.criteria, sortBy);
      const order = sortOption.direction;
      return (
        <th
          key={sortBy[0]}
          aria-sort={
            active
              ? ({ asc: "ascending", desc: "descending" } as const)[order]
              : undefined
          }
          style={style}
        >
          <Link
            underline="none"
            color="neutral"
            textColor={active ? "primary.plainColor" : undefined}
            component="button"
            onClick={handleSortClick(sortBy)}
            endDecorator={
              <ArrowDown style={{ opacity: active ? 1 : 0 }} size={18} />
            }
            sx={{
              "& svg": {
                transition: "0.2s",
                transform:
                  active && order === "desc"
                    ? "rotate(0deg)"
                    : "rotate(180deg)",
              },
              "&:hover": { "& svg": { opacity: 1 } },
            }}
          >
            {label}
            {active ? (
              <Box component="span" sx={visuallyHidden}>
                {order === "desc" ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </Link>
        </th>
      );
    }

    return (
      <thead>
        <tr>
          <th style={{ width: 48, textAlign: "center", padding: 12 }}>
            <Checkbox
            // indeterminate={

            // }
            // checked={data.length > 0 && selected.length === data.length}
            // onChange={(event) => {
            //   setSelected(
            //     event.target.checked ? data.map((item) => item.id) : []
            //   );
            // }}
            // color={
            //   selected.length > 0 || selected.length === data.length
            //     ? "primary"
            //     : undefined
            // }
            // sx={{ verticalAlign: "text-bottom" }}
            />
          </th>
          <SortableHeader
            style={{ width: 50, paddingBottom: 12 }}
            sortBy={["student.grade", "student.class", "student.number"]}
            label="학번"
          />
          <SortableHeader
            style={{ width: 70, paddingBottom: 12 }}
            sortBy="student.name"
            label="학생"
          />
          <SortableHeader
            style={{ width: 250, paddingBottom: 12 }}
            sortBy={["from", "to"]}
            label="기간"
          />
          <th style={{ width: 200, paddingBottom: 12 }}>사유</th>
          <th style={{ width: 100, paddingBottom: 12 }}>승인상태</th>
          <th style={{ width: 130, paddingBottom: 12 }}>개별 액션</th>
        </tr>
      </thead>
    );
  }

  return (
    <Table
      aria-labelledby="tableTitle"
      // stripe="even"
      borderAxis="bothBetween"
      stickyHeader
      hoverRow
      sx={{
        "--TableCell-headBackground": (theme) =>
          theme.vars.palette.background.level1,
        "--Table-headerUnderlineThickness": "1px",
        "--TableRow-hoverBackground": (theme) =>
          theme.vars.palette.background.level1,
      }}
    >
      <OutstayHead />
      <tbody>
        {sortedData.map((item) => {
          return (
            <OutstayRow
              key={item.id}
              data={item}
              checked={selected.includes(item.id)}
              handleApprovalUpdate={handleApprovalUpdate}
              handleChecked={handleChecked}
            />
          );
        })}
      </tbody>
    </Table>
  );
}

function OutstayRow({
  data,
  checked,
  handleChecked,
  handleApprovalUpdate,
}: {
  data: OutstayForTeacher;
  checked: boolean;
  handleChecked: (checked: boolean, id: number) => void;
  handleApprovalUpdate: (
    ids: number[],
    status: "approved" | "rejected"
  ) => Promise<void>;
}) {
  const [isUpdating, setIsUpdating] = useState(false);
  async function updateApproval(
    ids: number[],
    status: "approved" | "rejected"
  ) {
    try {
      setIsUpdating(true);
      await handleApprovalUpdate(ids, status);
      toast.success(`${ids.length}건 ${statusMap[status].kor} 완료!`);
    } catch (error) {
      console.error(error);
      toast.error("작업에 실패했습니다");
    } finally {
      setIsUpdating(false);
    }
  }

  function formatTime(date: Date) {
    return date.toLocaleString("ko-KR", {
      month: "short",
      day: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  }

  return (
    <tr key={data.id}>
      <td style={{ textAlign: "center" }}>
        <Checkbox
          checked={checked}
          color="primary"
          onChange={(event) => {
            handleChecked(event.target.checked, data.id);
          }}
          slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
          sx={{ verticalAlign: "text-bottom" }}
        />
      </td>

      <td>{getSid(data.student)}</td>
      <td>{data.student.name}</td>
      <td>
        {formatTime(data.from)} ~ {formatTime(data.to)}
      </td>
      <td>{data.reason}</td>
      <td>
        <OutstayStatusChip status={data.status} />
      </td>
      <td>
        <Stack direction="row" gap={0}>
          <Button
            size="sm"
            variant="solid"
            color="success"
            loading={isUpdating}
            onClick={() => {
              if (data.status !== "approved")
                updateApproval([data.id], "approved");
            }}
            sx={{ mr: 1 }}
          >
            승인
          </Button>
          <Button
            size="sm"
            variant="solid"
            color="danger"
            loading={isUpdating}
            onClick={() => {
              if (data.status !== "rejected")
                updateApproval([data.id], "rejected");
            }}
            sx={{ mr: 1 }}
          >
            거절
          </Button>
        </Stack>
      </td>
    </tr>
  );
}
