import { supabase } from "lib/supabase";
import { OutstayForStudent, OutstayForTeacher } from "../types";

export async function createOutstay(d: {
  student: string;
  from: Date;
  to: Date;
  reason: string;
}) {
  return await supabase.rpc("create_outstay", {
    p_student: d.student,
    p_from: d.from,
    p_to: d.to,
    p_reason: d.reason,
  });
}

export async function removeOutstay(id: number) {
  const { error } = await supabase.from("outstay").delete().eq("id", id);
  if (error) throw error;
}

export async function getOutstaysForTeachers(from?: Date, to?: Date) {
  let query = supabase.from("outstay").select(
    `id, created_at, from, to, reason, status,
      checked_by (name),
      student (name, grade, class, number, avatar)`
  );

  if (from) query = query.gte("from", from);
  if (to) query = query.lte("to", to);

  const { data, error } = await query;

  if (error) throw error;

  const outstays = data.map((item) => ({
    ...item,
    created_at: new Date(item.created_at),
    from: new Date(item.from),
    to: new Date(item.to),
  }));

  return outstays as unknown as OutstayForTeacher[];
}

export async function getOutstaysForStudents(
  student: string
): Promise<OutstayForStudent[]> {
  const { data, error } = await supabase
    .from("outstay")
    .select(`id, created_at, from, to, reason, status, checked_by (name)`)
    .eq("student", student);

  if (error) throw error;

  const outstays = data.map((item) => ({
    ...item,
    created_at: new Date(item.created_at),
    from: new Date(item.from),
    to: new Date(item.to),
  }));

  return outstays as unknown as OutstayForStudent[];
}
