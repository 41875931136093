import {
  Button,
  Card,
  CardContent,
  CardOverflow,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/joy";
import { approvalMap } from "features/application";
import React, { useState } from "react";
import { AlertTriangle, Trash2 } from "react-feather";
import type { Application, Room } from "..";
import type { Teacher } from "features/auth";
import { deleteApplication } from "../services/application";
import _ from "lodash";
import { FormModal } from "components/form/formModals";

type ColorType = "success" | "primary" | "danger";

function DeleteAskModal({
  open,
  handleClose,
  handleDelete,
}: {
  open: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}) {
  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      icon={<AlertTriangle />}
      title="승인된 신청을 삭제하시겠습니까?"
      description="삭제하면 선생님께 알림이 갈 수 있습니다. 그래도 삭제하시겠습니까?"
      actions={[
        <Button
          key="cancel"
          variant="plain"
          color="neutral"
          onClick={handleClose}
        >
          취소
        </Button>,
        <Button
          key="delete"
          color="danger"
          onClick={() => {
            handleClose();
            handleDelete();
          }}
        >
          삭제
        </Button>,
      ]}
    />
  );
}

// function InfoModal({
//   open,
//   handleClose,
//   handleDelete,
//   data,
//   teachers,
//   rooms,
//   isInteractable,
// }: {
//   open: boolean;
//   handleClose: () => void;
//   handleDelete: () => void;
//   data: Application;
//   teachers: Teacher[];
//   rooms: Room[];
//   isInteractable: boolean;
// }) {
//   const teacher = _.find(teachers, { id: data.teacher });
//   const teacherName = teacher ? teacher.name : "선생님 정보 오류";

//   const room = _.find(rooms, { id: data.room });
//   const roomName = room ? room.name : "교실 정보 오류";

//   const proxyTeacher = data.proxy_teacher
//     ? _.find(teachers, { id: data.proxy_teacher })
//     : undefined;

//   const color = approvalMap[data.status].color as ColorType;
//   const icon = React.cloneElement(approvalMap[data.status].icon, { size: 14 });
//   const apporovalKor = approvalMap[data.status].kor;

//   function PropertiesView({
//     title,
//     content,
//   }: {
//     title: string;
//     content: string;
//   }) {
//     return (
//       <Stack>
//         <Typography level="title-sm" fontWeight="bold">
//           {title}
//         </Typography>
//         <Typography level="body-sm">{content}</Typography>
//       </Stack>
//     );
//   }

//   return (
//     <FormModal
//       open={open}
//       handleClose={handleClose}
//       title={
//         <Typography level="h4" mb={1}>
//           <Typography variant="soft">{data.session}교시</Typography>{" "}
//           <Typography
//             endDecorator={
//               <Chip
//                 variant="solid"
//                 color={color}
//                 size="md"
//                 startDecorator={icon}
//               >
//                 {apporovalKor}
//               </Chip>
//             }
//           >
//             {roomName}
//           </Typography>
//         </Typography>
//       }
//       actions={[
//         <Button
//           color="danger"
//           key="delete"
//           disabled={!isInteractable}
//           onClick={() => {
//             handleClose();
//             handleDelete();
//           }}
//         >
//           삭제
//         </Button>,
//         <Button key="close" onClick={handleClose}>
//           닫기
//         </Button>,
//       ]}
//     >
//       <Stack
//         direction="row"
//         gap={1}
//         divider={<Divider orientation="vertical" />}
//       >
//         <Stack direction="column" gap={1} sx={{ width: "50%" }}>
//           <PropertiesView title="활동 지도" content={`${teacherName} 선생님`} />
//           <PropertiesView
//             title="신청 사유"
//             content={data.reason === "" ? "-" : data.reason}
//           />
//           <PropertiesView title="좌석" content="-" />
//         </Stack>
//         <Stack direction="column" gap={1} sx={{ width: "50%" }}>
//           <PropertiesView
//             title="대리신청"
//             content={proxyTeacher ? `${proxyTeacher.name} 선생님` : "-"}
//           />
//           <PropertiesView title="해당 날짜" content={data.date} />
//           <PropertiesView
//             title="신청한 시각"
//             content={new Date(data.created_at).toLocaleString()}
//           />
//         </Stack>
//       </Stack>
//     </FormModal>
//   );
// }

export function EmptyApplicationCard({ session }: { session: number }) {
  return (
    <Card
      orientation="horizontal"
      variant="soft"
      color="neutral"
      sx={{ boxShadow: "none" }}
    >
      <CardOverflow sx={{ pl: 2 }}>
        <Card
          sx={{
            height: "100%",
            minWidth: "50px",
            display: "flex",
            allignItems: "center",
            justifyContent: "center",
            boxShadow: "none",
          }}
          variant="plain"
        >
          <Typography
            level="h2"
            fontWeight="bold"
            textAlign="center"
            textColor="text.tertiary"
          >
            {session}
          </Typography>
        </Card>
      </CardOverflow>
      <CardContent>
        <Typography level="h3" mb={1} textColor="text.tertiary">
          신청 없음
        </Typography>
        <Stack direction="row" gap={1}>
          <Chip
            color="neutral"
            variant="outlined"
            size="md"
            sx={{ color: "text.tertiary" }}
          >
            상태 없음
          </Chip>
          <Chip
            color="neutral"
            variant="outlined"
            size="md"
            sx={{ color: "text.tertiary" }}
          >
            선생님 없음
          </Chip>
        </Stack>
      </CardContent>
    </Card>
  );
}
