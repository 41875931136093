import { useEffect, useState } from "react";
import { supabase } from "lib/supabase";
import toast from "react-hot-toast";
import _ from "lodash";
import { Button, Sheet, Stack } from "@mui/joy";
import { AlertTriangle, PlusSquare } from "react-feather";

import PageTitle from "components/ui/PageTitle";
import { FormModal } from "components/form/formModals";

import { getAllRooms } from "features/application";
import type { Room } from "features/application";
import RoomsTable from "./RoomsTable";
import AddRoomsModal from "./AddroomsModal";
import SeatManageModal from "./SeatManageModal";

export default function AdminApplicationRoomsPage() {
  const [rooms, setRooms] = useState<Room[] | null>(null);
  const [selected, setSelected] = useState<number[]>([]);

  const sortedRooms = rooms
    ? _.sortBy(rooms, ["sector", "number", "name"])
    : null;

  const validSelected = selected.filter((id) => _.find(sortedRooms, { id }));

  const updateRooms = async () => {
    toast.loading("새로고침 중입니다...", { id: "refresh" });
    const rooms = await getAllRooms();
    setRooms(rooms);
    toast.success("새로고침 완료!", { id: "refresh" });
  };

  useEffect(() => {
    updateRooms();
  }, []);

  const [isAddRoomsModalOpen, setIsAddRoomsModalOpen] = useState(false);

  const [deleteTargets, setDeleteTargets] = useState<number[]>([]);
  const handleDelete = async (ids: number[]) => {
    const { error } = await supabase.from("room").delete().in("id", ids);

    if (error) {
      toast.error("교실 삭제에 실패했습니다.");
    } else {
      toast.success("교실을 삭제했습니다.");
    }
    setDeleteTargets([]);

    await updateRooms();
  };

  const [seatModifyTarget, setSeatModifyTarget] = useState<number | null>(null);

  return (
    <>
      <PageTitle
        actions={[
          <Button
            color="primary"
            startDecorator={<PlusSquare />}
            onClick={() => setIsAddRoomsModalOpen(true)}
          >
            교실 추가
          </Button>,
        ]}
      />
      <Sheet
        variant="soft"
        sx={{
          width: "100%",
          borderRadius: "md",
          overflow: "auto",
          minHeight: 0,
          p: 1,
        }}
      >
        <Stack direction="row">
          <Button
            size="sm"
            color="danger"
            onClick={() => setDeleteTargets(validSelected)}
          >
            선택 삭제
          </Button>
        </Stack>
      </Sheet>
      <Sheet
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "md",
          flexGrow: 1,
          overflowY: "auto",
          minHeight: 0,
        }}
      >
        {sortedRooms ? (
          <RoomsTable
            data={sortedRooms}
            selected={validSelected}
            setSelected={setSelected}
            setDeleteTargets={setDeleteTargets}
            setSeatModifyTarget={setSeatModifyTarget}
            fireUpdate={updateRooms}
          />
        ) : (
          <div>불러오는 중...</div>
        )}
      </Sheet>
      <FormModal
        title="교실을 삭제하시겠습니까?"
        open={deleteTargets.length > 0}
        icon={<AlertTriangle />}
        description={`교실 ${deleteTargets.length}개를 삭제하시겠습니까? 교실이 삭제되면, 해당 교실로 신청한 학생들의 신청 기록도 모두 삭제됩니다. 이 작업은 되돌릴 수 없습니다.`}
        actions={[
          <Button
            color="neutral"
            key="cancel"
            variant="plain"
            onClick={() => setDeleteTargets([])}
          >
            취소
          </Button>,
          <Button
            color="danger"
            key="delete"
            onClick={() => handleDelete(deleteTargets)}
          >
            삭제
          </Button>,
        ]}
      />
      <AddRoomsModal
        open={isAddRoomsModalOpen}
        handleClose={() => setIsAddRoomsModalOpen(false)}
        fireUpdate={updateRooms}
      />
      {seatModifyTarget && (
        <SeatManageModal
          room={_.find(sortedRooms, { id: seatModifyTarget })!}
          fireReload={updateRooms}
          handleClose={() => setSeatModifyTarget(null)}
        />
      )}
    </>
  );
}
