import {
  Alert,
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { router } from "Router";
import { supabase } from "lib/supabase";
import { useState } from "react";
import toast from "react-hot-toast";

export default function UpdatePassword() {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState<null | string>(null);

  const handlePasswordUpdate = async () => {
    setError(null);

    const { error } = await supabase.auth.updateUser({
      password: newPassword,
    });

    if (error) {
      setError(error.message);
    } else {
      toast.success("비밀번호가 변경되었습니다");
      router.navigate("/");
    }
  };

  return (
    <Stack
      sx={{
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100%",
      }}
    >
      <Card variant="outlined" sx={{ m: 2 }}>
        <Typography level="h3">비밀번호 변경</Typography>
        <Divider />
        {error && <Alert color="danger">{error}</Alert>}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handlePasswordUpdate();
          }}
        >
          <Stack>
            <FormControl>
              <FormLabel>새 비밀번호</FormLabel>
              <Input
                type="password"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </FormControl>
          </Stack>
          <Stack
            direction="row"
            gap={1}
            sx={{ justifyContent: "flex-end", mt: 1 }}
          >
            <Button onClick={() => router.navigate("/")} variant="outlined">
              취소
            </Button>
            <Button type="submit">변경</Button>
          </Stack>
        </form>
      </Card>
    </Stack>
  );
}
