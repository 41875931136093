import { Button, Sheet } from "@mui/joy";
import ProtectedRotue from "features/auth/components/ProtectedRotue";
import NotFound from "pages/general/NotFound";
import ForgotPassword from "pages/general/ForgotPassword";
import RootLanding from "pages/general/RootLanding";
import SignIn from "pages/general/SignIn";
import AdminApplicationRoomsPage from "pages/admin/application/rooms";
import AdminApplicationConfigPage from "pages/admin/application/config";
import { Navigate, Outlet, createBrowserRouter } from "react-router-dom";
import StudentLayout from "layouts/StudentLayout";
import AdminLayout from "layouts/AdminLayout";
import TeacherLayout from "layouts/TeacherLayout";
import StudentApplicationManage from "pages/student/application/manage";
import TeacherApplicationManage from "pages/teacher/application/manage";
import TeacherApplicationAttendance from "pages/teacher/application/attendance";
import TeacherApplicaionProxy from "pages/teacher/application/proxy";
import StudentOutstayManage from "pages/student/outstay/manage/StudentOutstayManage";
import TeacherOutstayManage from "pages/teacher/outstay/manage/TeacherOutstayManage";
import StudentDashBoard from "pages/student/dashboard/StudentDashboard";
import UpdatePassword from "pages/general/UpdatePassword";
import AdminStudentPage from "pages/admin/account/student/AdminStudentPage";
import TeacherOutstayCurrent from "pages/teacher/outstay/current/TeacherOutstayCurrent";
import { FormModal } from "components/form/formModals";
import { AlertTriangle } from "react-feather";

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Sheet sx={{ height: "100vh", display: "flex", flexDirection: "column" }}>
        <Outlet />
      </Sheet>
    ),
    children: [
      {
        path: "/",
        element: <RootLanding />,
      },
      {
        path: "signin",
        element: <SignIn />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "update-password",
        element: <UpdatePassword />,
      },
      {
        path: "student",
        element: <ProtectedRotue role="student" />,
        children: [
          {
            path: "",
            element: <StudentLayout />,
            children: [
              {
                path: "dashboard",
                element: <StudentDashBoard />,
              },
              {
                path: "application",
                children: [
                  { path: "manage", element: <StudentApplicationManage /> },
                ],
              },
              {
                path: "outstay",
                children: [
                  { path: "manage", element: <StudentOutstayManage /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "teacher",
        element: <ProtectedRotue role="teacher" />,
        children: [
          {
            path: "",
            element: <TeacherLayout />,
            children: [
              {
                path: "dashboard",
                element: <Navigate to="/teacher/application/manage" />,
              },
              {
                path: "application",
                children: [
                  { path: "manage", element: <TeacherApplicationManage /> },
                  { path: "proxy", element: <TeacherApplicaionProxy /> },
                  {
                    path: "attendance",
                    element: <TeacherApplicationAttendance />,
                  },
                ],
              },
              {
                path: "outstay",
                children: [
                  { path: "manage", element: <TeacherOutstayManage /> },
                  { path: "current", element: <TeacherOutstayCurrent /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "admin",
        element: <ProtectedRotue role="service_role" />,
        children: [
          {
            path: "",
            element: <AdminLayout />,
            children: [
              {
                path: "dashboard",
                element: <Navigate to="/admin/account/students" />,
              },
              {
                path: "account",
                children: [
                  { path: "students", element: <AdminStudentPage /> },
                  { path: "teachers", element: <AdminApplicationConfigPage /> },
                ],
              },
              {
                path: "application",
                children: [
                  { path: "rooms", element: <AdminApplicationRoomsPage /> },
                  { path: "config", element: <AdminApplicationConfigPage /> },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);
