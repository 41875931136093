import { Student } from "features/auth";
import { supabase } from "lib/supabase";
import { useEffect, useState } from "react";

export function useAllStudents() {
  const [students, setStudents] = useState<Student[]>([]);

  useEffect(() => {
    loadStudents();
  }, []);

  const loadStudents = async () => {
    const { data, error } = await supabase.from("student").select("*");
    if (!error && data) setStudents(data);
  };

  return { students, loadStudents };
}
