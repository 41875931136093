import { Alert, IconButton } from "@mui/joy";
import { useAuth } from "features/auth";
import { LogOut } from "react-feather";

export default function ProfileFallback() {
  const { signOut } = useAuth();

  return (
    <Alert
      color="danger"
      endDecorator={
        <IconButton
          variant="plain"
          onClick={(e) => {
            e.stopPropagation();
            signOut();
          }}
        >
          <LogOut />
        </IconButton>
      }
    >
      사용자를 불러오지 못했습니다.
    </Alert>
  );
}
