import { useEffect, useState } from "react";
import { Sheet } from "@mui/joy";
import PageTitle from "components/ui/PageTitle";
import { Content } from "components/ui/content";
import OutstayTable from "./OutstayTable";
import { BaseOutstay } from "features/outstay/types";
import { getOutstaysForTeachers } from "features/outstay";
import { supabase } from "lib/supabase";

export default function TeacherOutstayManage() {
  const [baseOutstays, setBaseOutstays] = useState<BaseOutstay[]>([]);

  const [selected, setSelected] = useState<number[]>([]);

  const [loadingOutstays, setLoadingOutstays] = useState(false);
  useEffect(() => {
    loadOutstays();
  }, []);

  const loadOutstays = async () => {
    setLoadingOutstays(true);
    const outstays = await getOutstaysForTeachers();
    setBaseOutstays(outstays);
    setLoadingOutstays(false);
  };

  const handleApprovalUpdate = async (
    ids: number[],
    status: "approved" | "rejected"
  ) => {
    await supabase.from("outstay").update({ status }).in("id", ids);
    await loadOutstays();
  };

  return (
    <>
      <PageTitle />
      <Content>
        <Sheet
          variant="outlined"
          sx={{ borderRadius: "8px", flex: 1, overflow: "auto" }}
        >
          <OutstayTable
            data={baseOutstays}
            handleApprovalUpdate={handleApprovalUpdate}
            selected={selected}
            setSelected={setSelected}
          />
        </Sheet>
      </Content>
    </>
  );
}
