import { Button, Grid } from "@mui/joy";
import DataCard from "components/form/DataCard";

import { FiExternalLink, FiStar } from "react-icons/fi";

const links = [
  {
    title: "학생관리시스템",
    url: "https://www.cbshself.kr/",
  },
  {
    title: "컴시간학생",
    url: "http://xn--s39aj90b0nb2xw6xh.kr/",
  },
  {
    title: "학교 홈페이지",
    url: "https://school.cbe.go.kr/cbs-h/M01",
  },
  {
    title: "상벌점시스템",
    url: "http://cbs.smileschool.kr/green/",
  },
];

export default function LinksCard() {
  return (
    <DataCard title="유용한 링크" icon={<FiStar />}>
      <Grid container spacing={1} columns={1}>
        {links.map(({ title, url }) => (
          <Grid xs={1} key={title}>
            <Button
              variant="outlined"
              fullWidth
              endDecorator={<FiExternalLink />}
              onClick={() => {
                window.open(url, "_blank", "noreferrer");
              }}
            >
              {title}
            </Button>
          </Grid>
        ))}
      </Grid>
    </DataCard>
  );
}
