import type { TimePeriod } from "features/application";
import { DateStr } from "typings";

export function areDatesEqual(date1: Date, date2: Date) {
  return (
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth() &&
    date1.getFullYear() === date2.getFullYear()
  );
}

export function dateDiffInDays(date1: Date, date2: Date): number {
  const msPerDay = 1000 * 60 * 60 * 24;
  const ms1 = new Date(
    date1.getFullYear(),
    date1.getMonth(),
    date1.getDate()
  ).getTime();
  const ms2 = new Date(
    date2.getFullYear(),
    date2.getMonth(),
    date2.getDate()
  ).getTime();

  return Math.floor((ms2 - ms1) / msPerDay);
}

export function isTimeBetween(timeStr: string, periods: TimePeriod[]): boolean {
  const [START, END] = [0, 1];
  const time = +timeStr;

  for (const period of periods) {
    if (+period[START] <= time && time < +period[END]) {
      return true;
    }
  }

  return false;
}

export function time2str(time: Date): string {
  const hour = time.getHours();
  const minute = time.getMinutes();
  return `${hour < 10 ? "0" + hour : hour}${
    minute < 10 ? "0" + minute : minute
  }`;
}

export function date2str(time: Date): DateStr {
  const year = time.getFullYear().toString().padStart(4, "0");
  const month = (time.getMonth() + 1).toString().padStart(2, "0");
  const date = time.getDate().toString().padStart(2, "0");
  return `${year}${month}${date}`;
}

export function dateStr2date(dateStr: string): Date {
  const year = +dateStr.slice(0, 4);
  const month = +dateStr.slice(4, 6) - 1;
  const date = +dateStr.slice(6);
  return new Date(year, month, date);
}

export function dateStr2pgDate(dateStr: string): string {
  const year = +dateStr.slice(0, 4);
  const month = +dateStr.slice(4, 6);
  const date = +dateStr.slice(6);
  return `${year}-${month < 10 ? "0" + month : month}-${
    date < 10 ? "0" + date : date
  }`;
}

export function numberToKoreanDayOfWeek(number: number) {
  const dayOfWeek = ["일", "월", "화", "수", "목", "금", "토"];
  return dayOfWeek[number];
}

export function secondsToKorean(inputSeconds: number) {
  const hours = Math.floor(inputSeconds / 3600);
  const minutes = Math.floor((inputSeconds % 3600) / 60);

  return (hours > 0 ? `${hours}시간 ` : "") + `${minutes}분`;
}
