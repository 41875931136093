import {
  Button,
  Checkbox,
  CircularProgress,
  IconButton,
  Input,
  Select,
  Stack,
  Table,
  Option,
} from "@mui/joy";
import { Room } from "features/application";
import { supabase } from "lib/supabase";
import _ from "lodash";
import { useState } from "react";
import { Check, X } from "react-feather";
import toast from "react-hot-toast";

export default function RoomsTable({
  data,
  selected,
  setSelected,
  setDeleteTargets,
  setSeatModifyTarget,
  fireUpdate,
}: {
  data: Room[];
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  setDeleteTargets: React.Dispatch<React.SetStateAction<number[]>>;
  setSeatModifyTarget: React.Dispatch<React.SetStateAction<number | null>>;
  fireUpdate: () => void;
}) {
  const [editCell, setEditCell] = useState<
    {
      id: number;
      column: EditableColumn;
    }[]
  >([]);
  const handleEditCell = (id: number, column: EditableColumn) => {
    if (_.find(editCell, { id, column })) {
      return;
    } else {
      setEditCell((prev) => prev.concat({ id, column }));
    }
  };

  const EditableTd = ({
    id,
    column,
    value,
    type,
  }: {
    id: number;
    column: EditableColumn;
    value: string | number;
    type: "string" | "number";
  }) => {
    const [newValue, setNewValue] = useState<string | number>(value);
    const [isLoading, setIsLoading] = useState(false);

    const handleUpdate = async () => {
      setIsLoading(true);

      const { error } = await supabase
        .from("room")
        .update({ [column]: newValue })
        .eq("id", id);

      if (error) {
        toast.error("정보 변경에 실패했습니다.");
      } else {
      }

      fireUpdate();
      setIsLoading(false);
    };

    if (_.find(editCell, { id, column })) {
      return (
        <td>
          <form
            onSubmit={async (e) => {
              e.preventDefault();
              await handleUpdate();
              setEditCell((prev) =>
                prev.filter((each) => !_.isEqual(each, { id, column }))
              );
            }}
          >
            <Input
              size="sm"
              sx={{ minWidth: "120px" }}
              type={type === "number" ? "number" : "text"}
              value={newValue}
              onChange={(e) => {
                setNewValue(e.target.value);
              }}
              disabled={isLoading}
              endDecorator={
                isLoading ? (
                  <CircularProgress size="sm" />
                ) : (
                  <Stack direction="row" gap={1}>
                    <IconButton
                      color="danger"
                      variant="solid"
                      onClick={(e) => {
                        e.stopPropagation();
                        setEditCell((prev) =>
                          prev.filter(
                            (each) => !_.isEqual(each, { id, column })
                          )
                        );
                      }}
                    >
                      <X size={16} />
                    </IconButton>
                    <IconButton
                      color="success"
                      variant="solid"
                      type="submit"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <Check size={16} />
                    </IconButton>
                  </Stack>
                )
              }
            />
          </form>
        </td>
      );
    } else {
      return <td onClick={() => handleEditCell(id, column)}>{value}</td>;
    }
  };

  return (
    <>
      <Table
        aria-labelledby="tableTitle"
        borderAxis="bothBetween"
        stickyHeader
        hoverRow
        sx={{
          "--TableCell-headBackground": (theme) =>
            theme.vars.palette.background.level1,
          "--Table-headerUnderlineThickness": "1px",
          "--TableRow-hoverBackground": (theme) =>
            theme.vars.palette.background.level1,
        }}
      >
        <thead>
          <tr>
            <th style={{ width: 48, textAlign: "center", padding: 12 }}>
              <Checkbox
                indeterminate={
                  selected.length > 0 && data.length > selected.length
                }
                checked={data.length > 0 && selected.length === data.length}
                onChange={(event) => {
                  setSelected(
                    event.target.checked ? data.map((item) => item.id) : []
                  );
                }}
                color={
                  selected.length > 0 || selected.length === data.length
                    ? "primary"
                    : undefined
                }
                sx={{ verticalAlign: "text-bottom" }}
              />
            </th>
            <th style={{ width: 130, paddingBottom: 12 }}>교실</th>
            <th style={{ width: 80, paddingBottom: 12 }}>구역</th>
            <th style={{ width: 70, paddingBottom: 12 }}>호실번호</th>
            <th style={{ width: 70, paddingBottom: 12 }}>수용인원</th>
            <th style={{ width: 80, paddingBottom: 12 }}>지정좌석제</th>
            <th style={{ width: 150, paddingBottom: 12 }}>개별 액션</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item) => {
            return (
              <tr>
                <td style={{ textAlign: "center" }}>
                  <Checkbox
                    checked={selected.includes(item.id)}
                    color={selected.includes(item.id) ? "primary" : undefined}
                    onChange={(event) => {
                      setSelected((ids) =>
                        event.target.checked
                          ? ids.concat(item.id)
                          : ids.filter((eachId) => eachId !== item.id)
                      );
                    }}
                    slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                    sx={{ verticalAlign: "text-bottom" }}
                  />
                </td>
                <EditableTd
                  id={item.id}
                  column="name"
                  type="string"
                  value={item.name}
                />
                <EditableTd
                  id={item.id}
                  column="sector"
                  type="string"
                  value={item.sector}
                />
                <EditableTd
                  id={item.id}
                  column="number"
                  type="number"
                  value={item.number}
                />
                {item.seat_selection ? (
                  <td
                    onClick={() =>
                      toast.error(
                        "지정좌석제 교실의 정원은 좌석 배치를 통해 수정 가능합니다."
                      )
                    }
                  >
                    {item.capacity}
                  </td>
                ) : (
                  <EditableTd
                    id={item.id}
                    column="capacity"
                    type="number"
                    value={item.capacity}
                  />
                )}
                <td onClick={() => handleEditCell(item.id, "seat_selection")}>
                  {_.find(editCell, {
                    id: item.id,
                    column: "seat_selection",
                  }) ? (
                    <SeatSelectionEdit
                      roomId={item.id}
                      defaultValue={item.seat_selection}
                      handleClose={() =>
                        setEditCell((prev) =>
                          prev.filter(
                            (each) =>
                              !_.isEqual(each, {
                                id: item.id,
                                column: "seat_selection",
                              })
                          )
                        )
                      }
                      fireReload={fireUpdate}
                    />
                  ) : item.seat_selection ? (
                    "예"
                  ) : (
                    "아니오"
                  )}
                </td>
                <td>
                  <Stack direction="row" gap={0}>
                    <Button
                      size="sm"
                      variant="solid"
                      color="danger"
                      onClick={() => setDeleteTargets([item.id])}
                      sx={{ mr: 1 }}
                    >
                      삭제
                    </Button>
                    {item.seat_selection && (
                      <Button
                        size="sm"
                        variant="solid"
                        color="warning"
                        onClick={() => setSeatModifyTarget(item.id)}
                        sx={{ mr: 1 }}
                      >
                        좌석 배치
                      </Button>
                    )}
                  </Stack>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
}

function SeatSelectionEdit({
  roomId,
  defaultValue,
  handleClose,
  fireReload,
}: {
  roomId: number;
  defaultValue: boolean;
  handleClose: () => void;
  fireReload: () => void;
}) {
  const [value, setValue] = useState(defaultValue);
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    if (defaultValue !== value) {
      setLoading(true);

      const zeroCapacity = { capacity: 0 };
      const { error } = await supabase
        .from("room")
        .update({ seat_selection: value, ...(value ? zeroCapacity : null) })
        .eq("id", roomId);

      if (error) {
        toast.error("정보 변경에 실패했습니다.");
      } else {
        if (value)
          toast(
            "수용 인원이 0명으로 초기화되었습니다. 교실에 좌석을 배치해주세요.",
            { duration: 10000 }
          );
      }

      setLoading(false);
    }
    fireReload();
    handleClose();
  };

  return (
    <Select
      size="sm"
      value={value}
      onChange={(_, newValue) => {
        setValue(!!newValue);
      }}
      endDecorator={
        loading ? (
          <CircularProgress size="sm" />
        ) : (
          <Stack direction="row" gap={1}>
            <IconButton
              color="danger"
              variant="solid"
              onClick={(e) => {
                e.stopPropagation();
                handleClose();
              }}
            >
              <X size={16} />
            </IconButton>
            <IconButton
              color="success"
              variant="solid"
              onClick={async (e) => {
                e.stopPropagation();
                handleSubmit();
              }}
            >
              <Check size={16} />
            </IconButton>
          </Stack>
        )
      }
    >
      <Option value={true}>예</Option>
      <Option value={false}>아니오</Option>
    </Select>
  );
}

type EditableColumn =
  | "name"
  | "sector"
  | "number"
  | "capacity"
  | "seat_selection";
