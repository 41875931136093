import "index.css";
import "react-day-picker/dist/style.css";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import "lib/fcm-sw";

import React, { useEffect } from "react";
import ReactDOM from "react-dom/client";
import { CssVarsProvider } from "@mui/joy";
import { theme } from "assets/muiTheme";
import { Toaster } from "react-hot-toast";
import { RouterProvider } from "react-router-dom";
import { router } from "Router";
import { AuthProvider } from "features/auth/context/AuthContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <AuthProvider>
      <App />
    </AuthProvider>
  </React.StrictMode>
);


function App() {
  useEffect(() => {
    (async () => {
      if ("serviceWorker" in navigator) {
        // navigator.serviceWorker
        //   .register("./firebase-messaging-sw.js")
        //   .then(function (registration) {
        //     console.log("Registration was successful: ", registration);
        //   })
        //   .catch(function (e) {
        //     console.error("Registration has filed", e);
        //   });
      }
    })();
  }, []);
  return (
    <CssVarsProvider theme={theme} defaultMode="light">
      <RouterProvider router={router} />
      <Toaster position="top-right" />
    </CssVarsProvider>
  );
}

// serviceWorkerRegistration.register();
