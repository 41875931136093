import { Box, CssBaseline, GlobalStyles } from "@mui/joy";
import React from "react";
import { Outlet } from "react-router-dom";
import MobileHeader from "./MobileHeader";

export default function MenuLayout({
  sidebar,
  breadcrumbs,
}: {
  sidebar: React.ReactNode;
  breadcrumbs: React.ReactNode;
}) {
  return (
    <Box>
      <GlobalStyles
        styles={{
          "[data-feather], .feather": {
            color: "var(--Icon-color)",
            margin: "var(--Icon-margin)",
            fontSize: "var(--Icon-fontSize, 20px)",
            width: "1em",
            height: "1em",
          },
        }}
      />
      <CssBaseline />
      <Box sx={{ display: "flex", height: "100dvh" }}>
        <MobileHeader />
        {sidebar}
        <Box
          component="main"
          className="MainContent"
          sx={(theme) => ({
            "--main-paddingTop": {
              xs: `calc(${theme.spacing(2)} + var(--Header-height, 0px))`,
              md: "32px",
            },
            px: {
              xs: 2,
              md: 3,
            },
            pt: "var(--main-paddingTop)",
            pb: {
              xs: 2,
              sm: 2,
              md: 3,
            },
            flex: 1,
            display: "flex",
            flexDirection: "column",
            minWidth: 0,
            height: "100dvh",
            gap: 1,
            overflow: "auto",
          })}
        >
          <Box
            sx={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              minWidth: 0,
              height: "parent",
              gap: 1,
            }}
          >
            <Box sx={{ display: "flex", alignItems: "center", flex: 0 }}>
              {breadcrumbs}
            </Box>
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                minWidth: 0,
                height: "parent",
                gap: 1,
              }}
            >
              <Outlet />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
