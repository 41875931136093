import type { MenuCategory } from "typings";

export function getLocationFromPath(menu: MenuCategory[], path: string) {
  for (const item of menu) {
    if (item.path === path) {
      return [item.name];
    } else if (item.children) {
      for (const child of item.children) {
        if (child.path === path) {
          return [item.name, child.name];
        }
      }
    }
  }
  return null;
}
