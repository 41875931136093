import { RealtimeChannel } from "@supabase/supabase-js";
import { LockedSeat } from "features/application";
import { supabase } from "lib/supabase";
import { useCallback, useEffect, useRef, useState } from "react";
import { DateStr } from "typings";

export function useLockedSeats(roomId: number, dateStr: DateStr) {
  const [lockedSeats, setLockedSeats] = useState<LockedSeat[]>([]);
  const handleRefresh = useCallback(async () => {
    const lockedSeats = await getLockedSeats(roomId, dateStr);
    setLockedSeats(lockedSeats);
  }, [roomId]);

  const channelRef = useRef<RealtimeChannel | null>(null);
  useEffect(() => {
    setLockedSeats([]);
    handleRefresh();

    const channel = supabase
      .channel(`room_seat_lock_${roomId}`)
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "room_seat_lock",
          filter: `room=eq.${roomId}`,
        },
        () => {
          handleRefresh();
        }
      )
      .subscribe();
    channelRef.current = channel;

    return () => {
      if (channelRef.current) {
        setLockedSeats([]);
        supabase.removeChannel(channelRef.current);
        channelRef.current = null;
      }
    };
  }, [roomId]);

  return { lockedSeats, handleRefresh };
}

const getLockedSeats = async (roomId: number, dateStr: DateStr) => {
  const { data, error } = await supabase
    .from("room_seat_lock")
    .select("*, seat(id, room)")
    .eq("seat.room", roomId)
    .eq("date", dateStr);

  if (error) throw error;
  const sanitizedData = data.map((seat) => {
    return { ...seat, locked_at: new Date(seat.locked_at) };
  });

  return sanitizedData as LockedSeat[];
};
