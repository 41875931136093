import {
  Button,
  Grid,
  LinearProgress,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import PageTitle from "components/ui/PageTitle";
import { Content } from "components/ui/content";
import { useAuth } from "features/auth/context/AuthContext";
import { getOutstaysForStudents } from "features/outstay";
import OutstayApplicationForm from "features/outstay/components/OutstayApplicationForm";
import OutstayCard from "features/outstay/components/OutstayCard";
import { OutstayForStudent } from "features/outstay/types";
import { useEffect, useState } from "react";
import { PlusSquare } from "react-feather";

export default function StudentOutstayManage() {
  const { user } = useAuth();

  const [isApplictionFormOpen, setIsApplicationFormOpen] = useState(false);
  const [outstays, setOutstays] = useState<OutstayForStudent[]>([]);
  const [isLoadingOutstays, setIsLoadingOutstays] = useState(false);

  useEffect(() => {
    loadOutstays();
  }, []);

  const loadOutstays = async () => {
    setIsLoadingOutstays(true);
    const outstays = await getOutstaysForStudents(user!.id);
    setOutstays(outstays);
    setIsLoadingOutstays(false);
  };

  return (
    <>
      <PageTitle
        actions={[
          <Button
            startDecorator={<PlusSquare />}
            onClick={() => setIsApplicationFormOpen(true)}
            disabled={isApplictionFormOpen}
          >
            신청하기
          </Button>,
        ]}
      />
      <OutstayApplicationForm
        open={isApplictionFormOpen}
        handleClose={() => setIsApplicationFormOpen(false)}
        fireReload={() => loadOutstays()}
      />
      <Content>
        <Sheet variant="soft" sx={{ borderRadius: "8px", p: 2, flex: 1 }}>
          <Grid container gap={1}>
            {outstays.length > 0 ? (
              outstays.map((outstay) => {
                return (
                  <Grid key={outstay.id}>
                    <OutstayCard
                      {...outstay}
                      fireReload={() => loadOutstays()}
                    />
                  </Grid>
                );
              })
            ) : isLoadingOutstays ? (
              <LinearProgress />
            ) : (
              <Stack direction="column">
                <Typography level="body-sm">
                  외출·외박 신청 내역이 없습니다.
                </Typography>
              </Stack>
            )}
          </Grid>
        </Sheet>
      </Content>
    </>
  );
}
