import {
  Button,
  Chip,
  ColorPaletteProp,
  List,
  ListItem,
  ListItemButton,
  Sheet,
  Step,
  StepIndicator,
  Stepper,
  ToggleButtonGroup,
  Typography,
  VariantProp,
} from "@mui/joy";
import { FormModal } from "components/form/formModals";
import { Room } from "features/application/types";
import _ from "lodash";
import { useMemo, useState } from "react";
import { FormModalProps } from "typings";

export function RoomSelectModal(props: FormModalProps & RoomSelectModalProps) {
  const { rooms, room, handleSelect, handleClose } = props;

  const [sector, setSector] = useState<string | null>(null);
  const [floor, setFloor] = useState<number | null>(null);

  const sectors = useMemo(() => {
    const sectors = rooms.map((room) => room.sector);
    return _.sortedUniq(sectors);
  }, [rooms]);

  const floors = useMemo(() => {
    const floors = new Set<number>();
    rooms.forEach((room) => {
      floors.add(Math.floor(room.number / 100));
    });

    return Array.from(floors).sort((a, b) => a - b);
  }, [rooms]);

  const filteredFloors = useMemo(() => {
    if (sector === null) return floors;
    return floors.filter((floor) => {
      return rooms.some((room) => {
        return (
          room.sector === sector && Math.floor(room.number / 100) === floor
        );
      });
    });
  }, [sector, floors, rooms]);

  const filteredRooms = useMemo(() => {
    let filteredRooms: Room[] = [...rooms];
    if (sector !== null) {
      filteredRooms = filteredRooms.filter((room) => room.sector === sector);
    }
    if (floor !== null) {
      filteredRooms = filteredRooms.filter(
        (room) => Math.floor(room.number / 100) === floor
      );
    }
    return filteredRooms;
  }, [sector, floor, rooms]);

  return (
    <FormModal
      {...props}
      actions={[<Button onClick={handleClose}>닫기</Button>]}
    >
      <Sheet sx={{ maxHeight: "70vh", overflow: "auto", py: 1 }}>
        <Stepper orientation="vertical">
          <CustomStep indicator={1} title="구역 선택" highlight={!!sector}>
            <Sheet
              variant="outlined"
              sx={{ borderRadius: "md", display: "flex", gap: 2, p: 0.5 }}
            >
              <ToggleButtonGroup
                variant="soft"
                size="sm"
                spacing={0.5}
                value={sector}
                onChange={(_, newValue) => {
                  setSector(newValue);
                }}
              >
                {sectors.map((sector) => (
                  <Button value={sector} key={sector}>
                    {sector}
                  </Button>
                ))}
              </ToggleButtonGroup>
            </Sheet>
          </CustomStep>
          <CustomStep indicator={2} title="층 선택" highlight={floor !== null}>
            <Sheet
              variant="outlined"
              sx={{
                borderRadius: "md",
                overflow: "auto",
                display: "flex",
                gap: 2,
                p: 0.5,
              }}
            >
              <ToggleButtonGroup
                variant="plain"
                size="sm"
                spacing={0.5}
                value={`${floor}`}
                onChange={(_, newValue) => {
                  setFloor(newValue === null ? null : +newValue);
                }}
              >
                {floors.map((floor) => (
                  <Button
                    value={`${floor}`}
                    key={floor}
                    disabled={!filteredFloors.includes(floor)}
                  >
                    {floor === 0 ? "기타" : `${floor}`}
                  </Button>
                ))}
              </ToggleButtonGroup>
            </Sheet>
          </CustomStep>
          <CustomStep indicator={3} title="교실 선택" highlight={!!room}>
            <Sheet
              variant="outlined"
              sx={{ borderRadius: "5px", overflow: "auto", maxHeight: "30vh" }}
            >
              <List>
                {filteredRooms.map((item) => (
                  <ListItem>
                    <ListItemButton
                      {...(item.id === room?.id ? selectedStyle : undefined)}
                      onClick={() => handleSelect(item)}
                    >
                      {item.name}
                      {item.seat_selection && (
                        <Chip color="warning" size="sm" variant="soft">
                          좌석
                        </Chip>
                      )}
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
            </Sheet>
          </CustomStep>
        </Stepper>
      </Sheet>
    </FormModal>
  );
}

function CustomStep({
  indicator,
  title,
  highlight,
  children,
}: {
  indicator: string | number;
  title: string;
  highlight?: boolean;
  children: React.ReactNode;
}) {
  const variant = highlight ? "solid" : undefined;
  return (
    <Step
      indicator={
        <StepIndicator variant={variant} color="primary">
          {indicator}
        </StepIndicator>
      }
    >
      <Typography>{title}</Typography>
      {children}
    </Step>
  );
}

type RoomSelectModalProps = {
  rooms: Room[];
  room: Room | null;
  handleSelect: (room: Room) => void;
};

const selectedStyle = {
  color: "primary" as ColorPaletteProp,
  variant: "solid" as VariantProp,
};
