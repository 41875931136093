import {
  Button,
  Card,
  CardContent,
  CardOverflow,
  Chip,
  Divider,
  Stack,
  Typography,
} from "@mui/joy";
import {
  Application,
  approvalMap,
  deleteApplication,
} from "features/application";
import { FiTrash2 } from "react-icons/fi";
import { useState } from "react";
import { FormModal } from "components/form/formModals";
import { IApplication } from "./StudentApplicationManage";
import { useObjects, useSeats } from "pages/admin/application/rooms/hooks";
import { RoomSeatModal } from "features/application/components/RoomSeatModal";

export default function ApplicationView({
  data,
  interactable,
  fireReload,
}: {
  data: IApplication;
  interactable: boolean;
  fireReload: () => void;
}) {
  const [isDeleting, setIsDeleteing] = useState(false);
  const [isInfoOpen, setIsInfoOpen] = useState(false);

  const { session, teacher, room, status } = data;
  const approval = approvalMap[status];

  const handleDelete = async () => {
    setIsDeleteing(true);
    await deleteApplication(data.id);
    fireReload();
  };

  const handleInfoClick = () => {
    setIsInfoOpen(true);
  };

  return (
    <>
      <Card
        orientation="horizontal"
        variant="outlined"
        sx={{ boxShadow: "none" }}
        onClick={handleInfoClick}
      >
        <CardOverflow sx={{ pl: 2 }}>
          <Card
            sx={{
              height: "100%",
              width: "50px",
              display: "flex",
              allignItems: "center",
              justifyContent: "center",
              boxShadow: "none",
            }}
            variant="soft"
            color={approval.color}
          >
            <Typography level="h2" fontWeight="bold" textAlign="center">
              {session}
            </Typography>
          </Card>
        </CardOverflow>
        <CardContent>
          <Typography level="h3" mb={1}>
            {room.name}
          </Typography>
          <Stack direction="row" gap={1}>
            <Chip
              variant="soft"
              color={approval.color}
              size="md"
              startDecorator={approval.icon}
            >
              {approval.kor}
            </Chip>
            <Chip color="neutral" variant="outlined" size="md">
              {teacher.name}
            </Chip>
          </Stack>
        </CardContent>
        <CardOverflow>
          <Stack direction="row" sx={{ pr: 2 }} gap={1}>
            <Button
              color="danger"
              variant="soft"
              size="sm"
              loading={isDeleting}
              disabled={!interactable}
              onClick={(e) => {
                e.stopPropagation();
                handleDelete();
              }}
            >
              <FiTrash2 size={20} />
            </Button>
          </Stack>
        </CardOverflow>
      </Card>
      <InfoModal
        open={isInfoOpen}
        handleClose={() => setIsInfoOpen(false)}
        handleDelete={handleDelete}
        data={data}
        interactable={interactable}
      />
    </>
  );
}

function InfoModal({
  open,
  data,
  interactable,
  handleClose,
  handleDelete,
}: {
  open: boolean;
  data: IApplication;
  interactable: boolean;
  handleClose: () => void;
  handleDelete: () => void;
}) {
  const { seats } = useSeats(data.room.id);
  const { objects } = useObjects(data.room.id);
  const [isRoomSeatModalOpen, setIsRoomSeatModalOpen] = useState(false);

  const approval = approvalMap[data.status];

  function PropertiesView({
    title,
    content,
    action,
  }: {
    title: string;
    content: string;
    action?: React.ReactNode;
  }) {
    return (
      <Stack>
        <Typography level="title-sm" fontWeight="bold">
          {title}
        </Typography>
        <Typography level="body-sm" endDecorator={action}>
          {content}
        </Typography>
      </Stack>
    );
  }

  return (
    <>
      <FormModal
        open={open}
        handleClose={handleClose}
        title={
          <Typography level="h4" mb={1}>
            <Typography variant="soft">{data.session}교시</Typography>{" "}
            <Typography
              endDecorator={
                <Chip
                  variant="solid"
                  color={approval.color}
                  size="md"
                  startDecorator={approval.icon}
                >
                  {approval.kor}
                </Chip>
              }
            >
              {data.room.name}
            </Typography>
          </Typography>
        }
        actions={[
          data.seat && (
            <Button
              key="seat"
              color="neutral"
              loading={seats && objects ? false : true}
              onClick={() => {
                setIsRoomSeatModalOpen(true);
              }}
            >
              좌석 보기
            </Button>
          ),
          <Button
            color="danger"
            key="delete"
            disabled={!interactable}
            onClick={() => {
              handleClose();
              handleDelete();
            }}
          >
            삭제
          </Button>,
          <Button key="close" onClick={handleClose}>
            닫기
          </Button>,
        ]}
      >
        <Stack
          direction="row"
          gap={1}
          divider={<Divider orientation="vertical" />}
        >
          <Stack direction="column" gap={1} sx={{ width: "50%" }}>
            <PropertiesView
              title="활동 지도"
              content={`${data.teacher.name} 선생님`}
            />
            <PropertiesView
              title="신청 사유"
              content={data.reason === "" ? "-" : data.reason}
            />
            <PropertiesView title="좌석" content={data.seat?.name ?? "-"} />
          </Stack>
          <Stack direction="column" gap={1} sx={{ width: "50%" }}>
            <PropertiesView
              title="대리신청"
              content={
                data.proxy_teacher ? `${data.proxy_teacher.name} 선생님` : "-"
              }
            />
            <PropertiesView title="해당 날짜" content={data.date} />
            <PropertiesView
              title="신청한 시각"
              content={new Date(data.created_at).toLocaleString()}
            />
          </Stack>
        </Stack>
      </FormModal>

      {seats && objects && (
        <RoomSeatModal
          open={isRoomSeatModalOpen}
          onClose={() => setIsRoomSeatModalOpen(false)}
          room={data.room}
          seats={seats}
          objects={objects}
        />
      )}
    </>
  );
}
