import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Button,
  Stack,
  Typography,
} from "@mui/joy";
import { accordionDetailsClasses } from "@mui/joy/AccordionDetails";
import { accordionSummaryClasses } from "@mui/joy/AccordionSummary";

import { FormModal, ModalDataView } from "components/form/formModals";
import { Info } from "react-feather";
import type { ApplicationConfig, TimePeriod } from "..";
import { date2str, numberToKoreanDayOfWeek } from "utils/manageTime";
import { getMaxSession, getTimePeriod } from "../services/config";

const formatTimeStr = (time: number) => {
  const hour = String(Math.floor(time / 100)).padStart(2, "0");
  const minute = String(time % 100).padStart(2, "0");
  return `${hour}:${minute}`;
};

const formatDateStr = (date: string) => {
  const month = date.slice(4, 6);
  const day = date.slice(6, 8);
  return `${month}/${day}`;
};

export function ConfigView({
  open,
  handleClose,
  maxSession,
  applyPeriods,
  config,
}: {
  open: boolean;
  handleClose: () => void;
  maxSession: number;
  applyPeriods: TimePeriod[];
  config: ApplicationConfig;
}) {
  const today = new Date();

  return (
    <FormModal
      open={open}
      handleClose={handleClose}
      title="신청 유의사항"
      icon={<Info />}
      actions={[
        <Button onClick={handleClose} key="close">
          닫기
        </Button>,
      ]}
    >
      <Stack spacing={2} sx={{ overflow: "auto" }}>
        <ModalDataView title="신청 시간대">
          <Stack spacing={1}>
            {applyPeriods.map((period, index) => {
              const [start, end] = period;
              return (
                <Typography
                  level="body-md"
                  color="primary"
                  variant="soft"
                  key={index}
                >
                  {formatTimeStr(start)} - {formatTimeStr(end)}
                </Typography>
              );
            })}
          </Stack>
        </ModalDataView>
        <ModalDataView title="배정된 교시">
          <Stack
            direction="row"
            spacing={1}
            flexWrap="wrap"
            useFlexGap
            pl={0.5}
          >
            {maxSession > 0 ? (
              Array.from({ length: maxSession }, (_, i) => i + 1).map(
                (session) => (
                  <Typography
                    level="body-md"
                    color="primary"
                    variant="soft"
                    mr={0.5}
                    key={session}
                  >
                    {session}교시
                  </Typography>
                )
              )
            ) : (
              <Typography
                level="body-md"
                color="danger"
                variant="soft"
                mr={0.5}
              >
                배정 없음
              </Typography>
            )}
          </Stack>
        </ModalDataView>
        <ModalDataView title="다른 날의 설정 보기">
          <OtherDaysView today={today} config={config} />
        </ModalDataView>
      </Stack>
    </FormModal>
  );
}

function OtherDaysView({
  today,
  config,
}: {
  today: Date;
  config: ApplicationConfig;
}) {
  const dateDeltas = [1, 2, 3, 4, 5, 6, 7];
  return (
    <AccordionGroup
      variant="outlined"
      sx={{
        borderRadius: "sm",
        [`& .${accordionSummaryClasses.button}:hover`]: {
          bgcolor: "transparent",
        },
        [`& .${accordionDetailsClasses.content}`]: {
          boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
          [`&.${accordionDetailsClasses.expanded}`]: {
            paddingBlock: "0.75rem",
          },
        },
      }}
    >
      {dateDeltas.map((delta) => {
        const targetDate = new Date(today);
        targetDate.setDate(targetDate.getDate() + delta);

        const dayOfWeek = targetDate.getDay();
        const dateStr = date2str(targetDate);

        const applyPeriods = getTimePeriod(dateStr, config.applyTime);
        const maxSession = getMaxSession(dateStr, config.session);

        return (
          <Accordion key={delta}>
            <AccordionSummary>
              {formatDateStr(dateStr)} ({numberToKoreanDayOfWeek(dayOfWeek)})
            </AccordionSummary>
            <AccordionDetails>
              <b>신청 시간대</b>
              {applyPeriods.map((period, index) => {
                const [start, end] = period;
                return (
                  <Typography color="primary" variant="soft" key={index}>
                    {formatTimeStr(start)} - {formatTimeStr(end)}
                    <br />
                  </Typography>
                );
              })}
              <div style={{ height: "10px" }} />
              <b>배정된 교시</b>
              <Stack direction="row" spacing={1} flexWrap="wrap" useFlexGap>
                {maxSession > 0 ? (
                  Array.from({ length: maxSession }, (_, i) => i + 1).map(
                    (session) => (
                      <Typography
                        level="body-md"
                        color="primary"
                        variant="soft"
                        mr={0.5}
                        key={session}
                      >
                        {session}교시
                      </Typography>
                    )
                  )
                ) : (
                  <Typography
                    level="body-md"
                    color="danger"
                    variant="soft"
                    mr={0.5}
                  >
                    배정 없음
                  </Typography>
                )}
              </Stack>
            </AccordionDetails>
          </Accordion>
        );
      })}
    </AccordionGroup>
  );
}
