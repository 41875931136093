export function getSid({
  grade,
  class: classNo,
  number,
}: {
  grade: number;
  class: number;
  number: number;
}) {
  const gradeStr = grade.toString().padStart(1, "0").slice(-1);
  const classStr = classNo.toString().padStart(1, "0").slice(-1);
  const numberStr = number.toString().padStart(2, "0").slice(-2);

  return `${gradeStr}${classStr}${numberStr}`;
}
