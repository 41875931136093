import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  FormControl,
  FormLabel,
  LinearProgress,
  Stack,
  Typography,
  styled,
} from "@mui/joy";
import { router } from "Router";
import { FormModal } from "components/form/formModals";
import { Student, useAuth } from "features/auth";
import { supabase } from "lib/supabase";
import { ChangeEvent, useState } from "react";
import toast from "react-hot-toast";
import { Progress } from "typings";

export function AccountSettings({ student }: { student: Student }) {
  const { refreshProfile } = useAuth();
  const [isChangingImage, setIsChangingImage] = useState<Progress | null>(null);
  const [isImagePreviewOpen, setIsImagePreviewOpen] = useState(false);

  const handleImageChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (isChangingImage) return;
    if (!(e.target.files && e.target.files[0])) {
      toast.error("이미지를 선택해주세요");
      return;
    }

    try {
      setIsChangingImage(imageChangeProgress["prepare"]);
      const target = e.target.files[0];

      const targetNames = target.name.split(".");
      const targetExt = targetNames[targetNames.length - 1];
      const name = `${student.id}_${new Date().toISOString()}.${targetExt}`;

      if (student.avatar) {
        setIsChangingImage(imageChangeProgress["remove"]);
        const { error: removeError } = await supabase.storage
          .from("avatar")
          .remove([student.avatar]);

        if (removeError) {
          throw removeError;
        }
      }

      setIsChangingImage(imageChangeProgress["upload"]);
      const { error: uploadError } = await supabase.storage
        .from("avatar")
        .upload(name, target);

      if (uploadError) {
        throw uploadError;
      }

      setIsChangingImage(imageChangeProgress["reflect"]);
      const { error: reflectError } = await supabase
        .from("student")
        .update({ avatar: name })
        .eq("id", student.id);

      if (reflectError) {
        throw reflectError;
      }

      setIsChangingImage(imageChangeProgress["refresh"]);
      await refreshProfile();

      setIsChangingImage(imageChangeProgress["finish"]);
      toast.success("이미지 변경 완료!");
    } catch (error) {
      setIsChangingImage((prev) => ({ ...prev!, error: true }));
      console.error(error);
    }
  };

  const imageUrl = student.avatar
    ? supabase.storage.from("avatar").getPublicUrl(student.avatar, {
        transform: {
          width: 240,
          height: 240,
          resize: "cover",
        },
      }).data.publicUrl
    : undefined;

  return (
    <>
      <Stack gap={1}>
        <Typography
          level="title-md"
          fontWeight="bold"
          mb={1}
          startDecorator={
            <Box
              sx={{
                width: "10px",
                height: "10px",
                borderRadius: "99px",
                bgcolor: "primary.300",
              }}
            />
          }
        >
          계정
        </Typography>
        <Stack gap={1}>
          <FormControl>
            <FormLabel>프로필</FormLabel>
            <Card variant="soft" orientation="horizontal">
              <Avatar
                onClick={() => setIsImagePreviewOpen(true)}
                variant="outlined"
                src={!isChangingImage ? imageUrl : undefined}
                sx={{ height: "100px", width: "100px" }}
              />
              <CardContent>
                <Typography level="title-lg" id="card-description">
                  {student.name} 학생
                </Typography>
                <Typography
                  level="body-sm"
                  aria-describedby="card-description"
                  mb={1}
                >
                  {/* <Link
                    overlay
                    underline="none"
                    href="#interactive-card"
                    sx={{ color: 'text.tertiary' }}
                  >
                </Link> */}
                  {student.grade}학년 {student.class}반 {student.number}번
                </Typography>
                <Box sx={{ height: "1px" }}></Box>
                <Button
                  component="label"
                  role={undefined}
                  tabIndex={-1}
                  disabled={!!isChangingImage}
                >
                  사진 변경
                  <HiddenImageSelect
                    type="file"
                    accept="image/jpeg, image/png"
                    name="profile_img"
                    onChange={handleImageChange}
                  />
                </Button>
              </CardContent>
            </Card>
            <Typography level="body-xs">
              *이름, 학번 수정은 선생님께 문의해주세요
            </Typography>
          </FormControl>
          <FormControl>
            <FormLabel>비밀번호</FormLabel>
            <Button onClick={() => router.navigate("/update-password")}>
              비밀번호 변경하기
            </Button>
          </FormControl>
        </Stack>
      </Stack>
      <FormModal
        open={!!isChangingImage}
        closeButton={!!isChangingImage?.error}
        handleClose={
          isChangingImage?.error ? () => setIsChangingImage(null) : undefined
        }
      >
        <Stack>
          <Stack
            direction="row"
            alignItems="flex-end"
            justifyContent="space-between"
          >
            <Typography fontWeight="bold">
              {isChangingImage?.error ? "오류 발생" : isChangingImage?.label}
            </Typography>
            <Typography level="body-xs">
              {isChangingImage?.progress}%
            </Typography>
          </Stack>
          <LinearProgress
            determinate
            value={isChangingImage?.progress}
            color={isChangingImage?.error ? "danger" : undefined}
          />
          <div style={{ height: "10px" }} />
          {!isChangingImage?.error && (
            <Typography level="body-xs">
              처리 완료시까지 페이지를 닫지 마세요!
            </Typography>
          )}
        </Stack>
      </FormModal>
      <FormModal
        open={isImagePreviewOpen}
        handleClose={() => setIsImagePreviewOpen(false)}
        closeButton
      >
        <img src={imageUrl} alt="프로필 사진" />
      </FormModal>
    </>
  );
}

const HiddenImageSelect = styled("input")`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const imageChangeProgress: { [key: string]: Progress } = {
  prepare: {
    label: "준비중...",
    progress: 10,
  },
  remove: {
    label: "기존 사진 삭제중...",
    progress: 30,
  },
  upload: {
    label: "신규 사진 전송중...",
    progress: 60,
  },
  reflect: {
    label: "사진 등록중...",
    progress: 80,
  },
  refresh: {
    label: "마무리하는중...",
    progress: 99,
  },
  finish: {
    label: "완료!",
    progress: 100,
  },
};
