import {
  Box,
  ButtonGroup,
  IconButton,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import DataCard from "components/form/DataCard";
import { useState } from "react";
import { FiArrowLeft, FiArrowRight, FiInfo } from "react-icons/fi";
import { MdOutlineFastfood } from "react-icons/md";

export default function MealCard() {
  const [showAllergy, setShowAllergy] = useState(false);
  const handleShowAllergy = () => setShowAllergy((prev) => !prev);

  const currentMeal = [
    {
      name: "차슈덮밥",
      allergy: [1, 5, 5, 10, 13],
    },
    {
      name: "유부국",
      allergy: [5, 6],
    },
    {
      name: "마라떡볶이",
      allergy: [1, 2, 5, 6, 10, 12, 13, 15, 16, 18],
    },
    {
      name: "단무지",
      allergy: [],
    },
    {
      name: "아이스크림(콘-바닐라)",
      allergy: [1, 2, 4, 5, 6],
    },
  ];

  return (
    <DataCard
      title="급식"
      icon={<MdOutlineFastfood />}
      action={
        <ButtonGroup size="sm" variant="soft" color="primary">
          <IconButton>
            <FiArrowLeft />
          </IconButton>
          <IconButton
            variant={showAllergy ? "solid" : undefined}
            onClick={handleShowAllergy}
          >
            <FiInfo />
          </IconButton>
          <IconButton>
            <FiArrowRight />
          </IconButton>
        </ButtonGroup>
      }
    >
      {/* <>
        <Typography level="title-sm">2023년 11월 7일 화요일 석식</Typography>
        <Sheet variant="soft" sx={{ p: 1, borderRadius: "5px" }}>
          {currentMeal.map(({ name, allergy }) => {
            const allergyText = "(" + allergy.join(".") + ")";
            return (
              <Typography fontSize={14} key={name}>
                {name}
                {showAllergy && allergy.length > 0 && allergyText}
              </Typography>
            );
          })}
        </Sheet>
      </> */}

      <Box
        style={{
          height: "100%",
          width: "100%",
          background: `repeating-linear-gradient(
      -45deg,
      #e3effb,
      #e3effb 5px,
      #ffffff 5px,
      #ffffff 10px
    )`,
        }}
      >
        <Stack
          sx={{ height: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            level="body-md"
            variant="soft"
            textAlign="center"
            color="primary"
          >
            개발 예정
          </Typography>
        </Stack>
      </Box>
    </DataCard>
  );
}
