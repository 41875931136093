import { createClient } from "@supabase/supabase-js";

const supabase_url = process.env.REACT_APP_SUPABASE_URL;
const service_role_key = process.env.REACT_APP_SUPABASE_SERVICE_ROLE_KEY;

if (!supabase_url || !service_role_key) {
  throw new Error(
    "Cannot connect to Supabase. Contact the administrator. 서버와 연결하지 못했습니다. 관리자에게 문의하세요."
  );
}

const supabase = createClient(supabase_url, service_role_key, {
  auth: {
    autoRefreshToken: false,
    persistSession: false,
  },
});

// Access auth admin api
export const adminClient = supabase;
export const adminAuthClient = supabase.auth.admin;
