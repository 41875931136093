import { Autocomplete, CircularProgress } from "@mui/joy";
import { Teacher, useTeachers } from "features/auth";
import { Dispatch, SetStateAction } from "react";

export function TeacherSelect(props: TeacherSelectProps) {
  const { teacher, setTeacher } = props;

  const { teachers } = useTeachers();

  return (
    <Autocomplete
      placeholder="검색"
      options={teachers ?? []}
      getOptionLabel={(option) => option.name}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      value={teacher}
      endDecorator={
        teachers === undefined && (
          <CircularProgress size="sm" sx={{ bgcolor: "background.surface" }} />
        )
      }
      onChange={(_, newValue) => setTeacher(newValue)}
    />
  );
}

type TeacherSelectProps = {
  teacher: Teacher | null;
  setTeacher: Dispatch<SetStateAction<Teacher | null>>;
};
