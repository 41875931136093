import PageTitle from "components/ui/PageTitle";
import { Content } from "components/ui/content";
import { Sheet, Tab, TabList, TabPanel, Tabs, Typography } from "@mui/joy";
import { useEffect, useState } from "react";
import type { ApplicationConfig } from "features/application";
import { getApplicationConfig } from "features/application";
import { FormModal } from "components/form/formModals";
import ManageTimePeriod from "./ManageTimePeriod";

export default function AdminApplicationConfigPage() {
  const [config, setConfig] = useState<ApplicationConfig | undefined>(
    undefined
  );

  const loadConfig = async () => {
    setConfig(undefined);
    const config = await getApplicationConfig();
    setConfig(config);
  };

  useEffect(() => {
    loadConfig();
  }, []);

  return (
    <>
      <PageTitle />
      <Content>
        {config ? (
          <Sheet
            variant="outlined"
            sx={{
              width: "100%",
              borderRadius: "md",
              flex: 1,
              overflow: "auto",
              minHeight: 0,
            }}
          >
            <Tabs defaultValue={1}>
              <TabList>
                <Tab>신청시간 설정</Tab>
                <Tab>교시 배정</Tab>
              </TabList>
              <TabPanel value={0}>
                {/* <ManageTimePeriod
                  config={config}
                  fireReload={() => loadConfig()}
                /> */}

                <Typography>
                  교시 배정 매커니즘이 변경되어 다시 개발중입니다.
                  <br />
                  <br />
                  최홍제에게 연락해주시면 수동으로 변경해드릴께요!
                </Typography>
              </TabPanel>
              <TabPanel value={1}>
                <Typography>
                  교시 배정 매커니즘이 변경되어 다시 개발중입니다.
                  <br />
                  <br />
                  최홍제에게 연락해주시면 수동으로 변경해드릴께요!
                </Typography>
              </TabPanel>
            </Tabs>
          </Sheet>
        ) : (
          <FormModal title="불러오는 중..." loading zIndex={999} />
        )}
      </Content>
    </>
  );
}
