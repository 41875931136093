import {
  Button,
  ButtonGroup,
  Card,
  CardOverflow,
  Grid,
  List,
  ListItem,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { router } from "Router";
import DataCard from "components/form/DataCard";
import { baseMenu } from "data/menu";
import {
  ApplicationConfig,
  ApplicationDefaultInputs,
  ApplicationStatus,
  Room,
  approvalMap,
  getApplicationConfig,
  getMaxSession,
  getTimePeriod,
} from "features/application";
import ApplicationForm from "features/application/components/ApplicationForm/ApplicationForm";
import { Teacher, useAuth } from "features/auth";
import { supabase } from "lib/supabase";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import toast from "react-hot-toast";
import { FiCodepen, FiPlusSquare, FiSliders } from "react-icons/fi";
import { date2str, isTimeBetween, time2str } from "utils/manageTime";

const fastApplicationSample: { teacher: Teacher; room: Room }[] = [
  {
    teacher: {
      name: "최승재",
      id: "ae001717-e561-4862-b314-cbeb610a57e4",
      homeroom: 0,
      avatar: "",
    },
    room: {
      name: "정보세미나실",
      id: 96,
      capacity: 10,
      sector: "본관",
      number: 414,
      seat_selection: true,
      room_size: { x: 20, y: 20 },
    },
  },
  {
    teacher: {
      name: "최승재",
      id: "ae001717-e561-4862-b314-cbeb610a57e4",
      homeroom: 0,
      avatar: "",
    },
    room: {
      name: "인공지능 랩2",
      id: 129,
      capacity: 10,
      sector: "본관",
      number: 414,
      seat_selection: true,
      room_size: { x: 20, y: 20 },
    },
  },
];

export default function ApplicationCard() {
  const now = new Date();
  const dateStr = date2str(now);
  const { user } = useAuth();

  const [config, setConfig] = useState<ApplicationConfig | undefined>(
    undefined
  );
  useEffect(() => {
    loadApplicationConfig();
  }, []);
  const loadApplicationConfig = async () => {
    const config = await getApplicationConfig();
    setConfig(config);
  };

  const isApplyable = useMemo(() => {
    if (!config) return false;
    const applyPeriods = getTimePeriod(date2str(now), config.applyTime);
    return isTimeBetween(time2str(now), applyPeriods);
  }, [config, now]);

  const maxSessions = useMemo(() => {
    if (!config) return undefined;
    return getMaxSession(dateStr, config.session);
  }, [config, dateStr]);

  const [applications, setApplications] = useState<
    undefined | null | MinimalApplication[]
  >(undefined);
  useEffect(() => {
    loadApplications();
  }, [user, dateStr]);
  const loadApplications = async () => {
    if (!user) {
      setApplications(null);
      return;
    }
    const { data, error } = await supabase
      .from("application")
      .select(`id, session, status, room (name)`)
      .eq("date", dateStr)
      .eq("student", user.id);
    if (error) {
      toast.error("특별실 신청 내역 불러오기 오류");
      setApplications(null);
    } else setApplications(data as unknown as MinimalApplication[]);
  };

  const [isApplicationModalOpen, setIsApplicationModalOpen] = useState(false);
  const [defaultInputs, setDefaultInputs] = useState<
    ApplicationDefaultInputs | undefined
  >(undefined);

  const handleFormOpen = () => {
    setDefaultInputs(undefined);
    setIsApplicationModalOpen(true);
  };

  function FastApplicationButton({
    teacher,
    room,
  }: {
    teacher: Teacher;
    room: Room;
  }) {
    return (
      <Button
        variant="soft"
        startDecorator={<FiPlusSquare />}
        key={room.name + teacher.name}
        onClick={() => {
          setDefaultInputs({
            teacher,
            room,
          });
          setIsApplicationModalOpen(true);
        }}
      >
        {room.name}({teacher.name})
      </Button>
    );
  }

  return (
    <>
      <DataCard
        title={baseMenu.application.name}
        icon={<FiCodepen />}
        sx={{ maxHeight: { xs: "600px", sm: "350px" } }}
        action={
          <ButtonGroup spacing={1} size="sm" color="primary" variant="soft">
            {isApplyable ? (
              <Button onClick={handleFormOpen}>신청하기</Button>
            ) : (
              <Button disabled>신청 불가</Button>
            )}
            <Button
              onClick={() => router.navigate("/student/application/manage")}
            >
              열기
            </Button>
          </ButtonGroup>
        }
      >
        <Grid container spacing={2} columns={{ xs: 1, sm: 2 }}>
          <Grid xs={1}>
            <Typography level="title-sm">오늘 사용할 특별실</Typography>
            <Sheet
              sx={{
                borderRadius: "5px",
                overflow: "auto",
                maxHeight: "250px",
              }}
            >
              {maxSessions && applications ? (
                <List size="sm" sx={{ borderRadius: "5px" }}>
                  {Array.from({ length: maxSessions }, (_, i) => i + 1).map(
                    (session) => {
                      const application = _.find(applications, { session });
                      const color = application
                        ? approvalMap[application.status].color
                        : "neutral";
                      return (
                        <ListItem sx={{ px: 0 }} key={session}>
                          <Stack
                            direction="row"
                            sx={{ width: "100%" }}
                            gap={0.5}
                          >
                            <Card
                              variant="outlined"
                              sx={{ p: 1, width: "30px" }}
                              color={color}
                            >
                              <CardOverflow sx={{ p: 0.5 }}>
                                <Typography
                                  fontWeight="bold"
                                  textAlign="center"
                                  fontSize={20}
                                >
                                  {session}
                                </Typography>
                              </CardOverflow>
                            </Card>
                            {application ? (
                              <Card
                                variant="soft"
                                sx={{ p: 1, flex: 1 }}
                                color={color}
                              >
                                <CardOverflow sx={{ p: 1 }}>
                                  <Typography noWrap>
                                    {application.room.name}
                                  </Typography>
                                </CardOverflow>
                              </Card>
                            ) : (
                              <Button
                                color={color}
                                variant="soft"
                                sx={{ flex: 1 }}
                                onClick={() => {
                                  const defaultSession = Array.from(
                                    { length: maxSessions },
                                    () => false
                                  );
                                  defaultSession[session - 1] = true;
                                  setDefaultInputs({ session: defaultSession });
                                  setIsApplicationModalOpen(true);
                                }}
                              >
                                <FiPlusSquare size={20} />
                              </Button>
                            )}
                          </Stack>
                        </ListItem>
                      );
                    }
                  )}
                </List>
              ) : (
                <Typography level="body-sm" textAlign="center">
                  불러오는 중...
                </Typography>
              )}
            </Sheet>
          </Grid>
          <Grid xs={1}>
            <Typography level="title-sm">빠른 신청</Typography>
            <Sheet
              variant="soft"
              invertedColors
              sx={{
                borderRadius: "5px",
                overflow: "auto",
                maxHeight: "250px",
                p: 1,
              }}
            >
              <Stack gap={1}>
                {/* {fastApplicationSample.map(({ teacher, room }) => (
                  <FastApplicationButton teacher={teacher} room={room} />
                ))} */}

                <Button variant="outlined" startDecorator={<FiSliders />}>
                  빠른 신청 관리 (개발중)
                </Button>
              </Stack>
            </Sheet>
          </Grid>
        </Grid>
      </DataCard>
      {maxSessions && (
        <ApplicationForm
          open={isApplyable && isApplicationModalOpen}
          handleClose={() => setIsApplicationModalOpen(false)}
          fireReload={() => loadApplications()}
          initialData={{ config }}
          defaultInputs={defaultInputs}
        />
      )}
    </>
  );
}

type MinimalApplication = {
  id: number;
  session: number;
  status: ApplicationStatus;
  room: {
    name: string;
  };
};
