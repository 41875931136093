import {
  Alert,
  Autocomplete,
  Button,
  FormControl,
  FormLabel,
  Sheet,
  Stack,
} from "@mui/joy";
import { FormModal } from "components/form/formModals";
import { useState, useEffect } from "react";
import { Codepen } from "react-feather";
import {
  type Room,
  ApplicationInitialData,
  ApplicationDefaultInputs,
  RoomSelect,
  getSessionSet,
  exampleApplicationReasons,
  useApplications,
} from "features/application";
import { date2str } from "utils/manageTime";
import toast from "react-hot-toast";
import _ from "lodash";
import { Teacher, getUserRole, useAuth } from "features/auth";
import { useConfig } from "features/application/hooks/useConfig";
import { TeacherSelect } from "features/auth/components/TeacherSelect";
import { supabase } from "lib/supabase";
import SessionSelect from "../SessionSelect/SessionSelect";
export default function ApplicationForm({
  open,
  handleClose,
  fireReload,
  initialData,
  defaultInputs,
}: {
  open: boolean;
  handleClose: () => void;
  fireReload?: () => void;
  initialData?: ApplicationInitialData;
  defaultInputs?: ApplicationDefaultInputs;
}) {
  const { user } = useAuth();
  const { config } = useConfig();

  const dateStr = date2str(new Date());
  const { applications, fetchApplications } = useApplications(
    user!.id,
    dateStr,
    "session"
  );

  const [room, setRoom] = useState<Room | null>(null);
  const [teacher, setTeacher] = useState<Teacher | null>(null);
  const [session, setSession] = useState<(number | boolean)[]>([]);
  const [reason, setReason] = useState<string>("");
  const [lockedSession, setLockedSession] = useState<number[]>([]);

  useEffect(() => {
    fetchApplications();
  }, [open]);

  useEffect(() => {
    if (applications) {
      const lockedSession = [] as number[];
      applications.forEach((application) => {
        lockedSession.push(application.session);
      });
      setLockedSession(lockedSession);

      const newSession = [...session];
      lockedSession.forEach((session) => {
        newSession[session - 1] = false;
      });
      setSession(newSession);
    }
  }, [applications]);

  const resetInputs = () => {
    setRoom(null);
    setTeacher(null);
    setReason("");
    if (config) {
      const sessionSet = getSessionSet(dateStr, config.session);
      setSession(Array(sessionSet.length).fill(false));
    }
  };

  useEffect(() => {
    if (config) {
      const sessionSet = getSessionSet(dateStr, config.session);
      setSession(Array(sessionSet.length).fill(false));
    }
  }, [config, dateStr]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    if (!room) return toast.error("교실을 선택해주세요");
    if (!teacher) return toast.error("선생님을 선택해주세요");
    if (reason.length === 0) return toast.error("사유를 입력해주세요");
    if (!session.some((value) => value))
      return toast.error("교시를 선택해주세요");
    if (!(user && getUserRole(user) === "student"))
      return toast.error("학생 계정이어야 합니다. 다시 로그인해보세요.");

    setIsSubmitting(true);
    const baseApplication = {
      date: dateStr,
      student: user.id,
      teacher: teacher.id,
      room: room.id,
      reason,
    };

    const promises: any[] = [];
    session.forEach((value, i) => {
      if (value) {
        const session = i + 1;
        const application = {
          ...baseApplication,
          seat: typeof value === "number" ? value : null,
          session,
        };

        promises.push(supabase.from("application").insert(application));
      }
    });
    const results = await Promise.all(promises);

    const failed = results.filter((result) => result.error);
    if (failed.length) {
      toast.error(`신청 실패가 ${failed.length}건 있습니다`);
    } else resetInputs();

    setIsSubmitting(false);
    handleClose();
    fireReload?.();
    fetchApplications();
  };

  return (
    <>
      <FormModal
        open={open}
        icon={<Codepen />}
        title="특별실 사용 신청서"
        actions={[
          <Button
            onClick={handleClose}
            color="neutral"
            variant="plain"
            key="close"
          >
            닫기
          </Button>,
          <Button onClick={handleSubmit} key="submit" loading={isSubmitting}>
            전송
          </Button>,
        ]}
      >
        <Sheet sx={{ overflow: "auto", p: 0.5 }}>
          <Stack direction="column" gap={2}>
            <FormControl>
              <FormLabel>교실</FormLabel>
              <RoomSelect room={room} setRoom={(room) => setRoom(room)} />
            </FormControl>
            <FormControl>
              <FormLabel>선생님</FormLabel>
              <TeacherSelect teacher={teacher} setTeacher={setTeacher} />
            </FormControl>
            <FormControl>
              <FormLabel>교시</FormLabel>
              {config ? (
                room ? (
                  <SessionSelect
                    selection={session}
                    setSelection={setSession}
                    lockedSelection={lockedSession}
                    dateStr={dateStr}
                    room={room}
                  />
                ) : (
                  <Alert color="primary">교실을 선택해주세요</Alert>
                )
              ) : (
                <Alert color="warning">데이터를 불러오는 중입니다</Alert>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>사유</FormLabel>
              <Autocomplete
                freeSolo
                placeholder="선택 / 직접 입력"
                options={exampleApplicationReasons}
                inputValue={reason}
                onChange={(_, newValue) => setReason(newValue ?? "")}
                onInputChange={(_, newValue) => setReason(newValue ?? "")}
              />
            </FormControl>
          </Stack>
        </Sheet>
      </FormModal>
    </>
  );
}
