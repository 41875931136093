import { Chip } from "@mui/joy";
import { statusMap } from "features/outstay/data";
import { OutstayStatus } from "features/outstay/types";
import { cloneElement } from "react";

export default function OutstayStatusChip({
  status,
  size = "md",
}: {
  status: OutstayStatus;
  size?: "sm" | "md" | "lg";
}) {
  const icon = cloneElement(statusMap[status].icon, {
    size: size === "md" ? 16 : size === "sm" ? 14 : 18,
  });
  return (
    <Chip color={statusMap[status].color} startDecorator={icon} size={size}>
      {statusMap[status].kor}
    </Chip>
  );
}
