import { User } from "@supabase/supabase-js";
import { adminAuthClient } from "lib/supabaseAdmin";
import { useEffect, useState } from "react";

export function useAllUsers() {
  const [users, setUsers] = useState<User[]>([]);

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    const {
      data: { users },
      error,
    } = await adminAuthClient.listUsers();

    if (!error && users) setUsers(users);
  };

  return { users };
}
