import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import { useAuth, type UserRole } from "..";
import NotFound from "pages/general/NotFound";
import CheckingAuth from "./CheckingAuth";

export default function ProtectedRotue({ role }: { role?: UserRole }) {
  const { user } = useAuth();

  const userRole: UserRole | undefined | null = useMemo(() => {
    if (user) {
      return user.app_metadata.user_role;
    } else {
      return user as undefined | null;
    }
  }, [user]);

  if (userRole === undefined) return <CheckingAuth />;
  if (userRole === role) return <Outlet />;
  else return <NotFound />;
}
