export const organization = {
  full: "충북과학고등학교",
  short: "충북과고",
  eng: "CBSH",
};

export const copyright = {
  year: new Date().getFullYear(),
  name: "KONGTEK",
};
