import {
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  GlobalStyles,
  Input,
  Sheet,
  Typography,
} from "@mui/joy";
import { formLabelClasses } from "@mui/joy/FormLabel";
import { Link as RouteLink } from "react-router-dom";
import { useState } from "react";
import { copyright, organization } from "data/constants";
import { supabase } from "lib/supabase";
import toast from "react-hot-toast";
import ThemeToggle from "components/ui/ThemeToggle";
import { FormModal } from "components/form/formModals";

export default function ForgotPassword() {
  const [email, setEmail] = useState("");

  const handlePasswordReset = async () => {
    setIsLoading(true);
    const { error } = await supabase.auth.resetPasswordForEmail(email, {
      redirectTo: `${window.location.origin}/update-passsword`,
    });
    setIsLoading(false);

    if (error) {
      toast.error("에러가 발생했습니다");
    } else {
      setIsInfoModalOpen(true);
    }
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isInfoModalOpen, setIsInfoModalOpen] = useState(false);

  return (
    <Sheet>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Collapsed-breakpoint": "769px", // form will stretch when viewport is below `769px`
            "--Cover-width": "40vw", // must be `vw` only
            "--Form-maxWidth": "700px",
            "--Transition-duration": "0.3s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(4px)",
          backgroundColor: "rgba(255 255 255 / 0.6)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width:
              "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
            maxWidth: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontWeight="lg"
              startDecorator={
                <img src="/icons/favicon-196x196.png" alt="logo" width="20px" />
              }
            >
              {organization.full}
            </Typography>
            <ThemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: "hidden",
              },
            }}
          >
            <div>
              <Typography component="h1" fontSize="xl2" fontWeight="lg">
                패스워드 재설정
              </Typography>
            </div>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handlePasswordReset();
              }}
            >
              <FormControl>
                <FormLabel>이메일</FormLabel>
                <Input
                  type="email"
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                  required
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography />
              </Box>
              <Button type="submit" fullWidth loading={isLoading}>
                패스워드 재설정
              </Button>
              <Button
                fullWidth
                variant="plain"
                component={RouteLink}
                to="/signin"
              >
                로그인 화면으로 돌아가기
              </Button>
            </form>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-sm" textAlign="center">
              © {copyright.name} {copyright.year}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(/images/landing.png)`,
        }}
      />
      <FormModal
        open={isInfoModalOpen}
        title="패스워드 재설정 메일 전송됨"
        description="패스워드 재설정 메일이 입력하신 이메일로 전송되었습니다. 메일 속의 링크를 클릭해 패스워드를 재설정하세요."
      />
    </Sheet>
  );
}
