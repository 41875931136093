import * as React from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import IconButton from "@mui/joy/IconButton";
import Sheet from "@mui/joy/Sheet";
import { Menu } from "react-feather";
import { EduSetLogo } from "components/ui/logo";
import ThemeToggle from "components/ui/ThemeToggle";
import { toggleSidebar } from "utils/manageSidebar";

export default function MobileHeader() {
  return (
    <Sheet
      sx={{
        display: { xs: "flex", md: "none" },
        alignItems: "center",
        position: "fixed",
        top: 0,
        width: "100vw",
        height: "var(--Header-height)",
        zIndex: 999,
        py: 1,
        px: 2,
        gap: 1,
        boxShadow: "sm",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Header-height": "52px",
            [theme.breakpoints.up("md")]: {
              "--Header-height": "0px",
            },
          },
        })}
      />
      <IconButton
        onClick={() => toggleSidebar()}
        variant="outlined"
        color="neutral"
        size="sm"
      >
        <Menu size={20} />
      </IconButton>
      <EduSetLogo variant="plain" sx={{ boxShadow: "none", mr: "auto" }} />
      <ThemeToggle id={undefined} />
    </Sheet>
  );
}
