import { BottomActions, GeneratedMenu } from "components/layout/menu";
import HeadBreadcrumbs from "components/layout/HeadBreadcrumbs";
import MenuLayout from "components/layout/MenuLayout";
import Sidebar from "components/layout/Sidebar";
import { useAuth } from "features/auth";
import Profile from "components/layout/Profile";
import { teacherMenu } from "data/menu";
import { HelpCircle, Settings } from "react-feather";
import ProfileFallback from "components/layout/ProfileFallback";
import { router } from "Router";

export default function TeacherLayout() {
  const { teacherProfile } = useAuth();
  const profile = teacherProfile
    ? {
        primaryText: `${teacherProfile.name} 선생님`,
        secondaryText: `좋은 하루 되세요!`,
        avatar: teacherProfile.avatar,
      }
    : teacherProfile;

  const ProfileElement = profile ? (
    <Profile
      primaryText={profile.primaryText}
      secondaryText={profile.secondaryText}
      avatar={profile.avatar}
    />
  ) : profile === undefined ? (
    <Profile primaryText={"-"} secondaryText={"새로고침 중..."} />
  ) : (
    <ProfileFallback />
  );

  function TeacherSidebar() {
    return (
      <Sidebar
        menuTop={<GeneratedMenu menu={teacherMenu} />}
        menuBottom={
          <BottomActions
            actions={[
              {
                icon: <Settings />,
                label: "비밀번호 변경",
                onClick: () => {
                  router.navigate("/update-password");
                },
              },
              {
                icon: <HelpCircle />,
                label: "도움말",
                onClick: () => {
                  window.open(
                    "https://kongtek.notion.site/2292b397248e4d10baa3f1b8eeb6d3cc?pvs=4",
                    "_blank"
                  );
                },
              },
            ]}
          />
        }
        profile={ProfileElement}
      />
    );
  }

  return (
    <MenuLayout
      sidebar={<TeacherSidebar />}
      breadcrumbs={<HeadBreadcrumbs menu={teacherMenu} />}
    />
  );
}
