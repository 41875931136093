import AspectRatio, { AspectRatioProps } from "@mui/joy/AspectRatio";

export function EduSetLogo({ sx, ...props }: AspectRatioProps) {
  return (
    <AspectRatio
      ratio="1"
      variant="plain"
      {...props}
      sx={[
        {
          width: 24,
          borderRadius: "sm",
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <div>
        <img src="/icons/transparent-196x196.png" alt="logo" />
      </div>
    </AspectRatio>
  );
}
