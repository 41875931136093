import { ObjectForSeat, SeatType } from "features/application";
import { supabase } from "lib/supabase";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";

export function useSeats(roomId: number) {
  const [seats, setSeats] = useState<SeatType[] | undefined>(undefined);
  useEffect(() => {
    setSeats(undefined);
    loadSeats(roomId);
  }, [roomId]);

  const loadSeats = async (roomId: number) => {
    const { data: rawSeats, error } = await supabase
      .from("room_seat")
      .select("*")
      .eq("room", roomId);

    if (error) {
      toast.error("좌석 정보를 불러오는 중 오류가 발생했습니다.");
      return;
    }

    const seats = rawSeats.map((seat) => ({
      ...seat,
      ...(seat.locked_at && { locked_at: new Date(seat.locked_at) }),
    }));

    setSeats(seats);
  };

  return { seats, loadSeats };
}

export function useObjects(roomId: number) {
  const [objects, setObjects] = useState<ObjectForSeat[] | undefined>(
    undefined
  );
  useEffect(() => {
    setObjects(undefined);
    loadObjects(roomId);
  }, [roomId]);

  const loadObjects = async (roomId: number) => {
    const { data: objects, error } = await supabase
      .from("room_object")
      .select("*")
      .eq("room", roomId);

    if (error) {
      toast.error("구조물 정보를 불러오는 중 오류가 발생했습니다.");
      return;
    }

    setObjects(objects);
  };

  return { objects, loadObjects };
}
