import { FormModal } from "components/form/formModals";
import { DateStr } from "typings";
import { Object, Seat, SeatContainer } from "./canvasComponents";
import {
  Application,
  LockedSeat,
  ObjectForSeat,
  Point,
  SeatType,
} from "features/application/types";
import { Button, ColorPaletteProp, Sheet, Stack, Typography } from "@mui/joy";
import { supabase } from "lib/supabase";
import { useAuth } from "features/auth";
import { useMemo } from "react";
import toast from "react-hot-toast";

export function SeatSelectModal(props: SeatSelectModalProps) {
  const {
    roomSize,
    seats,
    objects,
    dateStr,
    session,
    roomId,
    lockedSeats,
    applications,
    handleClose,
  } = props;
  const { user } = useAuth();

  const totalLockedStatus: { id: number; user: string }[] = useMemo(() => {
    const result: { id: number; user: string }[] = [];
    lockedSeats.forEach((item) => {
      result.push({ id: item.seat.id, user: item.locked_by });
    });
    applications.forEach((item) => {
      result.push({ id: item.seat!, user: item.student });
    });
    return result;
  }, [lockedSeats, applications]);

  const disSelectSeat = async (id: number) => {
    await supabase
      .from("room_seat_lock")
      .delete()
      .eq("session", session)
      .eq("date", dateStr)
      .eq("seat", id);
  };

  const selectSeat = async (id: number) => {
    await supabase
      .from("room_seat_lock")
      .delete()
      .eq("room", roomId)
      .eq("session", session)
      .eq("date", dateStr)
      .eq("locked_by", user!.id);
    await supabase
      .from("room_seat_lock")
      .insert({
        room: roomId,
        seat: id,
        session,
        date: dateStr,
        locked_by: user!.id,
      });
  };

  return (
    <FormModal
      title={`${session}교시 좌석 선택`}
      actions={[
        <Button key="close" color="neutral" onClick={handleClose}>
          닫기
        </Button>,
      ]}
    >
      <Stack alignItems="center" gap={1}>
        <Sheet
          sx={{
            overflow: "auto",
            maxHeight: "50vh",
            width: "100%",
          }}
          variant="soft"
          color="neutral"
        >
          <SeatContainer {...roomSize}>
            {seats.map((seat) => {
              const seatState: SeatState = totalLockedStatus.find(
                (i) => i.id === seat.id && i.user === user!.id
              )
                ? "selected"
                : totalLockedStatus.find((i) => i.id === seat.id)
                ? "locked"
                : "normal";
              return (
                <Seat
                  key={seat.id}
                  {...seat.position}
                  label={seat.name}
                  color={seatColorMap[seatState]}
                  variant={seatState === "normal" ? "outlined" : "solid"}
                  onClick={() => {
                    if (seatState === "selected") disSelectSeat(seat.id);
                    else if (seatState === "normal") selectSeat(seat.id);
                    else toast.error("이미 점유된 좌석입니다.");
                  }}
                />
              );
            })}
            {objects.map((object) => {
              return (
                <Object
                  key={object.id}
                  {...object.position}
                  {...object.size}
                  label={object.name}
                />
              );
            })}
          </SeatContainer>
        </Sheet>
        <Typography level="body-sm">
          *신청 완료 전이라도, 좌석을 선택하면 다른 사람에게 해당 좌석은 점유
          상태로 보입니다.
        </Typography>
      </Stack>
    </FormModal>
  );
}

const seatColorMap: { [state in SeatState]: ColorPaletteProp } = {
  normal: "neutral",
  selected: "primary",
  locked: "neutral",
};

type SeatState = "locked" | "selected" | "normal";

type SeatSelectModalProps = {
  roomSize: Point;
  seats: SeatType[];
  objects: ObjectForSeat[];
  lockedSeats: LockedSeat[];
  applications: Application[];
  session: number;
  dateStr: DateStr;
  roomId: number;
  handleClose: () => void;
};
