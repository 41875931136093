import { Box, Stack, Typography } from "@mui/joy";
import { TypographyInheritContext } from "@mui/joy/Typography/Typography";
import DataCard from "components/form/DataCard";
import { baseMenu } from "data/menu";

export default function MileageCard() {
  return (
    <DataCard title="그린마일리지" icon={baseMenu.mileage.icon}>
      <Box
        style={{
          height: "100%",
          width: "100%",
          background: `repeating-linear-gradient(
      -45deg,
      #e3effb,
      #e3effb 5px,
      #ffffff 5px,
      #ffffff 10px
    )`,
        }}
      >
        <Stack
          sx={{ height: "100%" }}
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            level="body-md"
            variant="soft"
            textAlign="center"
            color="primary"
          >
            개발 예정
          </Typography>
        </Stack>
      </Box>
    </DataCard>
  );
}
