import { Table } from "@mui/joy";
import { Student } from "features/auth";

export function StudentsTable(props: StudentsTableProps) {
  const { students } = props;

  return (
    <Table>
      <thead>
        <tr>
          <th>사진</th>
          <th>이름</th>
          <th>학년</th>
          <th>반</th>
          <th>번호</th>
        </tr>
      </thead>
      <tbody>
        {students.map((i) => {
          return (
            <tr>
              <td>불러오는 중...</td>
              <td>{i.name}</td>
              <td>{i.grade}</td>
              <td>{i.class}</td>
              <td>{i.number}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}

type StudentsTableProps = {
  students: Student[];
};
