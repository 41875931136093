import { supabase } from "lib/supabase";
import { ObjectForSeat, SeatType } from "./../../types/room.d";
import { useEffect, useState } from "react";

export function useRoomSeatComponents(roomId: number) {
  const [seats, setSeats] = useState<SeatType[]>([]);
  const [objects, setObjects] = useState<ObjectForSeat[]>([]);

  useEffect(() => {
    handleRefresh(roomId);
  }, [roomId]);

  const handleSeatsRefresh = async (roomId: number) => {
    const seats = await getRoomSeats(roomId);
    setSeats(seats);
  };
  const handleObjectsRefresh = async (roomId: number) => {
    const objects = await getRoomObjects(roomId);
    setObjects(objects);
  };

  const handleRefresh = async (roomId: number) => {
    Promise.all([handleSeatsRefresh(roomId), handleObjectsRefresh(roomId)]);
  };

  return {
    seats,
    objects,
    handleRefresh,
    handleSeatsRefresh,
    handleObjectsRefresh,
  };
}

const getRoomSeats = async (roomId: number) => {
  const { data, error } = await supabase
    .from("room_seat")
    .select("*")
    .eq("room", roomId);

  if (error) throw error;

  return data as SeatType[];
};

const getRoomObjects = async (roomId: number) => {
  const { data, error } = await supabase
    .from("room_object")
    .select("*")
    .eq("room", roomId);

  if (error) throw error;

  return data as ObjectForSeat[];
};
