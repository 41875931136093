import PageTitle from "components/ui/PageTitle";
import { StudentsTable } from "./StudentsTable";
import { useAllStudents } from "./useAllStudents";
import { useAllUsers } from "features/admin/hooks/useAllUsers";
import { useEffect } from "react";
import { Button } from "@mui/joy";
import { createStudent, createTeacher } from "features/admin/services/users";
import toast from "react-hot-toast";

export default function AdminStudentPage() {
  const { students } = useAllStudents();
  const { users } = useAllUsers();

  const handleNewSignup = async () => {
    const originalData = `rlrp13@cberi.go.kr	박정환	
    `;

    function convertToTestData(originalData: string) {
      const rows = originalData.trim().split("\n");
      const testData = rows.map((row) => {
        const [email, name, homeroomInfo] = row.split("\t");

        // Use regex to extract homeroom class number
        const homeroomMatch = homeroomInfo.match(/(\d+)학년 (\d+)반/);
        const homeroom = homeroomMatch
          ? parseInt(homeroomMatch[1]) * 100 + parseInt(homeroomMatch[2])
          : undefined;

        return {
          email: email,
          password: "1234",
          name: name,
          homeroom: homeroom,
        };
      });
      return testData;
    }

    const testData = convertToTestData(originalData);

    for (const teacher of testData) {
      await createTeacher(teacher);
      toast.success(`${teacher.name} 선생님 계정 생성 완료`);
    }
  };

  return (
    <>
      <PageTitle />
      <Button onClick={handleNewSignup}>테스트</Button>
      <StudentsTable students={students} />
    </>
  );
}

// function convertToTestData(originalData: string) {
//   const rows = originalData.trim().split("\n");
//   const testData = rows.map((row) => {
//     const [grade, clazz, number, name, email] = row.split("\t");
//     return {
//       grade: parseInt(grade),
//       class: parseInt(clazz),
//       number: parseInt(number),
//       name: name,
//       email: email,
//       password: "1234",
//     };
//   });
//   return testData;
// }
