import { PartialArea } from "../types";

export function isInArea(targetArea: PartialArea, parentArea: PartialArea) {
  const target = { w: 1, h: 1, ...targetArea };
  const parent = { w: 1, h: 1, ...parentArea };

  const isLeftTopInArea = target.x >= parent.x && target.y >= parent.y;
  const isRightBottomInArea =
    target.x + target.w <= parent.x + parent.w &&
    target.y + target.h <= parent.y + parent.h;

  if (isLeftTopInArea && isRightBottomInArea) return true;
  else return false;
}

export function mergeAreas(area1: PartialArea, area2: PartialArea) {
  const a1 = { w: 1, h: 1, ...area1 };
  const a2 = { w: 1, h: 1, ...area2 };

  const lt = {
    x: Math.min(a1.x, a2.x),
    y: Math.min(a1.y, a2.y),
  };
  const rb = {
    x: Math.max(a1.x + a1.w, a2.x + a2.w),
    y: Math.max(a1.y + a1.h, a2.y + a2.h),
  };
  const size = {
    w: rb.x - lt.x,
    h: rb.y - lt.y,
  };

  return { ...lt, ...size };
}
