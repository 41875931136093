import { BottomActions, GeneratedMenu } from "components/layout/menu";
import HeadBreadcrumbs from "components/layout/HeadBreadcrumbs";
import MenuLayout from "components/layout/MenuLayout";
import Sidebar from "components/layout/Sidebar";
import Profile from "components/layout/Profile";
import { adminMenu } from "data/menu";
import { HelpCircle, Settings } from "react-feather";
import { router } from "Router";

export default function AdminLayout() {
  function AdminSidebar() {
    return (
      <Sidebar
        menuTop={<GeneratedMenu menu={adminMenu} />}
        menuBottom={
          <BottomActions
            actions={[
              {
                icon: <Settings />,
                label: "비밀번호 변경",
                onClick: () => {
                  router.navigate("/update-password");
                },
              },
              {
                icon: <HelpCircle />,
                label: "도움말",
                onClick: () => {
                  window.open(
                    "https://kongtek.notion.site/2292b397248e4d10baa3f1b8eeb6d3cc?pvs=4",
                    "_blank"
                  );
                },
              },
            ]}
          />
        }
        profile={
          <Profile
            primaryText="시스템 관리자"
            secondaryText="로그아웃 잊지 마세요!"
          />
        }
        color="warning"
      />
    );
  }

  return (
    <MenuLayout
      sidebar={<AdminSidebar />}
      breadcrumbs={<HeadBreadcrumbs menu={adminMenu} />}
    />
  );
}
