import {
  AuthChangeEvent,
  AuthError,
  AuthTokenResponse,
  Session,
  User,
} from "@supabase/supabase-js";
import { supabase } from "lib/supabase";
import { createContext, useContext, useEffect, useState } from "react";

import { Student, Teacher, getUserRole } from "..";
import { signIn } from "../services/signin";
import { signOut } from "../services/signout";

const initialAuthContext: AuthContextType = {
  session: undefined,
  user: undefined,
  studentProfile: undefined,
  teacherProfile: undefined,
  lastEvent: null,
  signIn,
  signOut,
  refreshProfile: () => Promise.resolve(null),
};

// Create a context
const AuthContext = createContext<AuthContextType>(initialAuthContext);

// Create a provider component
export const AuthProvider = ({ children }: { children: JSX.Element }) => {
  const [session, setSession] = useState<Session | null | undefined>(undefined);
  const [lastEvent, setLastEvent] = useState<AuthChangeEvent | null>(null);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event, session) => {
      setLastEvent(event);
      setSession(session);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const user = session ? session.user : session;

  const [studentProfile, setStudentProfile] = useState<
    Student | undefined | null
  >(undefined);

  const fetchStudentProfile = async (id: string) => {
    const { data, error } = await supabase
      .from("student")
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      console.error("!!!", error);
      setStudentProfile(null);
    } else {
      setStudentProfile(data);
    }
    return { data, error };
  };

  const [teacherProfile, setTeacherProfile] = useState<
    Teacher | undefined | null
  >(undefined);

  const fetchTeacherProfile = async (id: string) => {
    setTeacherProfile(undefined);

    const { data, error } = await supabase
      .from("teacher")
      .select("*")
      .eq("id", id)
      .single();

    if (error) {
      console.error(error);
      setTeacherProfile(null);
    } else {
      setTeacherProfile(data);
    }
    return { data, error };
  };

  useEffect(() => {
    refreshProfile();
  }, [user]);

  const refreshProfile = async () => {
    if (user) {
      const userRole = getUserRole(user);
      if (userRole === "student") {
        setTeacherProfile(null);
        const { data: student, error } = await fetchStudentProfile(user.id);
        return error ? null : (student as Student);
      }
      if (userRole === "teacher") {
        setStudentProfile(null);
        const { data: teacher, error } = await fetchTeacherProfile(user.id);
        return error ? null : (teacher as Teacher);
      }
    }
    setStudentProfile(null);
    setTeacherProfile(null);
    return null;
  };

  return (
    <AuthContext.Provider
      value={{
        session,
        user,
        studentProfile,
        teacherProfile,
        lastEvent,
        signIn,
        signOut,
        refreshProfile,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Create a hook to use the auth context
export const useAuth = () => useContext(AuthContext);

type AuthContextType = {
  session: Session | null | undefined;
  user: User | null | undefined;
  studentProfile: Student | null | undefined;
  teacherProfile: Teacher | null | undefined;
  lastEvent: AuthChangeEvent | null;
  signIn: (
    email: string,
    password: string,
    option?: { showToast?: boolean; landToRoot?: boolean }
  ) => Promise<AuthTokenResponse>;
  signOut: (option?: {
    showToast?: boolean;
    landToRoot?: boolean;
  }) => Promise<{ error: AuthError | null }>;
  refreshProfile: () => Promise<Student | Teacher | null>;
};
