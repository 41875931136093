import { PostgrestError } from "@supabase/supabase-js";
import { useEffect, useState } from "react";

export function useSql<T>(query: any) {
  const [data, setData] = useState<T | undefined>(undefined);
  const [errors, setErrors] = useState<PostgrestError[]>([]);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    const { data: result, error } = await query;
    if (!error) {
      setData(result);
    } else {
      setErrors((prev) => [...prev, error as PostgrestError]);
    }
  };

  return { data, errors, loadData };
}
