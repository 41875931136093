import { Sheet } from "@mui/joy";
import React from "react";

export function Content({ children }: { children: React.ReactNode }) {
  return (
    <Sheet
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "inherit",
        overflow: "auto",
      }}
    >
      {children}
    </Sheet>
  );
}
