import {
  Accordion,
  AccordionDetails,
  AccordionGroup,
  AccordionSummary,
  Alert,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  FormControl,
  FormLabel,
  Input,
  List,
  ListItem,
  ListItemContent,
  ListItemDecorator,
  RadioGroup,
  Skeleton,
  Stack,
  Typography,
  accordionDetailsClasses,
  accordionSummaryClasses,
} from "@mui/joy";
import { proxyApplicationErrorCodes } from "data/errors";
import { FormModal } from "components/form/formModals";
import { proxyApplication } from "features/application/services/application";
import {
  getApplicationConfig,
  getMaxSession,
} from "features/application/services/config";
import PageTitle from "components/ui/PageTitle";
import { supabase } from "lib/supabase";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { MapPin, Target, Users, X } from "react-feather";
import toast from "react-hot-toast";
import {
  RoomSelect,
  type ApplicationConfig,
  type Room,
} from "features/application";
import type { Student } from "features/auth";
import { date2str } from "utils/manageTime";

type ProxyApplicationInputs = {
  student: Student[];
  room: Room | null;
  session: number[];
  reason: string;
  config: {
    approve: boolean;
    overwrite: boolean;
    force: boolean;
  };
};

const defaultInputs: ProxyApplicationInputs = {
  student: [],
  room: null,
  session: [],
  reason: "",
  config: {
    approve: true,
    overwrite: true,
    force: false,
  },
};

export default function TeacherApplicaionProxy() {
  const dateStr = date2str(new Date());

  const [inputs, setInputs] = useState<ProxyApplicationInputs>(defaultInputs);
  const insertSession = (session: number) => {
    setInputs((prev) => ({
      ...prev,
      session: [...prev.session.filter((item) => item !== session), session],
    }));
  };
  const popSession = (session: number) => {
    setInputs((prev) => ({
      ...prev,
      session: prev.session.filter((item) => item !== session),
    }));
  };

  const [students, setStudents] = useState<Student[] | undefined>(undefined);
  const loadStudents = async () => {
    const { data, error } = await supabase.from("student").select("*");
    if (error) {
      toast.error("학생 목록을 불러오지 못했습니다.");
      return;
    }

    setStudents(data);
  };

  const [config, setConfig] = useState<ApplicationConfig | undefined>(
    undefined
  );
  const loadConfig = async () => {
    const config = await getApplicationConfig();
    setConfig(config);
  };

  const maxSession = config
    ? getMaxSession(dateStr, config.session)
    : undefined;
  //   const maxSession = undefined;

  useEffect(() => {
    loadStudents();
    loadConfig();
  }, []);

  const [isSendingProxyApplication, setIsSendingProxyApplication] =
    useState(false);

  const [proxyResult, setProxyResult] = useState<
    | {
        student: Student;
        session: number;
        success: boolean;
        errorCode: string;
      }[]
    | null
  >(null);
  const handleProxyApplicationSubmit = async () => {
    if (inputs.student.length === 0) {
      toast.error("대상 학생을 선택해주세요.");
      return;
    }
    if (!inputs.room) {
      toast.error("목표 교실을 선택해주세요.");
      return;
    }
    if (inputs.session.length === 0) {
      toast.error("목표 교시를 선택해주세요.");
      return;
    }

    setIsSendingProxyApplication(true);

    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) {
      toast.error("세션이 만료되었습니다. 다시 로그인해주세요.");
      return;
    }
    const uid = session.user.id;

    const serializedApplication: {
      student: Student;
      session: number;
    }[] = [];
    inputs.student.map((student) => {
      inputs.session.map((session) => {
        serializedApplication.push({
          student: student,
          session,
        });
      });
    });

    const promises = serializedApplication.map((item) => {
      const application = {
        ...item,
        student: item.student.id,
        teacher: uid,
        room: inputs.room!.id,
        reason: inputs.reason,
        dateStr,
      };
      return proxyApplication(application, inputs.config);
    });

    // run each promise in a row, not pararell
    const results = [];
    for (const promise of promises) {
      if (inputs.room!.seat_selection) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
      }
      results.push(await promise);
    }

    const proxyResults = results.map((result, index) => {
      const { error } = result;

      if (error) {
        return {
          student: serializedApplication[index].student,
          session: serializedApplication[index].session,
          success: false,
          errorCode: error.message,
        };
      } else {
        return {
          student: serializedApplication[index].student,
          session: serializedApplication[index].session,
          success: true,
          errorCode: "",
        };
      }
    });

    setProxyResult(proxyResults);
    setIsSendingProxyApplication(false);
    setInputs(defaultInputs);
  };

  return (
    <>
      <PageTitle />
      {maxSession === 0 && (
        <Alert variant="soft" color="danger">
          금일 자율학습이 배정되지 않았습니다.
        </Alert>
      )}
      <Card variant="soft">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleProxyApplicationSubmit();
          }}
        >
          <Stack gap={2}>
            <FormControl>
              <FormLabel>대상 학생</FormLabel>
              <Autocomplete
                openOnFocus
                multiple
                startDecorator={<Users size={18} />}
                loading={!students}
                placeholder="여러 명 검색..."
                options={_.sortBy(students, ["grade", "class", "number"])}
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                groupBy={(option) => `${option.grade}학년 ${option.class}반`}
                value={inputs.student}
                onChange={(_, newValue) =>
                  setInputs((prev) => ({
                    ...prev,
                    student: newValue,
                  }))
                }
                renderTags={(tags, getTagProps) =>
                  tags.map((item, index) => (
                    <Chip
                      color="primary"
                      startDecorator={<Avatar src={item.avatar} />}
                      endDecorator={<X size={15} />}
                      {...getTagProps({ index })}
                    >
                      {`${item.name}`}
                    </Chip>
                  ))
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>목표 교실</FormLabel>
              {/* <Autocomplete
                openOnFocus
                required
                startDecorator={<MapPin size={18} />}
                loading={!rooms}
                placeholder="검색"
                options={_.sortBy(rooms, ["sector", "name"])}
                getOptionLabel={(option) => `${option.name}`}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                groupBy={(option) => `${option.sector}`}
                value={inputs.room}
                onChange={(_, newValue) =>
                  setInputs((prev) => ({
                    ...prev,
                    room: newValue,
                  }))
                }
              /> */}
              <RoomSelect
                room={inputs.room}
                setRoom={(room) =>
                  setInputs((prev) => ({
                    ...prev,
                    room,
                  }))
                }
              />
              {inputs.room?.seat_selection && (
                <Alert variant="soft" color="warning" sx={{ mt: 1 }}>
                  좌석은 랜덤으로 선택됩니다.
                </Alert>
              )}
            </FormControl>
            <FormControl>
              <FormLabel>목표 교시</FormLabel>

              {maxSession ? (
                <RadioGroup sx={{ b: 0 }}>
                  <List
                    orientation="horizontal"
                    wrap
                    sx={{
                      "--List-gap": "8px",
                      "--ListItem-radius": "20px",
                    }}
                  >
                    {new Array(maxSession).fill(0).map((_, index) => {
                      const session = index + 1;
                      const checked = inputs.session.includes(session);
                      return (
                        <ListItem key={session}>
                          <Checkbox
                            overlay
                            disableIcon
                            checked={checked}
                            onChange={(e) => {
                              if (e.target.checked) insertSession(session);
                              else popSession(session);
                            }}
                            variant={checked ? "solid" : "outlined"}
                            label={`${session}교시`}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                </RadioGroup>
              ) : (
                <Skeleton variant="rectangular" height="50px" />
              )}
            </FormControl>
            <FormControl>
              <FormLabel>신청 사유</FormLabel>
              <Input
                startDecorator={<Target size={18} />}
                value={inputs.reason}
                onChange={(e) =>
                  setInputs({ ...inputs, reason: e.target.value })
                }
              />
            </FormControl>
            <FormControl>
              <FormLabel>대리신청 설정</FormLabel>
              <RadioGroup>
                <List size="sm">
                  <ListItem>
                    <Checkbox
                      label="내 명의로 승인하기"
                      checked={inputs.config.approve}
                      onChange={(e) =>
                        setInputs({
                          ...inputs,
                          config: {
                            ...inputs.config,
                            approve: e.target.checked,
                          },
                        })
                      }
                    />
                  </ListItem>
                  <ListItem>
                    <Checkbox
                      label="학생이 다른 곳을 신청했어도 덮어쓰기"
                      checked={inputs.config.overwrite}
                      onChange={(e) =>
                        setInputs({
                          ...inputs,
                          config: {
                            ...inputs.config,
                            overwrite: e.target.checked,
                          },
                        })
                      }
                    />
                  </ListItem>
                  {/* <ListItem>
                    <Checkbox
                      label="교실 정원이 초과되어도 신청하기"
                      checked={inputs.config.force}
                      onChange={(e) =>
                        setInputs({
                          ...inputs,
                          config: { ...inputs.config, force: e.target.checked },
                        })
                      }
                    />
                  </ListItem> */}
                </List>
              </RadioGroup>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                justifyContent: "flex-end",
                pt: 2,
              }}
            >
              <Button type="submit" loading={isSendingProxyApplication}>
                대리신청
              </Button>
            </Box>
          </Stack>
        </form>
      </Card>
      <FormModal
        title="대리신청 결과"
        open={!!proxyResult}
        actions={[
          <Button
            key="close"
            color="neutral"
            variant="plain"
            onClick={() => setProxyResult(null)}
          >
            닫기
          </Button>,
        ]}
      >
        <AccordionGroup
          variant="outlined"
          sx={{
            borderRadius: "sm",
            [`& .${accordionSummaryClasses.button}:hover`]: {
              bgcolor: "transparent",
            },
            [`& .${accordionDetailsClasses.content}`]: {
              boxShadow: (theme) => `inset 0 1px ${theme.vars.palette.divider}`,
              [`&.${accordionDetailsClasses.expanded}`]: {
                paddingBlock: "0.75rem",
              },
            },
          }}
        >
          {proxyResult &&
            proxyResult.filter((item) => item.success).length > 0 && (
              <Accordion key="success">
                <AccordionSummary>
                  성공 ({proxyResult.filter((item) => item.success).length}건)
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    variant="outlined"
                    sx={{
                      minWidth: 240,
                      borderRadius: "sm",
                      px: 1,
                      "--ListItemDecorator-size": "56px",
                    }}
                  >
                    {proxyResult
                      .filter((item) => item.success)
                      .map((item, index) => (
                        <ListItem key={index}>
                          <ListItemDecorator>
                            <Avatar src={item.student.avatar} />
                          </ListItemDecorator>
                          <ListItemContent>
                            <Typography level="title-sm">
                              {item.student.name} {item.session}교시 성공
                            </Typography>
                            <Typography level="body-sm" noWrap>
                              신청 성공
                            </Typography>
                          </ListItemContent>
                        </ListItem>
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            )}
          {proxyResult &&
            proxyResult.filter((item) => !item.success).length > 0 && (
              <Accordion key="fail">
                <AccordionSummary>
                  실패 ({proxyResult.filter((item) => !item.success).length}건)
                </AccordionSummary>
                <AccordionDetails>
                  <List
                    variant="outlined"
                    sx={{
                      minWidth: 240,
                      borderRadius: "sm",
                      px: 1,
                      "--ListItemDecorator-size": "56px",
                    }}
                  >
                    {proxyResult
                      .filter((item) => !item.success)
                      .map((item, index) => {
                        const errorExplanation =
                          proxyApplicationErrorCodes[item.errorCode]
                            ?.explanation ?? "알 수 없는 오류입니다.";
                        return (
                          <ListItem key={index}>
                            <ListItemDecorator>
                              <Avatar src={item.student.avatar} />
                            </ListItemDecorator>
                            <ListItemContent>
                              <Typography level="title-sm">
                                {item.student.name} {item.session}교시 실패
                              </Typography>
                              <Typography level="body-sm" noWrap>
                                {errorExplanation}
                              </Typography>
                            </ListItemContent>
                          </ListItem>
                        );
                      })}
                  </List>
                </AccordionDetails>
              </Accordion>
            )}
        </AccordionGroup>
      </FormModal>
    </>
  );
}
