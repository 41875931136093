import { Chip, Sheet, Switch, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import {
  getApplicationConfig,
  getMaxSession,
} from "features/application/services/config";
import PageTitle from "components/ui/PageTitle";
import { supabase } from "lib/supabase";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import type {
  Application,
  ApplicationConfig,
  Room,
  SeatType,
} from "features/application";
import { date2str } from "utils/manageTime";
import RoomsView from "./RoomsView";
import { Student } from "features/auth";

export type MinimalApplicationType = Application & {
  room: Room;
  student: Student;
  seat: null | SeatType;
};

export default function TeacherApplicationAttendance() {
  const dateStr = date2str(new Date());

  const [config, setConfig] = useState<ApplicationConfig | undefined>(
    undefined
  );
  const loadConfig = async () => setConfig(await getApplicationConfig());

  const maxSession = config ? getMaxSession(dateStr, config.session) : 0;

  const [applications, setApplications] = useState<
    MinimalApplicationType[] | undefined
  >(undefined);
  const loadApplications = async () => {
    toast.loading("데이터를 갱신하는 중...", { id: "loading" });

    const { data: applications, error } = await (showApprovedOnly
      ? supabase
          .from("application")
          .select(`*, room(*), student(*), seat(*)`)
          .eq("date", dateStr)
          .eq("status", "approved")
      : supabase
          .from("application")
          .select(`*, room(*), student(*), seat(*)`)
          .eq("date", dateStr));

    if (error) {
      toast.error("데이터를 갱신하지 못했습니다.", { id: "loading" });
      return;
    }
    setApplications(applications);
    toast.success("데이터를 갱신했습니다.", { id: "loading" });
  };

  const [students, setStudents] = useState<Student[]>([]);
  const loadStudents = async () => {
    const { data } = await supabase.from("student").select("*");
    setStudents(data ?? []);
  };

  useEffect(() => {
    loadConfig();
    loadApplications();
    loadStudents();
  }, []);

  const [targetSession, setTargetSession] = useState<number>(1);
  const [showApprovedOnly, setShowApprovedOnly] = useState<boolean>(false);

  useEffect(() => {
    loadApplications();
  }, [showApprovedOnly]);

  return (
    <>
      <PageTitle
        actions={[
          <Switch
            startDecorator="승인된 신청만 보기"
            checked={showApprovedOnly}
            onChange={() => setShowApprovedOnly((prev) => !prev)}
          />,
        ]}
      />
      <Sheet
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "md",
          flex: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        <Tabs
          value={targetSession}
          onChange={(_, value) => setTargetSession(value as number)}
        >
          <TabList>
            {Array.from({ length: maxSession }, (_, i) => i + 1).map(
              (session) => {
                const totalApplications =
                  applications?.filter((item) => item.session === session) ??
                  [];
                const totalCount = totalApplications.length;
                const checkedCount = totalApplications.filter(
                  (item) => item.present
                ).length;
                return (
                  <Tab
                    variant="plain"
                    color="neutral"
                    key={session}
                    value={session}
                  >
                    {session}교시
                    <Chip
                      size="sm"
                      variant="solid"
                      color={
                        totalCount !== checkedCount ? "warning" : "success"
                      }
                      sx={{ ml: 1 }}
                    >
                      {checkedCount}/{totalCount}
                    </Chip>
                  </Tab>
                );
              }
            )}
          </TabList>
          {Array.from({ length: maxSession }, (_, i) => i + 1).map(
            (session) => {
              const totalApplications =
                applications?.filter((item) => item.session === session) ?? [];
              return (
                <TabPanel
                  variant="plain"
                  color="neutral"
                  key={session}
                  value={session}
                >
                  <RoomsView
                    applications={totalApplications}
                    students={students}
                    fireReload={() => loadApplications()}
                  />
                </TabPanel>
              );
            }
          )}
        </Tabs>
      </Sheet>
    </>
  );
}
