export type ErrorExplanation = {
  [errorCode: string]: {
    explanation: string;
  };
};

export const proxyApplicationErrorCodes: ErrorExplanation = {
  PERMISSION_DENIED: {
    explanation: "선생님 권한이 확인되지 않습니다.",
  },
  ROOM_CAPACITY_EXCEEDED: {
    explanation: "해당 시간의 교실이 꽉 찼습니다.",
  },
  OVERLAPPING_APPLICATION_EXISTS: {
    explanation: "해당 시간에 학생의 신청이 이미 존재해 덮어쓰지 못했습니다.",
  },
};
