import { Breadcrumbs, Typography, Link } from "@mui/joy";
import { ChevronRight, Home } from "react-feather";
import { useLocation } from "react-router-dom";
import { getLocationFromPath } from "utils/manageLocation";

import type { MenuCategory } from "typings";

export default function HeadBreadcrumbs({ menu }: { menu: MenuCategory[] }) {
  const location = useLocation();

  const locationNames = getLocationFromPath(menu, location.pathname);

  return (
    <Breadcrumbs
      size="sm"
      aria-label="breadcrumbs"
      separator={<ChevronRight size={16} />}
      sx={{
        "--Breadcrumbs-gap": "1rem",
        "--Icon-fontSize": "16px",
        fontWeight: "lg",
        color: "neutral.400",
        px: 0,
      }}
    >
      <Link underline="none" color="neutral" fontSize="inherit">
        <Home size={18} />
      </Link>
      {locationNames !== null ? (
        locationNames.map((name, index) => {
          if (index === locationNames.length - 1) {
            return (
              <Typography
                fontSize="inherit"
                variant="soft"
                color="primary"
                key={name}
              >
                {name}
              </Typography>
            );
          } else {
            return (
              <Link
                underline="hover"
                color="neutral"
                fontSize="inherit"
                key={name}
              >
                {name}
              </Link>
            );
          }
        })
      ) : (
        <Link underline="hover" color="neutral" fontSize="inherit">
          확인되지 않은 경로
        </Link>
      )}
    </Breadcrumbs>
  );
}
