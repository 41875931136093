import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  ColorPaletteProp,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Modal,
  ModalClose,
  ModalDialog,
  Option,
  Select,
  Sheet,
  Stack,
  Table,
  Tooltip,
  Typography,
} from "@mui/joy";
import { koreanIndexOf, koreanOnsetIndexOf } from "korean-index-of";
import { approvalMap } from "features/application";
import { getApplicationsForTeacher } from "features/application/services/application";
import PageTitle from "components/ui/PageTitle";
import { supabase } from "lib/supabase";
import _ from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { CheckSquare, Filter, Search, X, XSquare } from "react-feather";

import type {
  Application,
  ApplicationStatus,
  ApplicationConfig,
} from "features/application";
import { date2str } from "utils/manageTime";
import toast from "react-hot-toast";
import {
  getApplicationConfig,
  getMaxSession,
} from "features/application/services/config";
import type { Student } from "features/auth";

export type ApplicationForTeacher = Application & {
  student: Student;
  room: { id: number; name: string };
};

type ApplicationForTeacherWithSid = ApplicationForTeacher & {
  student: { id: number; sid: number; name: string };
};

const koreanSearch = (query: string, target: string) => {
  return (
    koreanIndexOf(query, target) >= 0 || koreanOnsetIndexOf(query, target) >= 0
  );
};

export default function TeacherApplicationManage() {
  const dateStr = date2str(new Date());

  const [applications, setApplications] = useState<
    ApplicationForTeacherWithSid[] | undefined
  >(undefined);
  const [selected, setSelected] = useState<number[]>([]);

  const [applicationConfig, setApplicationConfig] =
    useState<ApplicationConfig | null>(null);
  const maxSession = applicationConfig
    ? getMaxSession(dateStr, applicationConfig.session)
    : 0;

  const defaultFilter = {
    approval: null,
    session: null,
  };

  const [isFilterModalopen, setIsFilterModalOpen] = useState(false);
  const [filter, setFilter] = useState<{
    approval: ApplicationStatus | null;
    session: number | null;
  }>(defaultFilter);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredApplications = useMemo(() => {
    if (!applications) return undefined;

    let result = applications;

    if (filter.approval) {
      result = _.filter(result, (app) => app.status === filter.approval);
    }

    if (filter.session) {
      result = _.filter(result, (app) => app.session === filter.session);
    }

    if (searchTerm.length > 0) {
      const query = searchTerm;
      result = _.filter(
        result,
        (obj) =>
          `${obj.student.sid}`.includes(query) ||
          koreanSearch(query, obj.student.name) ||
          koreanSearch(query, obj.room.name) ||
          koreanSearch(query, obj.reason)
      );
    }

    return result;
  }, [applications, searchTerm, filter]);
  const validSelected = selected.filter((id) => {
    if (!filteredApplications) return false;
    return filteredApplications.some((item) => item.id === id);
  });

  const updateApplicationsToMe = async (dateStr: string) => {
    const {
      data: { session },
      error,
    } = await supabase.auth.getSession();
    //TODO: error handling

    const {
      user: { id },
    } = session!;

    const applications = await getApplicationsForTeacher(dateStr, id);
    const applicationsWithSid = applications.map((application) => {
      const { id, name, grade, class: classNo, number } = application.student;
      const sid = grade * 1000 + classNo * 100 + number;

      return {
        ...application,
        student: {
          id,
          name,
          sid,
        },
      } as unknown as ApplicationForTeacherWithSid;
    });
    const sortedApplications = _.sortBy(applicationsWithSid, [
      (o) => o.student.sid,
      "session",
    ]);
    setApplications(sortedApplications);
  };

  const updateApplicationConfig = async () => {
    const config = await getApplicationConfig();
    setApplicationConfig(config);
  };

  useEffect(() => {
    (async () => {
      await updateApplicationConfig();
      await updateApplicationsToMe(dateStr);
    })();
  }, []);

  const handleApprovalUpdate = async (
    ids: number[],
    status: ApplicationStatus
  ) => {
    const toastId = toast.loading(
      `신청 ${ids.length}건 ${approvalMap[status].kor} 처리 중...`
    );
    const { data, error } = await supabase
      .from("application")
      .update({ status })
      .in("id", ids);
    if (error) {
      console.error(error);
      return;
    }
    toast.success(
      `신청 ${ids.length}건 ${approvalMap[status].kor} 처리 완료!`,
      { id: toastId }
    );

    updateApplicationsToMe(date2str(new Date()));
  };

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>승인 상태로 필터링</FormLabel>
        <Select
          onChange={(_, approval) => setFilter({ ...filter, approval })}
          value={filter.approval}
          placeholder="선택"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          {...(filter.approval && {
            // display the button and remove select indicator
            // when user has selected a value
            endDecorator: (
              <IconButton
                // size="sm"
                variant="plain"
                color="neutral"
                onMouseDown={(event) => {
                  // don't open the popup when clicking on this button
                  event.stopPropagation();
                }}
                onClick={() => {
                  setFilter({ ...filter, approval: null });
                  // action.current?.focusVisible();
                }}
              >
                <X size={16} />
              </IconButton>
            ),
            indicator: null,
            color: "warning",
          })}
        >
          <Option value="pending">대기</Option>
          <Option value="approved">승인</Option>
          <Option value="rejected">거부</Option>
        </Select>
      </FormControl>

      <FormControl size="sm">
        <FormLabel>교시</FormLabel>
        <Select
          onChange={(_, session) => setFilter({ ...filter, session })}
          value={filter.session}
          placeholder="선택"
          slotProps={{ button: { sx: { whiteSpace: "nowrap" } } }}
          {...(filter.session && {
            // display the button and remove select indicator
            // when user has selected a value
            endDecorator: (
              <IconButton
                // size="sm"
                variant="plain"
                color="neutral"
                onMouseDown={(event) => {
                  // don't open the popup when clicking on this button
                  event.stopPropagation();
                }}
                onClick={() => {
                  setFilter({ ...filter, session: null });
                  // action.current?.focusVisible();
                }}
              >
                <X size={16} />
              </IconButton>
            ),
            indicator: null,
            color: "warning",
          })}
        >
          {Array.from({ length: maxSession }, (_, i) => i + 1).map(
            (session) => (
              <Option value={session}>{session}교시</Option>
            )
          )}
        </Select>
      </FormControl>
    </React.Fragment>
  );

  function MobileFilterModal() {
    return (
      <Modal
        open={isFilterModalopen}
        onClose={() => setIsFilterModalOpen(false)}
      >
        <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
          <ModalClose />
          <Typography id="filter-modal" level="h2">
            필터
          </Typography>
          <Divider sx={{ my: 2 }} />
          <Sheet sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
            {renderFilters()}
            <Button color="primary" onClick={() => setIsFilterModalOpen(false)}>
              확인
            </Button>
          </Sheet>
        </ModalDialog>
      </Modal>
    );
  }

  return (
    <>
      <PageTitle
        actions={[
          <Button
            color="success"
            startDecorator={<CheckSquare size={20} />}
            onClick={() => handleApprovalUpdate(validSelected, "approved")}
          >
            선택 승인
          </Button>,
          <Button
            color="danger"
            startDecorator={<XSquare size={20} />}
            onClick={() => handleApprovalUpdate(validSelected, "rejected")}
          >
            선택 거절
          </Button>,
        ]}
      />

      <Sheet
        className="SearchAndFilters-mobile"
        sx={{
          display: {
            xs: "flex",
            sm: "none",
          },
          my: 1,
          gap: 1,
        }}
      >
        <Tooltip
          title="학번, 학생, 교실, 신청사유 중 검색. 초성만으로 검색 가능합니다."
          placement="bottom-start"
        >
          <Input
            size="sm"
            placeholder="검색"
            value={searchTerm}
            onChange={(event) => setSearchTerm(event.target.value)}
            startDecorator={<Search size={20} />}
            sx={{ flexGrow: 1 }}
            {...(searchTerm.length > 0 && {
              // display the button and remove select indicator
              // when user has selected a value
              endDecorator: (
                <IconButton
                  // size="sm"
                  variant="plain"
                  color="neutral"
                  onMouseDown={(event) => {
                    // don't open the popup when clicking on this button
                    event.stopPropagation();
                  }}
                  onClick={() => {
                    setSearchTerm("");
                  }}
                >
                  <X size={16} />
                </IconButton>
              ),
              color: "warning",
            })}
          />
        </Tooltip>
        <IconButton
          size="sm"
          variant="outlined"
          color={_.isEqual(filter, defaultFilter) ? "neutral" : "warning"}
          onClick={() => setIsFilterModalOpen(true)}
        >
          <Filter size={20} />
        </IconButton>
        <MobileFilterModal />
      </Sheet>
      <Box
        className="SearchAndFilters-tabletUp"
        sx={{
          borderRadius: "sm",
          py: 2,
          display: {
            xs: "none",
            sm: "flex",
          },
          flexWrap: "wrap",
          gap: 1.5,
          "& > *": {
            minWidth: {
              xs: "120px",
              md: "160px",
            },
          },
        }}
      >
        <Tooltip
          title="학번, 학생, 교실, 신청사유를 검색. 초성만으로 검색 가능합니다."
          placement="bottom-start"
        >
          <FormControl sx={{ flex: 1 }} size="sm">
            <FormLabel>아래 목록 중 검색</FormLabel>
            <Input
              placeholder="검색"
              startDecorator={<Search size={20} />}
              value={searchTerm}
              onChange={(event) => setSearchTerm(event.target.value)}
              {...(searchTerm.length > 0 && {
                // display the button and remove select indicator
                // when user has selected a value
                endDecorator: (
                  <IconButton
                    // size="sm"
                    variant="plain"
                    color="neutral"
                    onMouseDown={(event) => {
                      // don't open the popup when clicking on this button
                      event.stopPropagation();
                    }}
                    onClick={() => {
                      setSearchTerm("");
                    }}
                  >
                    <X size={16} />
                  </IconButton>
                ),
                color: "warning",
              })}
            />
          </FormControl>
        </Tooltip>

        {renderFilters()}
      </Box>
      {applications &&
        filteredApplications &&
        applications.length > filteredApplications.length && (
          <Alert color="warning">
            검색을 통해 일부 결과만을 표시하는 중입니다.
          </Alert>
        )}
      <Sheet
        variant="outlined"
        sx={{
          width: "100%",
          borderRadius: "md",
          flex: 1,
          overflow: "auto",
          minHeight: 0,
        }}
      >
        {filteredApplications ? (
          <ApplicationsTable
            data={filteredApplications}
            selected={validSelected}
            setSelected={setSelected}
            handleApprovalUpdate={handleApprovalUpdate}
          />
        ) : (
          <Stack
            sx={{ height: "100%" }}
            alignItems="center"
            justifyContent="center"
          >
            <Card variant="soft" color="primary" sx={{ width: "175px" }}>
              <Stack direction="row" gap={2}>
                <CircularProgress size="sm" />
                <Typography>불러오는 중...</Typography>
              </Stack>
            </Card>
          </Stack>
        )}
      </Sheet>
      {filteredApplications ? (
        <Typography>
          총 {filteredApplications.length}건 -{" "}
          <Typography variant="soft" color="neutral">
            대기 {_.filter(filteredApplications, { status: "pending" }).length}
          </Typography>{" "}
          <Typography variant="soft" color="success">
            승인 {_.filter(filteredApplications, { status: "approved" }).length}
          </Typography>{" "}
          <Typography variant="soft" color="danger">
            거절 {_.filter(filteredApplications, { status: "rejected" }).length}
          </Typography>
        </Typography>
      ) : (
        <Typography>불러오는 중...</Typography>
      )}
    </>
  );
}

function ApplicationsTable({
  data,
  selected,
  setSelected,
  handleApprovalUpdate,
}: {
  data: ApplicationForTeacherWithSid[];
  selected: number[];
  setSelected: React.Dispatch<React.SetStateAction<number[]>>;
  handleApprovalUpdate: (ids: number[], status: ApplicationStatus) => void;
}) {
  return (
    <Table
      aria-labelledby="tableTitle"
      // stripe="even"
      borderAxis="both"
      stickyHeader
      hoverRow
      sx={{
        "--TableCell-headBackground": (theme) =>
          theme.vars.palette.background.level1,
        "--Table-headerUnderlineThickness": "1px",
        "--TableRow-hoverBackground": (theme) =>
          theme.vars.palette.background.level1,
        overflow: "auto",
      }}
    >
      <thead>
        <tr>
          <th style={{ width: 48, textAlign: "center", padding: 12 }}>
            <Checkbox
              indeterminate={
                selected.length > 0 && data.length > selected.length
              }
              checked={data.length > 0 && selected.length === data.length}
              onChange={(event) => {
                setSelected(
                  event.target.checked ? data.map((item) => item.id) : []
                );
              }}
              color={
                selected.length > 0 || selected.length === data.length
                  ? "primary"
                  : undefined
              }
              sx={{ verticalAlign: "text-bottom" }}
            />
          </th>
          <th style={{ width: 50, paddingBottom: 12 }}>학번</th>
          <th style={{ width: 70, paddingBottom: 12 }}>학생</th>
          <th style={{ width: 50, paddingBottom: 12 }}>교시</th>
          <th style={{ width: 100, paddingBottom: 12 }}>교실</th>
          <th style={{ width: 150, paddingBottom: 12 }}>신청사유</th>
          <th style={{ width: 75, paddingBottom: 12 }}>승인상태</th>
          <th style={{ width: 130, paddingBottom: 12 }}>개별 액션</th>
        </tr>
      </thead>
      <tbody>
        {data.map((item) => {
          const statusIcon = React.cloneElement(approvalMap[item.status].icon, {
            size: 14,
          });
          const statusColor = approvalMap[item.status]
            .color as ColorPaletteProp;
          const statusText = approvalMap[item.status].kor;

          return (
            <tr key={item.id}>
              <td style={{ textAlign: "center" }}>
                <Checkbox
                  checked={selected.includes(item.id)}
                  color={selected.includes(item.id) ? "primary" : undefined}
                  onChange={(event) => {
                    setSelected((ids) =>
                      event.target.checked
                        ? ids.concat(item.id)
                        : ids.filter((eachId) => eachId !== item.id)
                    );
                  }}
                  slotProps={{ checkbox: { sx: { textAlign: "left" } } }}
                  sx={{ verticalAlign: "text-bottom" }}
                />
              </td>
              {/* <td>
                    <Typography fontWeight="md">{row.id}</Typography>
                  </td> */}
              {/* <td>
                    <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                      <Avatar size="sm">{row.customer.initial}</Avatar>
                      <div>
                        <Typography
                          fontWeight="lg"
                          level="body3"
                          textColor="text.primary"
                        >
                          {row.customer.name}
                        </Typography>
                        <Typography level="body3">
                          {row.customer.email}
                        </Typography>
                      </div>
                    </Box>
                  </td> */}
              {/* <td>
                    <Link fontWeight="lg" component="button" color="neutral">
                      Archive
                    </Link>
                    <Link
                      fontWeight="lg"
                      component="button"
                      color="primary"
                      sx={{ ml: 2 }}
                    >
                      Download
                    </Link>
                  </td> */}
              <td>{item.student.sid}</td>
              <td>{item.student.name}</td>
              <td>{item.session}</td>
              <td>{item.room.name}</td>
              <td>{item.reason}</td>
              <td>
                <Chip
                  variant="soft"
                  size="md"
                  startDecorator={statusIcon}
                  color={statusColor}
                >
                  {statusText}
                </Chip>
              </td>
              <td>
                <Stack direction="row" gap={0}>
                  <Button
                    size="sm"
                    variant="solid"
                    color="success"
                    onClick={() => {
                      if (item.status !== "approved")
                        handleApprovalUpdate([item.id], "approved");
                    }}
                    sx={{ mr: 1 }}
                  >
                    승인
                  </Button>
                  <Button
                    size="sm"
                    variant="solid"
                    color="danger"
                    onClick={() => {
                      if (item.status !== "rejected")
                        handleApprovalUpdate([item.id], "rejected");
                    }}
                    sx={{ mr: 1 }}
                  >
                    거절
                  </Button>
                </Stack>
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
}
