import { Sheet } from "@mui/joy";
import { FormModal } from "components/form/formModals";
import {
  Object,
  ObjectForSeat,
  Room,
  Seat,
  SeatContainer,
  SeatType,
} from "features/application";

export function RoomSeatModal(props: RoomSeatModalProps) {
  const { open, onClose, room, seats, objects } = props;
  return (
    <FormModal
      title={`${room.name} 교실 자리 배치`}
      open={open}
      handleClose={onClose}
    >
      <Sheet sx={{ p: 0.5, overflow: "auto" }}>
        <SeatContainer {...room.room_size}>
          {seats.map((seat) => {
            return <Seat key={seat.id} {...seat.position} label={seat.name} />;
          })}
          {objects.map((object) => {
            return (
              <Object
                key={object.id}
                {...object.position}
                {...object.size}
                label={object.name}
              />
            );
          })}
        </SeatContainer>
      </Sheet>
    </FormModal>
  );
}

type RoomSeatModalProps = {
  open: boolean;
  onClose: () => void;
  room: Room;
  seats: SeatType[];
  objects: ObjectForSeat[];
};
