import { useEffect, useState } from "react";
import { supabase } from "lib/supabase";
import { Teacher } from "../types/user";

export const useTeachers = () => {
  const [teachers, setTeachers] = useState<Teacher[] | undefined>(undefined);

  const fetchTeachers = async () => {
    const { data, error } = await supabase.from("teacher").select(`*`);

    if (error) throw error;

    setTeachers(data);
  };

  useEffect(() => {
    fetchTeachers();
  }, []);

  return { teachers, fetchTeachers };
};
