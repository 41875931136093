import { Table, Typography } from "@mui/joy";
import PageTitle from "components/ui/PageTitle";
import { Content } from "components/ui/content";
import { BaseOutstay, OutstayForTeacher } from "features/outstay/types";
import { useSql } from "hooks/useSql";
import { supabase } from "lib/supabase";
import { useEffect, useMemo, useState } from "react";

export default function TeacherOutstayCurrent() {
  const [now] = useState(new Date());
  const nowStr = useMemo(
    () => now.toISOString().replace("T", " ").replace("Z", "+00"),
    [now]
  );

  const currentOutstayQ = supabase
    .from("outstay")
    .select("*, student(*), checked_by(name)")
    .lte("from", nowStr)
    .gte("to", nowStr);

  const { data } = useSql<OutstayForTeacher[]>(currentOutstayQ);

  useEffect(() => {
    console.log(data);
  }, [data]);

  return (
    <>
      <PageTitle />
      <Content>
        <Typography level="body-sm">
          현재 시각 {now.toLocaleString()} 기준
        </Typography>
        <Table>
          <thead>
            <tr>
              <th>학생</th>
              <th>신청 사유</th>
              <th>시작</th>
              <th>종료</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((i) => {
              return (
                <tr>
                  <td>{i.student.name}</td>
                  <td>{i.reason}</td>
                  <td>
                    {new Date(i.from as unknown as string).toLocaleString()}
                  </td>
                  <td>
                    {new Date(i.to as unknown as string).toLocaleString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </Content>
    </>
  );
}
