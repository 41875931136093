import { Box, Button, Sheet, Stack, Typography } from "@mui/joy";
import { DotLottiePlayer } from "@dotlottie/react-player";
import ThemeToggle from "components/ui/ThemeToggle";
import { copyright, organization } from "data/constants";
import { router } from "Router";

export default function NotFound() {
  return (
    <Sheet>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100dvh",
          px: 3,
        }}
      >
        <Box
          component="header"
          sx={{
            py: 3,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            fontWeight="lg"
            startDecorator={
              <img src="/icons/favicon-196x196.png" alt="logo" width="20px" />
            }
          >
            {organization.full}
          </Typography>
          <ThemeToggle />
        </Box>
        <Box
          component="main"
          sx={{
            my: "auto",
            py: 2,
            display: "flex",
            flexDirection: "column",
            gap: 2,
            maxWidth: "100%",
            mx: "auto",
            borderRadius: "sm",
          }}
        >
          <Stack alignItems="center">
            <Box sx={{ height: "300px" }}>
              <DotLottiePlayer
                src="/lotties/404_not_found.lottie"
                autoplay
                loop
                style={{ width: "300px" }}
              />
            </Box>
            <Typography level="h2">페이지를 찾지 못했습니다.</Typography>
            <Typography level="body-sm">
              또는, 해당 페이지에 접근할 권한이 없는 것일 수도 있습니다.
            </Typography>
            <Stack direction="row" gap={1} mt={3}>
              <Button variant="outlined" onClick={() => router.navigate("/")}>
                홈으로 가기
              </Button>
              <Button onClick={() => router.navigate(-1)}>뒤로가기</Button>
            </Stack>
          </Stack>
        </Box>
        <Box component="footer" sx={{ py: 3 }}>
          <Typography level="body-sm" textAlign="center">
            © {copyright.name} {copyright.year}
          </Typography>
        </Box>
      </Box>
    </Sheet>
  );
}
