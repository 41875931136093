import { useEffect, useState } from "react";
import { Alert, Box, Button, LinearProgress, Typography } from "@mui/joy";

export default function CheckingAuth() {
  const [isAfterCriteria, setIsAfterCriteria] = useState(false);

  useEffect(() => {
    const timerId = setTimeout(() => setIsAfterCriteria(true), 3000);
    return () => clearTimeout(timerId);
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <Box
      alignItems="center"
      justifyContent="center"
      sx={{
        display: "flex",
        minHeight: "100dvh",
      }}
    >
      <Box sx={{ width: "300px" }}>
        <Typography level="h4">권한 확인중...</Typography>
        <LinearProgress />
        {isAfterCriteria && (
          <Alert
            color="warning"
            sx={{ mt: 2 }}
            endDecorator={
              <Button onClick={refreshPage} color="warning" variant="solid">
                새로고침
              </Button>
            }
          >
            너무 오래 걸리나요?
          </Alert>
        )}
      </Box>
    </Box>
  );
}
