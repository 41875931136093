import { Check, Minus, X } from "react-feather";
import { OutstayStatus } from "../types";
import { ColorPaletteProp } from "@mui/joy";

export const statusMap: {
  [key in OutstayStatus]: {
    kor: string;
    icon: JSX.Element;
    color: ColorPaletteProp;
  };
} = {
  approved: { kor: "승인", icon: <Check />, color: "success" },
  pending: { kor: "대기", icon: <Minus />, color: "neutral" },
  rejected: { kor: "거절", icon: <X />, color: "danger" },
};
