import type { MenuCategory } from "typings";
import { Codepen, Layout, MapPin, Users } from "react-feather";
import { FiAward, FiMusic, FiMessageCircle } from "react-icons/fi";

export const baseMenu = {
  dashboard: {
    name: "대시보드",
    icon: <Layout />,
  },
  account: {
    name: "계정 관리",
    icon: <Users />,
  },
  application: {
    name: "특별실 사용",
    icon: <Codepen />,
  },
  outstay: {
    name: "외출·외박",
    icon: <MapPin />,
  },
  reveille: {
    name: "기상음악",
    icon: <FiMusic />,
  },
  mileage: {
    name: "그린마일리지",
    icon: <FiAward />,
  },
  community: {
    name: "커뮤니티",
    icon: <FiMessageCircle />,
  },
};

export const studentMenu: MenuCategory[] = [
  {
    name: baseMenu.dashboard.name,
    icon: baseMenu.dashboard.icon,
    path: "/student/dashboard",
  },
  {
    name: baseMenu.application.name,
    icon: baseMenu.application.icon,
    children: [
      { name: "신청 및 조회", path: "/student/application/manage" },
      { name: "예약 관리(예정)", path: "/student/application/reserve" },
      { name: "교실출입권한(예정)", path: "/student/application/permission" },
    ],
  },
  {
    name: baseMenu.outstay.name,
    icon: baseMenu.outstay.icon,
    children: [
      { name: "신청 및 조회", path: "/student/outstay/manage" },
      { name: "정기외출(예정)", path: "/student/outstay/reserve" },
    ],
  },
  // {
  //   name: baseMenu.reveille.name,
  //   icon: baseMenu.reveille.icon,
  //   children: [{ name: "신청 및 조회", path: "/student/reveille/manage" }],
  // },
  {
    name: baseMenu.mileage.name,
    icon: baseMenu.mileage.icon,
    children: [
      { name: "내역 조회(예정)", path: "/student/mileage/view" },
      { name: "규칙 일람(예정)", path: "/student/mileage/rules" },
    ],
  },
  // {
  //   name: baseMenu.community.name,
  //   icon: baseMenu.community.icon,
  //   children: [
  //     { name: "공지사항", path: "/student/community/announcements" },
  //     { name: "총학생회", path: "/student/community/clubs" },
  //     { name: "동아리", path: "/student/community/clubs" },
  //     { name: "자유", path: "/student/community/free" },
  //   ],
  // },
];

export const teacherMenu: MenuCategory[] = [
  {
    name: baseMenu.application.name,
    icon: baseMenu.application.icon,
    children: [
      { name: "신청 관리", path: "/teacher/application/manage" },
      { name: "대리신청", path: "/teacher/application/proxy" },
      {
        name: "출석체크",
        path: "/teacher/application/attendance",
      },
    ],
  },
  {
    name: baseMenu.outstay.name,
    icon: baseMenu.outstay.icon,
    children: [
      { name: "전체 신청 관리", path: "/teacher/outstay/manage" },
      { name: "현재 외박자 일람", path: "/teacher/outstay/current" },
    ],
  },
];

export const adminMenu: MenuCategory[] = [
  {
    name: baseMenu.account.name,
    icon: baseMenu.account.icon,
    children: [
      { name: "학생 관리", path: "/admin/account/students" },
      { name: "선생님 관리", path: "/admin/account/teachers" },
    ],
  },
  {
    name: baseMenu.application.name,
    icon: baseMenu.application.icon,
    children: [
      { name: "교실 관리", path: "/admin/application/rooms" },
      { name: "설정", path: "/admin/application/config" },
    ],
  },
  // {
  //   name: "외출/외박",
  //   icon: <MapPin />,
  //   children: [{ name: "환경설정", path: "/admin/outstay/config" }],
  // },
];
