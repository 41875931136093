import { supabase } from "lib/supabase";
import { Room } from "..";

export async function getAllRooms() {
  const { data, error } = await supabase.from("room").select(`*`);

  if (error) throw error;

  return data as Room[];
}
