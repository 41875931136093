import { useEffect, useState } from "react";
import { supabase } from "lib/supabase";
import { ApplicationConfig } from "../types/config";

export const useApplicationConfig = () => {
  const [applicationConfig, setApplicationConfig] = useState<
    ApplicationConfig | undefined
  >(undefined);

  const fetchApplicationConfig = async () => {
    setApplicationConfig(undefined);
    const { data, error } = await supabase
      .from("config")
      .select("value")
      .eq("id", "application")
      .single();

    if (error) throw error;

    const config = data?.value as ApplicationConfig;
    setApplicationConfig(config);
  };

  useEffect(() => {
    fetchApplicationConfig();
  }, []);

  return { applicationConfig, fetchApplicationConfig };
};
