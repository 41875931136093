import { router } from "Router";
import { FormModal } from "components/form/formModals";
import { supabase } from "lib/supabase";
import React, { useEffect } from "react";
import toast from "react-hot-toast";

export default function RootLanding() {
  useEffect(() => {
    (async () => {
      const {
        data: { session },
      } = await supabase.auth.getSession();

      if (!session) {
        router.navigate("/signin");
        return;
      }

      const {
        user: {
          app_metadata: { user_role: role },
        },
      } = session;

      if (!session) {
        router.navigate("/signin");
      } else if (role === "student") {
        router.navigate("/student/dashboard");
      } else if (role === "teacher") {
        router.navigate("/teacher/dashboard");
      } else if (role === "service_role") {
        router.navigate("/admin/dashboard");
      } else {
        supabase.auth.signOut();
        router.navigate("/signin");
        toast.error(
          "E001: user_role이 지정되지 않음. 정상적인 절차로 생성된 계정이 아닙니다.",
          { id: "e001" }
        );
        toast.success("로그아웃했습니다.", { id: "signout" });
      }
    })();
  }, []);

  return <FormModal title="권한 확인 중..." loading />;
}
