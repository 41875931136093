import {
  Box,
  CircularProgress,
  Divider,
  Modal,
  ModalClose,
  ModalDialog,
  Stack,
  Typography,
} from "@mui/joy";
import React, { isValidElement } from "react";
import { FormModalProps } from "typings";

export function FormModal({
  open,
  handleClose,
  closeButton,
  loading,
  icon,
  zIndex,
  title,
  description,
  children,
  actions,
}: FormModalProps) {
  const isDividerNeeded = title && (description || children || actions);
  return (
    <Modal
      open={open ?? true}
      onClose={handleClose}
      sx={{
        zIndex,
      }}
    >
      <ModalDialog color="primary" size="lg" variant="outlined">
        {closeButton ? <ModalClose /> : null}

        {title &&
          (typeof title === "string" ? (
            <Typography
              level="title-lg"
              fontWeight="bold"
              startDecorator={
                loading ? <CircularProgress size="sm" /> : icon ?? undefined
              }
            >
              {title}
            </Typography>
          ) : isValidElement(title) ? (
            title
          ) : null)}

        {isDividerNeeded && <Divider />}
        {description ? (
          <Typography textColor="text.tertiary">{description}</Typography>
        ) : null}
        {isValidElement(children) ? children : null}
        {actions ? (
          <Box sx={{ display: "flex", gap: 1, justifyContent: "flex-end" }}>
            {actions.map((element) => {
              return element;
            })}
          </Box>
        ) : null}
      </ModalDialog>
    </Modal>
  );
}

export function ModalDataView({
  title,
  children,
}: {
  title: string;
  children: React.ReactNode;
}) {
  return (
    <Stack spacing={1}>
      <Typography level="title-lg" fontWeight="bold">
        {title}
      </Typography>
      {children}
    </Stack>
  );
}
