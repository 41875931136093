import { Box, Divider, GlobalStyles, Sheet, Typography } from "@mui/joy";
import type { ColorPaletteProp } from "@mui/joy";
import { closeSidebar } from "utils/manageSidebar";
import { EduSetLogo } from "components/ui/logo";
import { organization } from "data/constants";
import ThemeToggle from "components/ui/ThemeToggle";

export default function Sidebar({
  menuTop,
  menuBottom,
  profile,
  color = "primary",
}: {
  menuTop: JSX.Element;
  menuBottom: JSX.Element;
  profile: JSX.Element;
  color?: ColorPaletteProp;
}) {
  //   useEffect(() => {
  //     (async () => {
  //       const {
  //         data: { session },
  //       } = await supabase.auth.getSession();

  //       if (!session) {
  //         router.navigate("/signin");
  //         return;
  //       }

  //       const {
  //         user: {
  //           id: uid,
  //           app_metadata: { user_role },
  //         },
  //       } = session;

  //       if (user_role === "student") {
  //         const { data: rows } = await supabase
  //           .from("student")
  //           .select("name, grade, class, number, avatar")
  //           .eq("id", uid)
  //           .single();

  //         const data = rows!;

  //         setProfile({
  //           primaryText: data.name,
  //           secondaryText:
  //             data.grade + "학년 " + data.class + "반 " + data.number + "번",
  //           imageUrl: data.avatar,
  //         });
  //       } else if (user_role === "teacher") {
  //         const { data: rows } = await supabase
  //           .from("teacher")
  //           .select("name, homeroom, avatar")
  //           .eq("id", uid)
  //           .single();

  //         const data = rows!;

  //         let secondaryText;
  //         if (data.homeroom) {
  //           const { grade, classNum } = classStyleNumber2classObj(data.homeroom);
  //           secondaryText = `${grade}학년 ${classNum}반 담임`;
  //         } else {
  //           //TODO: 담임이 아닐 때
  //           secondaryText = "좋은 하루 되세요!";
  //         }
  //         setProfile({
  //           primaryText: data.name + " 선생님",
  //           secondaryText,
  //           imageUrl: data.avatar,
  //         });
  //       }
  //     })();
  //   }, []);

  const logo = <EduSetLogo />;

  return (
    <Sheet
      className="Sidebar"
      variant="soft"
      color={color}
      invertedColors
      sx={{
        position: {
          xs: "fixed",
          md: "sticky",
        },
        transform: {
          xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
          md: "none",
        },
        transition: "transform 0.4s, width 0.4s",
        zIndex: 1000,
        height: "100dvh",
        width: "var(--Sidebar-width)",
        top: 0,
        p: 1.5,
        py: 3,
        flexShrink: 0,
        display: "flex",
        flexDirection: "column",
        gap: 2,
        borderRight: "1px solid",
        borderColor: "divider",
      }}
    >
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "224px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "256px",
            },
          },
        })}
      />
      <Box
        className="Sidebar-overlay"
        sx={{
          position: "fixed",
          zIndex: 999,
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          bgcolor: "background.body",
          opacity: "calc(var(--SideNavigation-slideIn, 0) - 0.2)",
          transition: "opacity 0.4s",
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
            lg: "translateX(-100%)",
          },
        }}
        onClick={() => closeSidebar()}
      />
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        {logo}
        <Typography fontWeight="xl">{organization.full}</Typography>
        <ThemeToggle sx={{ ml: "auto" }} />
      </Box>
      {/* <Input startDecorator={<Search size={20} />} placeholder="기능 검색..." /> */}
      <Box
        sx={{
          minHeight: 0,
          overflow: "hidden auto",
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
          color,
        }}
      >
        {menuTop}

        <Box sx={{ color }}>{menuBottom}</Box>
      </Box>
      <Divider />
      {profile}
    </Sheet>
  );
}
