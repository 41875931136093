import { BottomActions, GeneratedMenu } from "components/layout/menu";
import HeadBreadcrumbs from "components/layout/HeadBreadcrumbs";
import MenuLayout from "components/layout/MenuLayout";
import Sidebar from "components/layout/Sidebar";
import { useAuth } from "features/auth";
import Profile from "components/layout/Profile";
import { studentMenu } from "data/menu";
import { HelpCircle, Settings } from "react-feather";
import ProfileFallback from "components/layout/ProfileFallback";
import StudentSettingsModal from "components/settings/StudentSettingsModal";
import { useState } from "react";

export default function StudentLayout() {
  const { studentProfile } = useAuth();
  const profile = studentProfile
    ? {
        primaryText: studentProfile.name,
        secondaryText: `${studentProfile.grade}학년 ${studentProfile.class}반 ${studentProfile.number}번`,
        avatar: studentProfile.avatar,
      }
    : studentProfile;

  const ProfileElement = profile ? (
    <Profile
      primaryText={profile.primaryText}
      secondaryText={profile.secondaryText}
      avatar={profile.avatar}
    />
  ) : profile === undefined ? (
    <Profile primaryText={"-"} secondaryText={"새로고침 중..."} />
  ) : (
    <ProfileFallback />
  );

  function StudentSidebar() {
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);
    return (
      <>
        <Sidebar
          menuTop={<GeneratedMenu menu={studentMenu} />}
          menuBottom={
            <>
              <BottomActions
                actions={[
                  {
                    icon: <Settings />,
                    label: "설정",
                    onClick: () => setIsSettingsOpen(true),
                  },
                  {
                    icon: <HelpCircle />,
                    label: "도움말",
                    onClick: () => {
                      window.open(
                        "https://kongtek.notion.site/2292b397248e4d10baa3f1b8eeb6d3cc?pvs=4",
                        "_blank"
                      );
                    },
                  },
                ]}
              />
            </>
          }
          profile={ProfileElement}
        />
        {studentProfile && (
          <StudentSettingsModal
            open={isSettingsOpen}
            handleClose={() => setIsSettingsOpen(false)}
            student={studentProfile}
          />
        )}
      </>
    );
  }

  return (
    <MenuLayout
      sidebar={<StudentSidebar />}
      breadcrumbs={<HeadBreadcrumbs menu={studentMenu} />}
    />
  );
}
