import { Avatar, Box, IconButton, Typography } from "@mui/joy";
import { useAuth } from "features/auth";
import { supabase } from "lib/supabase";
import { LogOut } from "react-feather";

export default function Profile({
  avatar,
  primaryText,
  secondaryText,
  onClick,
}: {
  avatar?: string;
  primaryText: string;
  secondaryText: string;
  onClick?: () => any;
}) {
  const { signOut } = useAuth();

  const imageUrl = avatar
    ? supabase.storage.from("avatar").getPublicUrl(avatar, {
        transform: {
          width: 100,
          height: 100,
          resize: "cover",
        },
      }).data.publicUrl
    : undefined;

  return (
    <Box
      sx={{ display: "flex", gap: 1, alignItems: "center" }}
      onClick={onClick}
    >
      <>
        <Avatar variant="outlined" src={imageUrl ?? undefined} />
        <Box sx={{ minWidth: 0, flex: 1 }}>
          <Typography fontSize="sm" fontWeight="lg">
            {primaryText}
          </Typography>
          <Typography level={"body-xs"}>{secondaryText}</Typography>{" "}
        </Box>
        <IconButton
          variant="plain"
          color="neutral"
          onClick={(e) => {
            e.stopPropagation();
            signOut();
          }}
        >
          <LogOut />
        </IconButton>
      </>
    </Box>
  );
}
