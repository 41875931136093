import {
  Autocomplete,
  AutocompleteOption,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  Typography,
} from "@mui/joy";
import { Room } from "features/application";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { FiMaximize2 } from "react-icons/fi";
import { useRooms } from "../../hooks/useRooms";
import _ from "lodash";
import { RoomSelectModal } from "./RoomSelectModal";

export function RoomSelect({ room, setRoom, defaultRoomId }: RoomSelectProps) {
  const { rooms: rawRooms, loading } = useRooms();

  const rooms = useMemo(() => {
    return _.sortBy(rawRooms, "sector", "name");
  }, [rawRooms]);

  const isInitialLoading = useMemo(() => {
    return loading && !rooms.length;
  }, [loading, rooms]);

  useEffect(() => {
    if (!isInitialLoading) {
      if (defaultRoomId) {
        const target = _.find(rooms, { id: defaultRoomId.id });
        if (target) setRoom(target);
      }
    }
  }, [isInitialLoading, defaultRoomId]);

  const [isRoomSelectModalOpen, setIsRoomSelectModalOpen] = useState(false);
  const handleOpenRoomSelectModal = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    e.stopPropagation();
    setIsRoomSelectModalOpen(true);
  };

  return (
    <>
      <Autocomplete
        loading={isInitialLoading}
        placeholder="검색"
        options={rooms}
        getOptionLabel={(option) => option.name}
        groupBy={(option) => `${option.sector}`}
        value={room}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
        renderOption={(props, option) => {
          return (
            <AutocompleteOption {...props}>
              <Typography
                endDecorator={option.seat_selection ? SeatChip : null}
              >
                {option.name}
              </Typography>
            </AutocompleteOption>
          );
        }}
        startDecorator={room?.seat_selection ? SeatChip : null}
        endDecorator={
          isInitialLoading ? (
            <CircularProgress
              size="sm"
              sx={{ bgcolor: "background.surface" }}
            />
          ) : (
            !room && (
              <IconButton
                variant="soft"
                color="primary"
                onClick={handleOpenRoomSelectModal}
              >
                <FiMaximize2 />
              </IconButton>
            )
          )
        }
        onChange={(_, newValue) => {
          setRoom(newValue);
        }}
      />
      <RoomSelectModal
        open={isRoomSelectModalOpen}
        handleClose={() => setIsRoomSelectModalOpen(false)}
        rooms={rooms}
        room={room}
        handleSelect={setRoom}
      />
    </>
  );
}

type RoomSelectProps = {
  room: Room | null;
  setRoom: (room: Room | null) => void;
  defaultRoomId?: Partial<Room> & Pick<Room, "id">;
  initialRooms?: Room[];
};

const SeatChip = (
  <Chip color="warning" size="sm" variant="soft">
    좌석
  </Chip>
);
