import { Button, Card, CardContent, Stack, Typography } from "@mui/joy";
import { OutstayForStudent } from "features/outstay/types";
import { dateDiffInDays } from "utils/manageTime";
import { removeOutstay } from "features/outstay/services";
import toast from "react-hot-toast";
import { useState } from "react";
import OutstayStatusChip from "../OutstayStatusChip";

export default function OutstayCard({
  id,
  created_at,
  from,
  to,
  reason,
  status,
  fireReload,
}: OutstayForStudent & { fireReload: () => void }) {
  const title =
    reason.length > 0 ? reason : `${dateDiffInDays(from, to) + 1}일간의 외출`;
  const detail = `${from.getMonth() + 1}월 ${from.getDate()}일 ${from
    .getHours()
    .toString()
    .padStart(2, "0")}:${from.getMinutes().toString().padStart(2, "0")} ~ ${
    to.getMonth() + 1
  }월 ${to.getDate()}일 ${to.getHours().toString().padStart(2, "0")}:${to
    .getMinutes()
    .toString()
    .padStart(2, "0")}`;

  const [isDeleting, setIsDeleting] = useState(false);

  const handleRemoveOutstay = (id: number) => async () => {
    try {
      setIsDeleting(true);
      await removeOutstay(id);
      toast.success("삭제 완료!");
    } catch (error) {
      toast.error("삭제에 실패했습니다");
      setIsDeleting(false);
    } finally {
      fireReload();
    }
  };

  return (
    <Card variant="outlined" sx={{ boxShadow: "none", width: 300 }}>
      <div>
        <Typography level="title-lg">{title}</Typography>
        <Typography level="body-sm">{detail}</Typography>
      </div>
      <CardContent orientation="horizontal">
        <div>
          <Typography level="body-xs">승인 상태</Typography>
          <OutstayStatusChip status={status} />
        </div>
        <Stack direction="row" gap={1} alignItems="center" sx={{ ml: "auto" }}>
          {/* //TODO: implement edit function  */}
          {/* <Button
            variant="outlined"
            size="md"
            color="primary"
            aria-label="Explore Bahamas Islands"
            sx={{ fontWeight: 600 }}
          >
            수정
          </Button> */}
          <Button
            variant="outlined"
            size="md"
            color="danger"
            sx={{ fontWeight: 600 }}
            loading={isDeleting}
            onClick={handleRemoveOutstay(id)}
          >
            삭제
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
