import {
  Button,
  Card,
  Divider,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Typography,
} from "@mui/joy";
import { FormModal } from "components/form/formModals";
import { Room } from "features/application";
import { supabase } from "lib/supabase";
import _ from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import {
  Clipboard,
  Download,
  MinusSquare,
  PlusSquare,
  Table as TableIcon,
} from "react-feather";

export default function AddRoomsModal({
  open,
  handleClose,
  fireUpdate,
}: {
  open: boolean;
  handleClose: () => void;
  fireUpdate: () => void;
}) {
  type NewRoom = Omit<Room, "id" | "room_size">;
  const defaultRoom: NewRoom = {
    name: "",
    seat_selection: false,
    sector: "",
    capacity: 20,
    number: 0,
  };
  const [newRooms, setNewRooms] = useState<NewRoom[]>([defaultRoom]);
  const handleValueChange = (index: number, key: keyof NewRoom, value: any) => {
    setNewRooms((prev) =>
      _.map(prev, (room, i) => {
        if (i === index) {
          return {
            ...room,
            [key]: value,
          };
        } else {
          return room;
        }
      })
    );
  };

  const [isExcelImportModalOpen, setIsExcelImportModalOpen] = useState(false);

  const handleAddRooms = async () => {
    const promises = newRooms.map((room) => {
      return supabase.from("room").insert(room);
    });

    const results = await Promise.allSettled(promises);

    let resultCount: { success: number[]; failure: number[] } = {
      success: [],
      failure: [],
    };
    results.forEach((result, index) => {
      if (result.status === "fulfilled" && !result.value.error) {
        resultCount.success.push(index);
      } else {
        resultCount.failure.push(index);
      }
    });

    if (resultCount.success.length > 0)
      toast.success(`교실 ${resultCount.success.length}개를 추가했습니다.`);
    if (resultCount.failure.length > 0) {
      toast.error(
        `교실 ${resultCount.failure.length}개를 추가하지 못했습니다.`
      );
      toast.error(`추가 실패한 교실들은 추가하기 화면에 남아 있습니다.`);

      const failedRooms = _.filter(newRooms, (room, index) =>
        _.includes(resultCount.failure, index)
      );
      setNewRooms(failedRooms);
    } else {
      setNewRooms([defaultRoom]);
    }
    // if (error) {
    //   toast.error("교실 추가에 실패했습니다.");
    // } else {
    //   toast.success("교실이 추가되었습니다.");
    // }

    fireUpdate();
    handleClose();
  };

  const handleDownloadExcelForm = async () => {
    const { data, error } = await supabase.storage
      .from("assets")
      .createSignedUrl("Add classroom form.xlsx", 60);

    if (error || !data) {
      toast.error("파일 다운로드에 실패했습니다. 다시 시도해주세요.");
      return;
    }

    window.location.href = data.signedUrl;
  };

  const handleRoomsImportFromClipboard = async () => {
    try {
      const text = await navigator.clipboard.readText();
      const lines = text.trim().split("\n");

      const objects = lines.map((line) => {
        const parts = line.split("\t");

        return {
          name: parts[0].trim(),
          capacity: parseInt(parts[1], 10),
          seat_selection: parts[2].trim() === "여" ? true : false,
          sector: parts[3].trim(),
          number: parseInt(parts[4], 10),
        } as NewRoom;
      });

      setNewRooms((prev) => {
        if (_.isEqual(prev, [defaultRoom])) return objects;
        else return prev.concat(objects);
      });

      toast.success("성공적으로 정보를 불러왔습니다!");
      setIsExcelImportModalOpen(false);
    } catch (err) {
      toast.error("정보를 불러오지 못했습니다.");
      console.error(err);
    }
  };

  return (
    <>
      <FormModal
        open={open}
        title="교실 추가"
        icon={<PlusSquare />}
        actions={[
          <Button onClick={handleClose} color="neutral" variant="plain">
            잠시 닫기 (입력 내용 보존)
          </Button>,
          <Button
            onClick={() => setIsExcelImportModalOpen(true)}
            startDecorator={<TableIcon />}
            color="success"
          >
            엑셀에서 작성 후 붙여넣기
          </Button>,
          <Button onClick={handleAddRooms}>추가</Button>,
        ]}
      >
        <Stack gap={1} sx={{ overflow: "auto" }}>
          {newRooms.map((room, index) => {
            return (
              <Stack gap={1}>
                <Stack direction="row" gap={1}>
                  <IconButton
                    variant="soft"
                    color="danger"
                    onClick={() => {
                      setNewRooms((prev) =>
                        _.filter(prev, (_, i) => i !== index)
                      );
                    }}
                  >
                    <MinusSquare />
                  </IconButton>
                  <FormControl>
                    <FormLabel>교실명</FormLabel>
                    <Input
                      type="text"
                      value={room.name}
                      onChange={(e) => {
                        handleValueChange(index, "name", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>구역</FormLabel>
                    <Input
                      type="text"
                      value={room.sector}
                      sx={{ width: "150px" }}
                      onChange={(e) => {
                        handleValueChange(index, "sector", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>호실번호</FormLabel>
                    <Input
                      type="number"
                      value={room.number}
                      sx={{ width: "100px" }}
                      onChange={(e) => {
                        handleValueChange(index, "number", e.target.value);
                      }}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel>수용인원</FormLabel>
                    <Input
                      type="number"
                      value={room.capacity}
                      sx={{ width: "100px" }}
                      onChange={(e) => {
                        handleValueChange(index, "capacity", e.target.value);
                      }}
                    />
                  </FormControl>
                </Stack>
                <Divider />
              </Stack>
            );
          })}
          <IconButton
            variant="soft"
            color="success"
            onClick={() => setNewRooms((prev) => prev.concat(defaultRoom))}
          >
            <PlusSquare />
          </IconButton>
        </Stack>
      </FormModal>
      <FormModal
        open={isExcelImportModalOpen}
        handleClose={() => setIsExcelImportModalOpen(false)}
        title="엑셀에서 작성 후 붙여넣기"
        icon={<TableIcon />}
        actions={[
          <Button
            color="neutral"
            variant="plain"
            onClick={() => setIsExcelImportModalOpen(false)}
          >
            닫기
          </Button>,
          <Button
            color="success"
            startDecorator={<Clipboard size={20} />}
            onClick={handleRoomsImportFromClipboard}
          >
            붙여넣기
          </Button>,
        ]}
      >
        <Stack gap={2}>
          <Card
            variant="soft"
            color="success"
            onClick={handleDownloadExcelForm}
          >
            <Stack direction="row" gap={1}>
              <Card variant="outlined" sx={{ p: 1 }}>
                <Download />
              </Card>
              <Stack>
                <Typography fontWeight="lg">양식 다운로드</Typography>
                <Typography level="body-sm">Add room form.xlsx</Typography>
              </Stack>
            </Stack>
          </Card>
          <Typography textColor="text.tertiary">
            양식을 다운로드해 채워넣고, 채워넣은 부분을 복사한 뒤<br />
            아래의 '붙여넣기' 버튼을 눌러주세요.
          </Typography>
        </Stack>
      </FormModal>
    </>
  );
}
