import { useEffect, useState } from "react";
import { ApplicationConfig } from "features/application/types";
import { getApplicationConfig } from "features/application/services";

export function useConfig() {
  const [config, setConfig] = useState<ApplicationConfig | undefined>(
    undefined
  );
  const [loading, setLoading] = useState(true);

  const handleRefresh = async () => {
    setLoading(true);

    const config = await getApplicationConfig();
    setConfig(config);

    setLoading(false);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  return { config, loading, handleRefresh };
}
