import { Box, Typography } from "@mui/joy";
import { adminMenu, studentMenu, teacherMenu } from "data/menu";
import { cloneElement } from "react";
import { useLocation } from "react-router-dom";
import { getLocationFromPath } from "utils/manageLocation";

export default function PageTitle({
  title,
  actions,
}: {
  title?: string;
  actions?: JSX.Element[];
}) {
  const location = useLocation();

  const studentTitle = getLocationFromPath(studentMenu, location.pathname);
  const teacherTitle = getLocationFromPath(teacherMenu, location.pathname);
  const adminTitle = getLocationFromPath(adminMenu, location.pathname);
  const titleString =
    title ??
    (location.pathname.startsWith("/student")
      ? studentTitle?.slice(-1)[0] ?? ""
      : location.pathname.startsWith("/teacher")
      ? teacherTitle?.slice(-1)[0] ?? ""
      : adminTitle?.slice(-1)[0] ?? "");

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        my: 1,
        gap: 1,
        flexWrap: "wrap",
        "& > *": {
          minWidth: "clamp(0px, (500px - 100%) * 999, 100%)",
          flexGrow: 1,
        },
      }}
    >
      <Typography level="h1" fontSize="xl4">
        {titleString}
      </Typography>
      <Box sx={{ flex: 999 }} />
      <Box sx={{ display: "flex", gap: 1, "& > *": { flexGrow: 1 } }}>
        {actions !== undefined
          ? actions.map((element, index) => {
              const action = cloneElement(element, { key: index });
              return action;
            })
          : null}
      </Box>
    </Box>
  );
}
