import {
  Alert,
  Button,
  ColorPaletteProp,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Typography,
} from "@mui/joy";
import { router } from "Router";
import React, { useEffect, useMemo, useState } from "react";
import { ChevronDown, ChevronUp } from "react-feather";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import type { MenuCategory } from "typings";

export function MenuList({
  category,
  color,
}: {
  category: MenuCategory;
  color: ColorPaletteProp;
}) {
  const location = useLocation();

  const containsOpenTab = useMemo(() => {
    return (
      category.children?.some((item) => item.path === location.pathname) ??
      false
    );
  }, [location, category.children]);
  const [isCollapsed, setIsCollapsed] = useState(
    containsOpenTab ? false : true
  );

  useEffect(() => {
    if (containsOpenTab) setIsCollapsed(false);
  }, [containsOpenTab]);

  const iconWithSize = React.cloneElement(category.icon, { size: 20 });
  const categorySelected = location.pathname === category.path;

  return (
    <>
      <ListItem nested>
        <ListItemButton
          variant={categorySelected ? "solid" : undefined}
          color={categorySelected ? color : undefined}
          onClick={() => {
            if (containsOpenTab) setIsCollapsed(false);
            setIsCollapsed(!isCollapsed);
          }}
        >
          <ListItemDecorator>{iconWithSize}</ListItemDecorator>
          <ListItemContent>
            <Typography fontWeight="bold">{category.name}</Typography>
          </ListItemContent>
          {isCollapsed ? <ChevronDown size={20} /> : <ChevronUp size={20} />}
        </ListItemButton>
        {!isCollapsed && category.children ? (
          <List size="sm">
            {category.children.map((item) => {
              const selected = location.pathname === item.path;
              if (selected && isCollapsed) setIsCollapsed(false);
              return (
                <ListItem key={item.name}>
                  <ListItemButton
                    variant={selected ? "solid" : undefined}
                    color={selected ? color : undefined}
                    onClick={
                      item.onClick !== undefined
                        ? item.onClick
                        : item.path !== undefined
                        ? () => {
                            router.navigate(item.path ?? "/");
                          }
                        : undefined
                    }
                  >
                    {item.name}
                  </ListItemButton>
                </ListItem>
              );
            })}
          </List>
        ) : null}
      </ListItem>
    </>
  );
}

export function SingleMenu({
  category,
  color,
}: {
  category: MenuCategory;
  color: ColorPaletteProp;
}) {
  const location = useLocation();

  const iconWithSize = React.cloneElement(category.icon, { size: 20 });
  const categorySelected = location.pathname === category.path;

  return (
    <ListItem>
      <ListItemButton
        variant={categorySelected ? "solid" : undefined}
        color={categorySelected ? color : undefined}
        onClick={
          category.onClick ?? category.path
            ? () => {
                router.navigate(category.path ?? "/");
              }
            : undefined
        }
      >
        <ListItemDecorator>{iconWithSize}</ListItemDecorator>
        <ListItemContent>
          <Typography
            fontWeight="bold"
            sx={{ color: categorySelected ? "white" : undefined }}
          >
            {category.name}
          </Typography>
        </ListItemContent>
      </ListItemButton>
    </ListItem>
  );
}

export function GeneratedMenu({ menu }: { menu: MenuCategory[] }) {
  return (
    <List
      sx={{
        mb: "auto",
        flexGrow: 0,
        borderRadius: "8px",
        "--ListItem-radius": "8px",
        "--List-gap": "4px",
        "--List-nestedInsetStart": "40px",
        overflowY: "auto",
        scrollbarWidth: "none" /* For Firefox */,
        "&::-webkit-scrollbar": {
          /* For Chrome, Safari, and Opera */ display: "none",
        },
      }}
    >
      {menu.map((category) => {
        if (category.children)
          return (
            <MenuList key={category.name} category={category} color="primary" />
          );
        else
          return (
            <SingleMenu
              key={category.name}
              category={category}
              color="primary"
            />
          );
      })}
    </List>
  );
}

export function BottomActions({
  actions,
}: {
  actions: { label: string; icon: JSX.Element; onClick?: () => any }[];
}) {
  return (
    <>
      <List
        sx={{
          mt: "auto",
          flexGrow: 0,
          "--ListItem-radius": "8px",
          "--List-gap": "8px",
        }}
      >
        {actions.map((action) => {
          const icon = React.cloneElement(action.icon, { size: 20 });
          return (
            <ListItem key={action.label}>
              <ListItemButton onClick={action.onClick}>
                <ListItemDecorator>{icon}</ListItemDecorator>
                <ListItemContent>{action.label}</ListItemContent>
              </ListItemButton>
            </ListItem>
          );
        })}
      </List>

      <Alert
        size="sm"
        endDecorator={
          <Button
            size="sm"
            variant="outlined"
            onClick={() => {
              navigator.clipboard.writeText("bugreport@edu-set.com");
              toast("오류 화면을 캡처하여 보내주시면 신속히 대응하겠습니다.");
              toast("신고용 이메일 주소가 복사되었습니다.");
            }}
          >
            신고
          </Button>
        }
      >
        베타 버전입니다. 오류는 <br />
        bugreport@edu-set.com
        <br />에 신고해주세요.
      </Alert>
    </>
  );
}
