import { supabase } from "lib/supabase";
import toast from "react-hot-toast";

export const signIn = async (
  email: string,
  password: string,
  option: { showToast?: boolean } = { showToast: true }
) => {
  const result = await supabase.auth.signInWithPassword({
    email,
    password,
  });
  if (option.showToast) {
    if (result.error) {
      toast.error("로그인하지 못했습니다");
      console.error(result.error);
    } else {
      toast.success("로그인되었습니다");
    }
  }
  return result;
};
