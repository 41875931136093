import { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { Info, PlusSquare } from "react-feather";
import { FiAlertCircle } from "react-icons/fi";
import {
  Alert,
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
} from "@mui/joy";

import {
  ConfigView,
  EmptyApplicationCard,
  getMaxSession,
  getTimePeriod,
  useApplicationConfig,
  useRooms,
  type TimePeriod,
  useApplications,
  Application,
  SeatType,
  Room,
} from "features/application";
import PageTitle from "components/ui/PageTitle";
import { FormModal } from "components/form/formModals";
import ApplicationForm from "features/application/components/ApplicationForm/ApplicationForm";
import { Content } from "components/ui/content";
import { date2str, isTimeBetween, time2str } from "utils/manageTime";

import { useAuth, useTeachers } from "features/auth";
import { useNow } from "hooks";
import ApplicationView from "./ApplicationView";

const applicationQuery = `
*,
teacher!application_teacher_fkey(name),
room(*),
proxy_teacher(name), 
seat(*)`;

export type IApplication = Application & {
  teacher: { name: string };
  room: Room;
  seat: SeatType | null;
  proxy_teacher: { name: string };
};

export default function StudentApplicationManage() {
  const { user } = useAuth();
  const uid = user!.id; //this page is protected by ProtectedRoute

  const { now } = useNow();
  const dateStr = useMemo(() => date2str(now), [now]);
  const timeStr = useMemo(() => time2str(now), [now]);

  const { applications, fetchApplications } = useApplications(
    uid,
    dateStr,
    applicationQuery
  ) as unknown as { applications: IApplication[]; fetchApplications: Function };
  const { applicationConfig: config } = useApplicationConfig();
  const { rooms } = useRooms();
  const { teachers } = useTeachers();

  const [isApplicationFormOpen, setIsApplicationFormOpen] = useState(false);
  const [isConfigViewOpen, setIsConfigViewOpen] = useState(false);

  const maxSession = config ? getMaxSession(dateStr, config.session) : 0;
  const applyPeriods: TimePeriod[] = config
    ? getTimePeriod(dateStr, config.applyTime)
    : [];

  const isTimeValid = isTimeBetween(timeStr, applyPeriods);

  const isLoading = !config || !applications || !rooms || !teachers;
  const isAppliable = isTimeValid;

  return (
    <>
      <PageTitle
        actions={[
          <IconButton
            variant="outlined"
            onClick={() => setIsConfigViewOpen(true)}
          >
            <Info />
          </IconButton>,
          <Button
            startDecorator={<PlusSquare />}
            onClick={() => setIsApplicationFormOpen(true)}
            disabled={!isAppliable}
          >
            신청하기
          </Button>,
        ]}
      />
      {isLoading ? (
        <FormModal open={true} loading title={"불러오는 중..."} />
      ) : (
        <Content>
          <Stack direction="column" gap={2} sx={{ height: "100%" }}>
            <Stack direction="column" gap={1}>
              {!isTimeValid && (
                <NotAvailableTimeAlert
                  openInfo={() => setIsConfigViewOpen(true)}
                />
              )}
            </Stack>
            {maxSession === 0 ? (
              <NoApplicationsAssigned />
            ) : (
              <Stack direction="column" gap={1}>
                {Array.from({ length: maxSession }, (_, i) => i + 1).map(
                  (session) => {
                    const application = _.find(applications, { session });

                    if (application)
                      return (
                        <ApplicationView
                          key={session}
                          data={application}
                          interactable={isAppliable}
                          fireReload={() => fetchApplications()}
                        />
                      );
                    else
                      return (
                        <EmptyApplicationCard session={session} key={session} />
                      );
                  }
                )}
              </Stack>
            )}
          </Stack>
        </Content>
      )}
      {config ? (
        <>
          {rooms && teachers ? (
            <ApplicationForm
              open={isApplicationFormOpen}
              handleClose={() => setIsApplicationFormOpen(false)}
              fireReload={() => fetchApplications()}
              initialData={{ rooms, teachers, config }}
            />
          ) : null}
          <ConfigView
            open={isConfigViewOpen}
            handleClose={() => setIsConfigViewOpen(false)}
            maxSession={maxSession}
            applyPeriods={applyPeriods}
            config={config}
          />
        </>
      ) : null}
    </>
  );
}

function NotAvailableTimeAlert({ openInfo }: { openInfo: () => void }) {
  return (
    <Alert
      variant="soft"
      size="sm"
      color="danger"
      endDecorator={
        <Button color="danger" variant="plain" onClick={openInfo}>
          정보 보기
        </Button>
      }
    >
      신청 가능 시간이 아닙니다.
    </Alert>
  );
}

function NoApplicationsAssigned() {
  return (
    <Stack
      direction="column"
      sx={{ flex: 1 }}
      alignItems="center"
      justifyContent="center"
    >
      <Card variant="soft">
        <CardContent>
          <Typography textAlign="center" color="warning">
            <FiAlertCircle size={30} />
            <br />
            배정된 교시가 없습니다
          </Typography>
        </CardContent>
      </Card>
    </Stack>
  );
}
