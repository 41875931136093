import { useEffect, useState } from "react";
import { Room } from "features/application/types";
import { supabase } from "lib/supabase";
import { PostgrestError } from "@supabase/supabase-js";

export function useRooms() {
  const [rooms, setRooms] = useState<Room[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<PostgrestError | null>(null);

  const handleRefresh = async () => {
    setLoading(true);
    const { data, error } = await supabase.from("room").select("*");
    if (error) {
      setError(error);
      setLoading(false);
      return;
    }

    setRooms(data);
    setLoading(false);
  };

  useEffect(() => {
    handleRefresh();
  }, []);

  return { rooms, loading, error, handleRefresh };
}
