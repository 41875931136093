import { Alert, Box, Grid, Sheet, Stack, Typography } from "@mui/joy";
import MealCard from "./MealCard";
import ReveilleCard from "./ReveilleCard";
import ApplicationCard from "./ApplicationCard";
import LinksCard from "./LinksCard";
import MileageCard from "./MileageCard";

export default function StudentDashBoard() {
  return (
    <>
      <Sheet>
        <Grid container columns={{ xs: 4, sm: 8, lg: 12 }} spacing={2}>
          <Grid xs={8}>
            <ApplicationCard />
          </Grid>
          <Grid xs={4}>
            <MealCard />
          </Grid>
          {/* <Grid xs={4}>
            <ReveilleCard />
          </Grid> */}
          <Grid xs={4}>
            <LinksCard />
          </Grid>
          <Grid xs={4}>
            <MileageCard />
          </Grid>
        </Grid>
      </Sheet>
    </>
  );
}
