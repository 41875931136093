import { RealtimeChannel } from "@supabase/supabase-js";
import { Application } from "features/application";
import { supabase } from "lib/supabase";
import { useEffect, useRef, useState } from "react";
import { DateStr } from "typings";

export function useRoomApplications(roomId: number, dateStr: DateStr) {
  const [applications, setApplications] = useState<Application[] | undefined>(
    undefined
  );
  const handleRefresh = async (roomId: number, dateStr: DateStr) => {
    const applications = await getRoomApplications(roomId, dateStr);
    setApplications(applications);
  };

  const channelRef = useRef<RealtimeChannel | null>(null);
  useEffect(() => {
    handleRefresh(roomId, dateStr);
    const channel = supabase
      .channel("application_db_changes")
      .on(
        "postgres_changes",
        {
          event: "*",
          schema: "public",
          table: "application",
        },
        () => {
          handleRefresh(roomId, dateStr);
        }
      )
      .subscribe();
    channelRef.current = channel;

    return () => {
      if (channelRef.current) {
        setApplications(undefined);
        supabase.removeChannel(channelRef.current);
        channelRef.current = null;
      }
    };
  }, [roomId, dateStr]);

  return { applications, handleRefresh };
}

const getRoomApplications = async (roomId: number, dateStr: DateStr) => {
  const { data, error } = await supabase
    .from("application")
    .select("*")
    .in("status", ["approved", "pending"])
    .eq("room", roomId)
    .eq("date", dateStr);

  if (error) throw error;

  return data as Application[];
};
