import { Box, Card, CardContent, Stack, Typography } from "@mui/joy";
import { SxProps, VariantProp } from "@mui/joy/styles/types";

export default function DataCard({
  title,
  icon,
  variant,
  action,
  children,
  sx,
}: {
  title: string;
  children: React.ReactNode;
  sx?: SxProps;
  variant?: VariantProp;
  icon?: React.ReactNode;
  action?: React.ReactNode;
}) {
  const newSx = {
    ...{
      maxHeight: "350px",
      height: "100%",
    },
    ...sx,
  };
  return (
    <Card sx={newSx} variant={variant}>
      <CardContent>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ mb: 1 }}
        >
          <Typography
            level="h4"
            startDecorator={icon}
            sx={{ color: "text.secondary" }}
            fontWeight="bold"
          >
            {title}
          </Typography>
          <Box>{action}</Box>
        </Stack>
        {children}
      </CardContent>
    </Card>
  );
}
