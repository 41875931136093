import * as React from "react";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion, { accordionClasses } from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import { MinimalApplicationType } from ".";
import {
  Avatar,
  Button,
  Card,
  CardActions,
  CardContent,
  Chip,
  Grid,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import _ from "lodash";
import { Room, approvalMap } from "features/application";
import { FormModal } from "components/form/formModals";
import { useEffect, useState } from "react";
import { secondsToKorean } from "utils/manageTime";
import { supabase } from "lib/supabase";
import toast from "react-hot-toast";
import { Student } from "features/auth";
import { RoomSeatModal } from "../../../../features/application/components/RoomSeatModal";
import { useObjects, useSeats } from "pages/admin/application/rooms/hooks";

export default function RoomsView({
  students,
  applications,
  fireReload,
}: {
  students: Student[];
  applications: MinimalApplicationType[];
  fireReload: () => void;
}) {
  const [expandedRoomId, setExpandedRoomId] = useState<number | null>(null);

  const applicationsBySector = _.groupBy(applications, "room.sector");
  const unAppliedStudents = React.useMemo(() => {
    const appliedStudents = _.map(applications, "student.id");
    const targets = _.filter(
      students,
      (student) => !appliedStudents.includes(student.id)
    );

    return _.orderBy(targets, ["grade", "class", "number"]);
  }, [students, applications]);

  return (
    <Sheet>
      <AccordionGroup
        sx={{
          [`& .${accordionClasses.root}`]: {
            bgcolor: "background.level1",
            borderRadius: "md",
            marginTop: "0.5rem",
            transition: "0.2s ease",
            '& button:not([aria-expanded="true"])': {
              transition: "0.2s ease",
              paddingBottom: "0.625rem",
            },
            "& button:hover": {
              background: "transparent",
            },
          },
          [`& .${accordionClasses.root}.${accordionClasses.expanded}`]: {
            bgcolor: "background.level1",
            borderRadius: "md",
            borderBottom: "1px solid",
            borderColor: "background.level2",
          },
          '& [aria-expanded="true"]': {
            boxShadow: (theme) =>
              `inset 0 -1px 0 ${theme.vars.palette.divider}`,
          },
        }}
      >
        {Object.keys(applicationsBySector)
          .sort()
          .map((sector) => {
            const applications = applicationsBySector[sector];
            const roomMap: { [roomId: string]: Room } = {};
            applications.forEach((app) => {
              roomMap[app.room.id] = app.room;
            });
            const applicationsByRoom = _.groupBy(applications, "room.id");

            return (
              <Accordion defaultExpanded>
                <AccordionSummary>
                  {sector} ({applications.length}명)
                </AccordionSummary>
                <AccordionDetails sx={{ pt: 1 }}>
                  <Grid container gap={1}>
                    {Object.values(roomMap)
                      .sort((a, b) => b.number - a.number)
                      .map((room) => {
                        return (
                          <>
                            <Grid>
                              <RoomCard
                                room={room}
                                applications={applicationsByRoom[room.id]}
                                onClick={() => setExpandedRoomId(room.id)}
                              />
                            </Grid>
                            <RoomModal
                              open={room.id === expandedRoomId}
                              handleClose={() => setExpandedRoomId(null)}
                              room={room}
                              applications={applicationsByRoom[room.id]}
                              fireReload={fireReload}
                            />
                          </>
                        );
                      })}
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          })}
        {unAppliedStudents.length > 0 && (
          <Accordion defaultExpanded>
            <AccordionSummary>
              미신청 ({unAppliedStudents.length}명)
            </AccordionSummary>
            <AccordionDetails sx={{ pt: 1 }}>
              <Grid container gap={1}>
                {unAppliedStudents.map((student) => {
                  return (
                    <Grid>
                      <StudentCard student={student} />
                    </Grid>
                  );
                })}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </AccordionGroup>
    </Sheet>
  );
}

function RoomCard({
  room,
  applications,
  onClick,
}: {
  room: Room;
  applications: MinimalApplicationType[];
  onClick: () => void;
}) {
  const checkedCount = _.filter(
    applications,
    (app) => app.present !== null
  ).length;
  const totalCount = applications.length;

  return (
    <Button
      onClick={onClick}
      color={totalCount === checkedCount ? "success" : "warning"}
    >
      {room.name} ({checkedCount}/{totalCount})
    </Button>
    // <Card
    //   sx={{ boxShadow: "none", width: 200 }}
    //   // color={totalCount === checkedCount ? "neutral" : "warning"}
    //   // variant={totalCount === checkedCount ? "outlined" : "soft"}
    //   onClick={onClick}
    // >
    //   <CardContent>
    //     <Typography level="body-sm">
    //       {room.sector} {room.number !== 0 ? `${room.number}호` : ""}
    //     </Typography>
    //     <Typography level="title-md">{room.name}</Typography>

    //     <Chip
    //       size="sm"
    //       variant="solid"
    //       color={totalCount === checkedCount ? "success" : "warning"}
    //       sx={{
    //         position: "absolute",
    //         top: "1rem",
    //         right: "0.875rem",
    //       }}
    //     >
    //       {checkedCount}/{totalCount}
    //     </Chip>
    //   </CardContent>
    // </Card>
  );
}

function RoomModal({
  open,
  handleClose,
  room,
  applications,
  fireReload,
}: {
  open: boolean;
  handleClose: () => void;
  room: Room;
  applications: MinimalApplicationType[];
  fireReload: () => void;
}) {
  const { seats } = useSeats(room.id);
  const { objects } = useObjects(room.id);

  const [now, setNow] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const [isFullChecking, setIsFullChecking] = useState(false);
  const handleFullCheck = async () => {
    setIsFullChecking(true);

    const now = new Date();
    const data: any[] = [];
    applications.forEach((app) => {
      data.push(
        supabase.from("application").update({ present: now }).eq("id", app.id)
      );
    });

    await Promise.allSettled(data);
    fireReload();

    setIsFullChecking(false);
  };

  const [isSeatModalOpen, setIsSeatModalOpen] = useState(false);

  return (
    <FormModal
      title={
        <Stack>
          <Typography level="title-lg" fontWeight="bold">
            {room.name}
          </Typography>
          <Typography level="body-sm" noWrap>
            정원 {room.capacity}명 | 신청 {applications.length}명 | 출석{" "}
            {_.filter(applications, (app) => app.present !== null).length}명
          </Typography>
        </Stack>
      }
      open={open}
      handleClose={handleClose}
      closeButton
      actions={[
        room.seat_selection ? (
          <Button
            color="neutral"
            onClick={() => setIsSeatModalOpen(true)}
            loading={!(seats && objects)}
          >
            좌석 배치도 보기
          </Button>
        ) : undefined,
        <Button
          color="success"
          onClick={handleFullCheck}
          loading={isFullChecking}
        >
          전원 출석확인
        </Button>,
      ]}
    >
      <Sheet sx={{ overflow: "auto", width: "80vw" }}>
        <Grid container gap={1}>
          {room.seat_selection
            ? _.orderBy(applications, ["seat.name"]).map((app) => (
                <ApplicationCard app={app} now={now} fireReload={fireReload} />
              ))
            : _.orderBy(applications, [
                "student.grade",
                "student.class",
                "student.number",
              ]).map((app) => (
                <ApplicationCard app={app} now={now} fireReload={fireReload} />
              ))}
        </Grid>
        {seats && objects && (
          <RoomSeatModal
            open={isSeatModalOpen}
            onClose={() => setIsSeatModalOpen(false)}
            room={room}
            seats={seats}
            objects={objects}
          />
        )}
      </Sheet>
    </FormModal>
  );
}

function ApplicationCard({
  app,
  now,
  fireReload,
}: {
  app: MinimalApplicationType;
  now: Date;
  fireReload: () => void;
}) {
  const avatarUrl = app.student.avatar
    ? supabase.storage.from("avatar").getPublicUrl(app.student.avatar, {
        transform: {
          width: 100,
          height: 100,
          resize: "cover",
        },
      }).data.publicUrl
    : undefined;

  const description = `${app.student.grade}${
    app.student.class
  }${`${app.student.number}`.padStart(2, "0")}`;
  const seatInfo = app.seat ? `좌석: ${app.seat.name}` : null;

  const handleAttendance = async (id: number, attendance: boolean) => {
    toast.loading("출석 처리중...", { id: "loading" });

    if (attendance) {
      await supabase
        .from("application")
        .update({ present: new Date() })
        .eq("id", id);
    } else {
      await supabase.from("application").update({ present: null }).eq("id", id);
    }

    fireReload();
  };

  return (
    <Grid>
      <Card
        // variant="soft"
        sx={{
          boxShadow: "none",
          borderWidth: "3px",
          borderColor: app.present === null ? undefined : "green",
        }}
        key={app.id}
        onClick={() => {
          if (app.present === null) {
            handleAttendance(app.id, true);
          } else {
            handleAttendance(app.id, false);
          }
        }}
      >
        <CardContent sx={{ mb: 0 }}>
          <Stack direction="column" gap={1}>
            {app.status !== "approved" && (
              <Typography level="body-xs" color="danger" variant="soft">
                승인되지 않음 ({approvalMap[app.status].kor})
              </Typography>
            )}
            <Stack direction="row" gap={1} alignItems="center">
              <Avatar
                variant="soft"
                color="neutral"
                src={avatarUrl}
                size="lg"
              />
              <Stack gap={0}>
                <Typography level="title-lg">{app.student.name}</Typography>
                <Typography level="body-xs">{description}</Typography>
              </Stack>
            </Stack>
            {seatInfo && (
              <Typography level="body-xs" color="warning" variant="soft">
                {seatInfo}
              </Typography>
            )}
            {/* {app.present === null ? (
              <Button
                variant="solid"
                color="primary"
                // onClick={() => handleAttendance(app.id, true)}
              >
                출석 확인
              </Button>
            ) : (
              <Button
                variant="soft"
                color="success"
                // onClick={() => handleAttendance(app.id, false)}
              >
                <Typography level="title-sm">
                  출석 확인됨
                  <br />
                  <Typography level="body-xs">{timeDeltaString} 전</Typography>
                </Typography>
              </Button>
            )} */}
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}

function StudentCard({ student }: { student: Student }) {
  const avatarUrl = student.avatar
    ? supabase.storage.from("avatar").getPublicUrl(student.avatar, {
        transform: {
          width: 100,
          height: 100,
          resize: "cover",
        },
      }).data.publicUrl
    : undefined;
  const description = `${student.grade}${
    student.class
  }${`${student.number}`.padStart(2, "0")}`;
  return (
    <Grid>
      <Card variant="outlined" sx={{ boxShadow: "none" }} key={student.id}>
        <CardContent sx={{ mb: 0 }}>
          <Stack direction="row" gap={1} alignItems="center">
            <Avatar variant="soft" color="neutral" src={avatarUrl} size="lg" />
            <Stack gap={0}>
              <Typography level="title-lg">{student.name}</Typography>
              <Typography level="body-xs">{description}</Typography>
            </Stack>
          </Stack>
        </CardContent>
      </Card>
    </Grid>
  );
}
