import { createClient } from "@supabase/supabase-js";

const supabase_url = process.env.REACT_APP_SUPABASE_URL;
const supabase_anon = process.env.REACT_APP_SUPABASE_ANON;

if (!supabase_url || !supabase_anon) {
  throw new Error(
    "Cannot connect to Supabase. Contact the administrator. 서버와 연결하지 못했습니다. 관리자에게 문의하세요."
  );
}

export const supabase = createClient(supabase_url, supabase_anon);
