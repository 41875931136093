import {
  Box,
  DialogTitle,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemContent,
  ListItemDecorator,
  Modal,
  ModalClose,
  ModalDialog,
  Sheet,
  Stack,
  Typography,
} from "@mui/joy";
import { Student } from "features/auth";
import { AccountSettings } from "./AccountSettings";
import NotificationSettings from "./NotificationSettings";

export default function StudentSettingsModal({
  student,
  open,
  handleClose,
}: {
  student: Student;
  open: boolean;
  handleClose: () => void;
}) {
  function SettingsNavigator() {
    return (
      <Sheet
        sx={{
          width: "150px",
          overFlow: "auto",
          borderRadius: 5,
          p: 1,
        }}
      >
        <List
          size="md"
          sx={{
            p: 0,
            "--ListItemDecorator-size": "20px",
            "--ListItem-radius": "8px",
            "& .JoyListItemButton-root": { p: "8px" },
          }}
        >
          <ListItem>
            <ListItemButton>
              <ListItemDecorator>
                <Box
                  sx={{
                    width: "10px",
                    height: "10px",
                    borderRadius: "99px",
                    bgcolor: "primary.300",
                  }}
                />
              </ListItemDecorator>
              <ListItemContent>
                <Typography fontWeight="bold">계정</Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        </List>
      </Sheet>
    );
  }

  function Settings() {
    return (
      <Sheet
        sx={{
          overflow: "auto",
          p: 2,
          pt: 1.8,
          borderRadius: 5,
          minWidth: "300px",
        }}
      >
        <Stack direction="column" gap={2} divider={<Divider />}>
          <AccountSettings student={student} />
          {/* <NotificationSettings /> */}
        </Stack>
      </Sheet>
    );
  }

  return (
    <Modal open={open} onClose={handleClose}>
      {/* <Modal open={true} onClose={handleClose}> */}
      <ModalDialog sx={{ p: 0 }}>
        <Sheet sx={{ borderRadius: 5 }}>
          <DialogTitle sx={{ p: 2 }}>설정</DialogTitle>
          <ModalClose />
          <Divider />
          <Stack direction="row">
            <Box sx={{ "@media (max-width: 600px)": { display: "none" } }}>
              <SettingsNavigator />
            </Box>
            <Divider orientation="vertical" />
            <Settings />
          </Stack>
          <Divider />
        </Sheet>
      </ModalDialog>
    </Modal>
  );
}
