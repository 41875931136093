import { router } from "Router";
import { supabase } from "lib/supabase";
import toast from "react-hot-toast";

export const signOut = async (
  option: { showToast?: boolean; landToRoot?: boolean } = {
    showToast: true,
    landToRoot: true,
  }
) => {
  const result = await supabase.auth.signOut();
  if (option.showToast) {
    if (result.error) {
      toast.error("로그아웃 도중 오류가 발생했습니다");
    } else {
      toast.success("로그아웃되었습니다");
    }
  }

  if (option.landToRoot) router.navigate("/");
  return result;
};
