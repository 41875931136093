import { Box, ColorPaletteProp, Sheet, Stack, Typography } from "@mui/joy";
import { seatConfig } from "features/application/data";
import { Point } from "features/application/types";

export function SeatContainer({
  x,
  y,
  children,
  guide,
}: Point & { children?: React.ReactNode; guide?: boolean }) {
  const { size, gap } = seatConfig;
  const width = x * (size + gap);
  const height = y * (size + gap);

  const guideStyle = {
    background: `linear-gradient(0deg, rgba(0,0,0,0.1) 1px, transparent 1px), 
          linear-gradient(90deg, rgba(0,0,0,0.1) 1px, transparent 1px),
          #fff`,
    backgroundSize: `${size + gap}px ${size + gap}px`,
  };

  return (
    <Sheet
      sx={{
        border: "1px solid",
        width: `${width}px`,
        height: `${height}px`,
        position: "relative",
        ...(guide && guideStyle),
      }}
    >
      {children}
    </Sheet>
  );
}

export function Seat({
  x,
  y,
  label,
  onClick,
  color = "neutral",
  variant = "solid",
}: Point & {
  label?: string;
  onClick?: () => void;
  color?: ColorPaletteProp;
  variant?: "solid" | "outlined";
}) {
  const { size, gap } = seatConfig;
  const left = size * (x - 1) + gap * (x - 0.5);
  const top = size * (y - 1) + gap * (y - 0.5);

  return (
    <Sheet
      sx={{
        // border: "1px solid blue",
        borderRadius: "4px",
        width: `${size}px`,
        height: `${size}px`,
        position: "absolute",
        left: `${left}px`,
        top: `${top}px`,
      }}
      invertedColors
      {...{ color, variant, onClick }}
    >
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        <Typography textAlign="center" fontSize={14}>
          {label}
        </Typography>
      </Stack>
    </Sheet>
  );
}

export function Object({
  x,
  y,
  w,
  h,
  label,
}: Point & { w: number; h: number; label?: string }) {
  const { size, gap } = seatConfig;

  const left = size * (x - 1) + gap * (x - 0.5);
  const top = size * (y - 1) + gap * (y - 0.5);
  const width = w * size + (w - 1) * gap;
  const height = h * size + (h - 1) * gap;

  return (
    <Box
      sx={{
        width: `${width}px`,
        height: `${height}px`,
        position: "absolute",
        left: `${left}px`,
        top: `${top}px`,

        background: label
          ? "#fdf0e1"
          : `repeating-linear-gradient(
        45deg,
        #fdf0e1,
        #fdf0e1 5px,
        #F8CB9A 5px,
        #F8CB9A 10px
      )`,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
        sx={{ height: "100%" }}
      >
        {label && label?.length > 0 && (
          <Typography color="warning" fontSize={14}>
            {label}
          </Typography>
        )}
      </Stack>
    </Box>
  );
}
