import { adminAuthClient, adminClient } from "lib/supabaseAdmin";
import {
  RegisteringStudentDataType,
  RegisteringTeacherDataType,
} from "../types/users";

export const createStudent = async (target: RegisteringStudentDataType) => {
  const { data: userCreateData, error: userCreateError } =
    await adminAuthClient.createUser({
      email: target.email,
      password: target.password,
      email_confirm: true,
    });
  if (userCreateError) throw userCreateError;

  const {
    user: { id },
  } = userCreateData;

  const studentData = {
    id,
    name: target.name,
    grade: target.grade,
    class: target.class,
    number: target.number,
  };

  const { error: dataUploadError } = await adminClient
    .from("student")
    .insert([studentData]);

  if (dataUploadError) throw dataUploadError;
};

export const createTeacher = async (target: RegisteringTeacherDataType) => {
  const { data: userCreateData, error: userCreateError } =
    await adminAuthClient.createUser({
      email: target.email,
      password: target.password,
      email_confirm: true,
    });
  if (userCreateError) throw userCreateError;

  const {
    user: { id },
  } = userCreateData;

  const teacherData = {
    id,
    name: target.name,
    homeroom: target.homeroom,
  };

  const { error: dataUploadError } = await adminClient
    .from("teacher")
    .insert([teacherData]);

  if (dataUploadError) throw dataUploadError;
};
