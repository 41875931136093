import {
  Chip,
  List,
  ListItem,
  ListItemButton,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/joy";
import { Room } from "features/application/types";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { useRoomApplications } from "./useRoomApplications";
import { useLockedSeats } from "features/application/hooks/useLockedSeats";
import { useAuth } from "features/auth";
import { useRoomSeatComponents } from "./useRoomSeatComponents";
import { Seat, SeatSelectModal } from "../seat";
import _ from "lodash";

export default function SessionSelect(props: SessionSelectProps) {
  const { selection, lockedSelection, setSelection, room, dateStr } = props;
  const { seats, objects } = useRoomSeatComponents(room.id);
  const { applications } = useRoomApplications(room.id, dateStr);
  const { lockedSeats } = useLockedSeats(room.id, dateStr);
  const { user } = useAuth();

  const [seatSelectSession, setSeatSelectSession] = useState<number | null>(
    null
  );

  useEffect(() => {
    if (room.seat_selection) {
      const newSelection: (boolean | number)[] = Array.from(
        { length: selection.length },
        () => false
      );
      lockedSeats
        .filter((item) => item.locked_by === user?.id)
        .forEach((item) => {
          newSelection[item.session - 1] = item.seat.id;
        });

      setSelection(newSelection);
    }
  }, [lockedSeats]);

  const handleClick = (session: number) => {
    if (room.seat_selection) {
      setSeatSelectSession(session);
    } else {
      setSelection((prev) => {
        const newSelection = [...prev];
        newSelection[session - 1] = !newSelection[session - 1];
        return newSelection;
      });
    }
  };

  return (
    <>
      <RadioGroup sx={{ b: 0 }}>
        <List
          sx={{
            "--ListItem-radius": "8px",
            "--List-gap": "4px",
            p: 0,
          }}
        >
          {selection.map((value, i) => {
            const index = i + 1;
            const seatsCntStr = applications
              ? room.capacity -
                applications.filter((i) => i.session === index).length -
                lockedSeats.filter((i) => i.session === index).length
              : "?";
            const locked = lockedSelection.includes(index);
            return (
              <ListItem>
                <ListItemButton
                  variant={value ? "solid" : "outlined"}
                  onClick={() => handleClick(index)}
                  disabled={locked}
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    sx={{ width: "100%" }}
                  >
                    <Typography fontWeight="bold" textColor="inherit">
                      {index}교시
                    </Typography>

                    {typeof value === "number" ? (
                      <Chip color="primary">
                        좌석: {_.find(seats, { id: value })?.name}
                      </Chip>
                    ) : (
                      <Chip>잔여: {seatsCntStr}</Chip>
                    )}
                  </Stack>
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </RadioGroup>
      {room.seat_selection && seatSelectSession && (
        <SeatSelectModal
          roomId={room.id}
          roomSize={room.room_size}
          dateStr={dateStr}
          session={seatSelectSession}
          lockedSeats={lockedSeats.filter(
            (i) => i.session === seatSelectSession
          )}
          applications={(applications ?? []).filter(
            (i) => i.session === seatSelectSession
          )}
          {...{ seats, objects }}
          handleClose={() => setSeatSelectSession(null)}
        />
      )}
    </>
  );
}

type SessionSelectProps = {
  selection: (number | boolean)[];
  lockedSelection: number[];
  setSelection: Dispatch<SetStateAction<(number | boolean)[]>>;
  room: Room;
  dateStr: string;
};
