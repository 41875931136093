import { supabase } from "lib/supabase";
import { dateStr2pgDate } from "utils/manageTime";
import type { Application, Room } from "..";
import type { Student } from "features/auth";

type ApplicationForTeacher = Application & {
  student: Student;
  room: Room;
};

export async function getApplications(dateStr: string, student: string) {
  const { data, error } = await supabase
    .from("application")
    .select(`*`)
    .eq("date", dateStr)
    .eq("student", student);

  if (error) throw error;

  return data as Application[];
}

export async function getValidRoomApplications(dateStr: string, room: number) {
  const { data, error } = await supabase
    .from("application")
    .select(`*`)
    .in("status", ["approved", "pending"])
    .eq("date", dateStr)
    .eq("room", room);

  if (error) throw error;

  return data as Application[];
}

export async function newApplication(d: {
  date: string;
  session: number;
  student: string;
  teacher: string;
  room: number;
  reason: string;
}) {
  const { data, error } = await supabase.rpc("create_application", {
    p_date: dateStr2pgDate(d.date),
    p_reason: d.reason,
    p_room: d.room,
    p_session: d.session,
    p_student: d.student,
    p_teacher: d.teacher,
  });
  return { data, error };
}

export async function deleteApplication(id: number) {
  const { error } = await supabase.from("application").delete().eq("id", id);
  if (error) throw error;
}

export async function getApplicationsForTeacher(
  dateStr: string,
  teacher: string
) {
  const { data, error } = await supabase
    .from("application")
    .select(
      `*,
      student (*),
      room (*)
    `
    )
    .eq("date", dateStr)
    .eq("teacher", teacher);

  if (error) throw error;

  return data as ApplicationForTeacher[];
}

export async function proxyApplication(
  data: {
    dateStr: string;
    session: number;
    student: string;
    teacher: string;
    room: number;
    reason: string;
  },
  config: { approve: boolean; overwrite: boolean; force: boolean }
) {
  return supabase.rpc("create_proxy_application", {
    p_date: data.dateStr,
    p_session: data.session,
    p_student: data.student,
    p_teacher: data.teacher,
    p_room: data.room,
    p_reason: data.reason,
    p_approve: config.approve,
    p_overwrite: config.overwrite,
    p_force: config.force,
  });
}
