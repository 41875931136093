import {
  Alert,
  Box,
  Button,
  CssBaseline,
  FormControl,
  FormLabel,
  GlobalStyles,
  Input,
  Link,
  Sheet,
  Typography,
} from "@mui/joy";
import { formLabelClasses } from "@mui/joy/FormLabel";
import { Link as RouteLink } from "react-router-dom";
import React, { useState } from "react";
import { copyright, organization } from "data/constants";
import { supabase } from "lib/supabase";
import toast from "react-hot-toast";
import { router } from "Router";
import ThemeToggle from "components/ui/ThemeToggle";

const defaultEmailEnd = "@cbs-h.cbe.go.kr";

function SignIn() {
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleSignIn = async (email: string, password: string) => {
    setIsError(false);
    setIsLoading(true);
    try {
      const {
        data: { session },
      } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (!session) {
        setIsError(true);
        return;
      }

      router.navigate("/");
      toast.success("로그인했습니다.", { id: "signin" });
    } catch (error) {
      console.error(error);
      setIsError(true);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Sheet>
      <CssBaseline />
      <GlobalStyles
        styles={{
          ":root": {
            "--Collapsed-breakpoint": "769px", // form will stretch when viewport is below `769px`
            "--Cover-width": "40vw", // must be `vw` only
            "--Form-maxWidth": "700px",
            "--Transition-duration": "0.3s", // set to `none` to disable transition
          },
        }}
      />
      <Box
        sx={(theme) => ({
          width:
            "clamp(100vw - var(--Cover-width), (var(--Collapsed-breakpoint) - 100vw) * 999, 100vw)",
          transition: "width var(--Transition-duration)",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          position: "relative",
          zIndex: 1,
          display: "flex",
          justifyContent: "flex-end",
          backdropFilter: "blur(4px)",
          backgroundColor: "rgba(255 255 255 / 0.6)",
          [theme.getColorSchemeSelector("dark")]: {
            backgroundColor: "rgba(19 19 24 / 0.4)",
          },
        })}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100dvh",
            width:
              "clamp(var(--Form-maxWidth), (var(--Collapsed-breakpoint) - 100vw) * 999, 100%)",
            maxWidth: "100%",
            px: 2,
          }}
        >
          <Box
            component="header"
            sx={{
              py: 3,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              fontWeight="lg"
              startDecorator={
                <img src="/icons/favicon-196x196.png" alt="logo" width="20px" />
              }
            >
              {organization.full}
            </Typography>
            <ThemeToggle />
          </Box>
          <Box
            component="main"
            sx={{
              my: "auto",
              py: 2,
              pb: 5,
              display: "flex",
              flexDirection: "column",
              gap: 2,
              width: 400,
              maxWidth: "100%",
              mx: "auto",
              borderRadius: "sm",
              "& form": {
                display: "flex",
                flexDirection: "column",
                gap: 2,
              },
              [`& .${formLabelClasses.asterisk}`]: {
                visibility: "hidden",
              },
            }}
          >
            <div>
              <Typography component="h1" fontSize="xl2" fontWeight="lg">
                환영합니다!
              </Typography>
              <Typography level="body-md" sx={{ my: 1, mb: 3 }}>
                {organization.full} 학생관리시스템입니다.
              </Typography>
            </div>
            {isError ? (
              <Alert color="danger" variant="solid">
                로그인 실패. 정보를 확인하십시오.
              </Alert>
            ) : null}
            <form
              onSubmit={async (event: React.FormEvent) => {
                event.preventDefault();
                handleSignIn(
                  email.includes("@") ? email : `${email}${defaultEmailEnd}`,
                  password
                );
              }}
            >
              <FormControl>
                <FormLabel>이메일</FormLabel>
                <Input
                  name="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  autoFocus
                  required
                  endDecorator={
                    email.includes("@") ? undefined : (
                      <Typography level="body-sm">{defaultEmailEnd}</Typography>
                    )
                  }
                />
              </FormControl>
              <FormControl>
                <FormLabel>패스워드</FormLabel>
                <Input
                  type="password"
                  name="password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </FormControl>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography />
                <Link
                  fontSize="sm"
                  component={RouteLink}
                  to="/forgot-password"
                  fontWeight="lg"
                >
                  패스워드를 잊으셨습니까?
                </Link>
              </Box>
              <Button type="submit" fullWidth loading={isLoading}>
                로그인
              </Button>
            </form>
          </Box>
          <Box component="footer" sx={{ py: 3 }}>
            <Typography level="body-sm" textAlign="center">
              © {copyright.name} {copyright.year}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box
        sx={(theme) => ({
          height: "100%",
          position: "fixed",
          right: 0,
          top: 0,
          bottom: 0,
          left: "clamp(0px, (100vw - var(--Collapsed-breakpoint)) * 999, 100vw - var(--Cover-width))",
          transition:
            "background-image var(--Transition-duration), left var(--Transition-duration) !important",
          transitionDelay: "calc(var(--Transition-duration) + 0.1s)",
          backgroundColor: "background.level1",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(/images/landing.png)`,
        })}
      />
    </Sheet>
  );
}

export default SignIn;
