import { ApplicationStatus } from "../types/application";
import { ColorPaletteProp } from "@mui/joy";
import { FiCheck, FiMinus, FiX } from "react-icons/fi";

export const approvalMap: ApprovalMapType = {
  approved: { kor: "승인", icon: <FiCheck />, color: "success" },
  pending: { kor: "대기", icon: <FiMinus />, color: "primary" },
  rejected: { kor: "거절", icon: <FiX />, color: "danger" },
};

type ApprovalMapType = {
  [key in ApplicationStatus]: {
    kor: string;
    icon: JSX.Element;
    color: ColorPaletteProp;
  };
};

export const seatConfig = {
  size: 20,
  gap: 4,
};

export const exampleApplicationReasons = [
  "시험공부",
  "탐구활동",
  "대회준비",
  "동아리활동",
  "개인자습",
];
