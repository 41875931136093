import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  LinearProgress,
  Sheet,
  Stack,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/joy";
import { FormModal } from "components/form/formModals";
import {
  ObjectForSeat,
  Point,
  Room,
  SeatContainer,
  Seat,
  SeatType,
  Object,
  isInArea,
  mergeAreas,
  seatConfig,
} from "features/application";
import _, { set } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import { useObjects, useSeats } from "./hooks";
import { supabase } from "lib/supabase";
import { FiAlertTriangle } from "react-icons/fi";
import toast from "react-hot-toast";

type Mode = "normal" | "seat" | "object" | "remove";
type NewSeat = Partial<SeatType> & Pick<SeatType, "name" | "position">;
type NewObject = Partial<ObjectForSeat> &
  Pick<ObjectForSeat, "name" | "position" | "size">;
type SubmitProgress = { progress: number; label: string; error?: boolean };
export default function SeatManageModal({
  room,
  handleClose,
  fireReload,
}: {
  room: Pick<Room, "id" | "name" | "room_size">;
  handleClose: () => void;
  fireReload: () => void;
}) {
  const [roomSize, setRoomSize] = useState(room.room_size);
  const [mode, setMode] = useState<Mode>("normal");
  useEffect(() => {
    if (["normal", "remove"].includes(mode)) setShadowPos(undefined);
  }, [mode]);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!!nameChangeTarget) return;
      if (["q", "Q", "ㅂ"].includes(event.key)) setMode("normal");
      if (["w", "W", "ㅈ"].includes(event.key)) setMode("seat");
      if (["e", "E", "ㄷ"].includes(event.key)) setMode("object");
      if (["r", "R", "ㄱ"].includes(event.key)) setMode("remove");
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, []);

  const { seats: prevSeats } = useSeats(room.id);
  const [newSeats, setNewSeats] = useState<NewSeat[]>([]);
  useEffect(() => {
    if (prevSeats && newSeats.length === 0) {
      setNewSeats(prevSeats as NewSeat[]);
    }
  }, [prevSeats]);

  const { objects: prevObjects } = useObjects(room.id);
  const [newObjects, setNewObjects] = useState<NewObject[]>([]);
  useEffect(() => {
    if (prevObjects && newObjects.length === 0) {
      setNewObjects(prevObjects as NewObject[]);
    }
  }, [prevObjects]);

  const minRoomSize = useMemo(() => {
    const minSize = { x: 1, y: 1 };
    newSeats.map((seat) => {
      const { x, y } = seat.position;
      if (x > minSize.x) minSize.x = x;
      if (y > minSize.y) minSize.y = y;
    });
    newObjects.map((obj) => {
      const { x, y } = obj.position;
      const { w, h } = obj.size;
      if (x + w - 1 > minSize.x) minSize.x = x + w - 1;
      if (y + h - 1 > minSize.y) minSize.y = y + h - 1;
    });
    return minSize;
  }, [newSeats, newObjects]);

  const [isDeleteAllModalOpen, setIsDeleteAllModalOpen] = useState(false);
  const deleteAll = () => {
    setNewSeats([]);
    setNewObjects([]);
  };

  const [shadowPos, setShadowPos] = useState<
    (Point & { w?: number; h?: number }) | undefined
  >(undefined);
  const [mouseDownPoint, setMouseDownPoint] = useState<Point | null>(null);

  const [nameChangeTarget, setNameChangeTarget] = useState<Point | null>(null);
  const handleNameChange = (newName: string, pos: Point) => {
    setNewObjects(
      newObjects.map((obj) => {
        if (_.isEqual(obj.position, pos)) return { ...obj, name: newName };
        else return obj;
      })
    );
    setNewSeats(
      newSeats.map((seat) => {
        if (_.isEqual(seat.position, pos)) return { ...seat, name: newName };
        else return seat;
      })
    );
  };

  const [submitProgress, setSubmitProgress] =
    useState<SubmitProgress>(defaultProgress);
  const handleSubmit = async () => {
    if (minRoomSize.x > roomSize.x || minRoomSize.y > roomSize.y) {
      toast.error(
        "교실 경계 외부에 배치된 좌석/구조물이 있습니다. 교실 크기를 늘려주세요!"
      );
      return;
    }

    setSubmitProgress({ progress: 0, label: "준비하는 중..." });
    const seatsDelta: {
      create: NewSeat[];
      update: NewSeat[];
      delete: number[];
    } = {
      create: [],
      update: [],
      delete: [],
    };
    newSeats.map((seat) => {
      if (seat.id) seatsDelta.update.push(seat);
      else seatsDelta.create.push({ ...seat, room: room.id });
    });
    seatsDelta.delete =
      prevSeats
        ?.filter((seat) => !newSeats.some((s) => s.id === seat.id))
        .map((s) => s.id) ?? [];

    const objectsDelta: {
      create: NewObject[];
      update: NewObject[];
      delete: number[];
    } = {
      create: [],
      update: [],
      delete: [],
    };
    newObjects.map((obj) => {
      if (obj.id) objectsDelta.update.push(obj);
      else objectsDelta.create.push({ ...obj, room: room.id });
    });
    objectsDelta.delete =
      prevObjects
        ?.filter((obj) => !newObjects.some((o) => o.id === obj.id))
        .map((o) => o.id) ?? [];

    setSubmitProgress({ progress: 10, label: "교실 정보 설정하는 중..." });
    await supabase
      .from("room")
      .update({ room_size: roomSize, capacity: newSeats.length })
      .eq("id", room.id);

    setSubmitProgress({ progress: 30, label: "좌석 업데이트 중..." });
    await supabase.from("room_seat").upsert(seatsDelta.update).select();
    setSubmitProgress({ progress: 40, label: "좌석 업데이트 중..." });
    await supabase.from("room_seat").delete().in("id", seatsDelta.delete);
    setSubmitProgress({ progress: 50, label: "좌석 업데이트 중..." });
    await supabase.from("room_seat").insert(seatsDelta.create).select();

    setSubmitProgress({ progress: 70, label: "구조물 업데이트 중..." });
    await supabase.from("room_object").upsert(objectsDelta.update).select();
    setSubmitProgress({ progress: 80, label: "구조물 업데이트 중..." });
    await supabase.from("room_object").delete().in("id", objectsDelta.delete);
    setSubmitProgress({ progress: 90, label: "구조물 업데이트 중..." });
    await supabase.from("room_object").insert(objectsDelta.create).select();
    setSubmitProgress(defaultProgress);
    toast.success("저장되었습니다!");
    fireReload();
    handleClose();
  };

  const rectCustomPropsGen = (pos: Point) => {
    switch (mode) {
      case "normal":
        return {
          onMouseEnter: () =>
            setShadowPos(() => {
              for (const obj of _.reverse(_.clone(newObjects))) {
                const objArea = { ...obj.position, ...obj.size };
                if (isInArea(pos, objArea)) return objArea;
              }
              for (const seat of newSeats) {
                if (_.isEqual(seat.position, pos)) return pos;
              }
              return undefined;
            }),
          onClick: () => {
            if (shadowPos) {
              const { x, y } = shadowPos;
              setNameChangeTarget({ x, y });
              setShadowPos(undefined);
            }
          },
        };
      case "seat":
        return {
          onMouseEnter: () =>
            setShadowPos(() => {
              if (
                newSeats.some((seat) => _.isEqual(seat.position, pos)) ||
                newObjects.some((obj) =>
                  isInArea(pos, { ...obj.position, ...obj.size })
                )
              ) {
                setShadowPos(undefined);
                return undefined;
              }
              return pos;
            }),

          onClick: () => {
            setNewSeats((prev) => {
              if (prev === undefined) return prev;
              if (prev.some((seat) => _.isEqual(seat.position, pos)))
                return prev;
              if (
                newObjects.some((obj) =>
                  isInArea(pos, { ...obj.position, ...obj.size })
                )
              ) {
                toast.error("구조물과 좌석을 겹칠 수 없습니다!");
                return prev;
              }
              const newSeat = {
                name: getNewSeatName([...prev]),
                position: pos,
              };
              return [...prev, newSeat];
            });
          },
        };
      case "object":
        return {
          onMouseEnter: () =>
            setShadowPos(() => {
              if (mouseDownPoint) {
                return mergeAreas(mouseDownPoint, pos);
              } else return pos;
            }),
          onMouseUp: () => {
            setMouseDownPoint(null);
            setNewObjects((prev) => {
              const { x, y, w, h } = shadowPos!;
              if (
                newSeats.some((seat) => isInArea(seat.position, { x, y, w, h }))
              ) {
                toast.error("구조물과 좌석을 겹칠 수 없습니다!");
                return prev;
              }
              return [
                ...prev,
                {
                  name: "",
                  position: { x, y },
                  size: { w: w ?? 1, h: h ?? 1 },
                },
              ];
            });
            setShadowPos(undefined);
          },
        };
      case "remove":
        return {
          onClick: () => {
            const { x, y } = shadowPos ?? { x: -1, y: -1 };
            setNewSeats((prev) => {
              return prev.filter((seat) => !_.isEqual(seat.position, { x, y }));
            });
            setNewObjects((prev) => {
              return _.filter(
                prev,
                (obj) => !_.isEqual(obj.position, { x, y })
              );
            });
            setShadowPos(undefined);
          },
          onMouseEnter: () =>
            setShadowPos(() => {
              for (const obj of _.reverse(_.clone(newObjects))) {
                const objArea = { ...obj.position, ...obj.size };
                if (isInArea(pos, objArea)) return objArea;
              }
              for (const seat of newSeats) {
                if (_.isEqual(seat.position, pos)) return pos;
              }
              return undefined;
            }),
        };
    }
  };

  return (
    <FormModal
      title={`${room.name} 좌석 배치 수정`}
      actions={[
        <Button key="close" color="neutral" onClick={handleClose}>
          닫기
        </Button>,
        <Button
          key="reset"
          color="danger"
          disabled={!(prevSeats && prevObjects)}
          onClick={() => {
            setNewSeats(prevSeats!);
            setNewObjects(prevObjects!);
          }}
        >
          원래대로
        </Button>,
        <Button key="save" color="success" onClick={handleSubmit}>
          저장
        </Button>,
      ]}
    >
      <Sheet variant="soft" sx={{ p: 2 }}>
        <Stack direction="row" gap={1} sx={{ width: "100%", mb: 1 }}>
          <FormControl>
            <FormLabel>교실 가로</FormLabel>
            <Input
              sx={{ width: "100px" }}
              type="number"
              endDecorator="칸"
              value={roomSize.x}
              color={minRoomSize.x > roomSize.x ? "danger" : undefined}
              onChange={(e) => {
                setRoomSize({ ...roomSize, x: +e.target.value });
              }}
            />
          </FormControl>
          <FormControl>
            <FormLabel>교실 세로</FormLabel>
            <Input
              sx={{ width: "100px" }}
              type="number"
              endDecorator="칸"
              value={roomSize.y}
              color={minRoomSize.y > roomSize.y ? "danger" : undefined}
              onChange={(e) => {
                setRoomSize({ ...roomSize, y: +e.target.value });
              }}
            />
          </FormControl>
        </Stack>
        <FormControl>
          <FormLabel>마우스 모드</FormLabel>
          <Sheet
            variant="outlined"
            sx={{ borderRadius: "md", display: "flex", gap: 2, p: 0.5 }}
          >
            <ToggleButtonGroup
              variant="plain"
              spacing={0.5}
              value={mode}
              onChange={(_, newValue) => {
                if (newValue) setMode(newValue);
              }}
            >
              <Button value="normal" color="primary">
                수정(Q)
              </Button>
              <Button value="seat" color="neutral">
                좌석 추가(W)
              </Button>
              <Button value="object" color="warning">
                구조물 추가(E)
              </Button>

              <Button
                value="remove"
                color="danger"
                onClick={() => {
                  if (mode === "remove") setIsDeleteAllModalOpen(true);
                }}
              >
                삭제(R)
              </Button>
            </ToggleButtonGroup>
          </Sheet>
        </FormControl>
        <Box sx={{ height: "10px" }} />
        <Sheet variant="soft" sx={{ overflow: "auto", maxHeight: "50vh" }}>
          {!(prevSeats && prevObjects) ? (
            <Alert color="primary">불러오는 중...</Alert>
          ) : (
            <>
              <SeatContainer {...roomSize} guide>
                {newSeats.map((seat) => (
                  <Seat
                    key={JSON.stringify(seat.position)}
                    {...seat.position}
                    label={seat.name}
                  />
                ))}
                {newObjects.map((obj) => (
                  <Object
                    key={JSON.stringify(obj.position)}
                    label={obj.name}
                    {...obj.position}
                    {...obj.size}
                  />
                ))}

                {shadowPos && (
                  <Shadow {...shadowPos} color={shadowColor[mode]} />
                )}
                {Array.from({ length: roomSize.x }, (_, i) => i + 1).map(
                  (x) => {
                    return Array.from(
                      { length: roomSize.y },
                      (_, i) => i + 1
                    ).map((y) => {
                      const pos = { x, y };
                      return (
                        <HiddenRect
                          key={`${x}-${y}`}
                          {...pos}
                          mouseDownPoint={setMouseDownPoint}
                          customPropsGen={rectCustomPropsGen}
                        />
                      );
                    });
                  }
                )}
              </SeatContainer>
              {!!nameChangeTarget && (
                <NameChangeModal
                  targetPos={nameChangeTarget}
                  handleClose={() => setNameChangeTarget(null)}
                  handleNameChange={handleNameChange}
                />
              )}
              <SubmitProgressModal
                progress={submitProgress}
                setProgress={setSubmitProgress}
              />
              <DeleteAllModal
                open={isDeleteAllModalOpen}
                handleClose={() => setIsDeleteAllModalOpen(false)}
                handleDeleteAll={deleteAll}
              />
            </>
          )}
        </Sheet>
      </Sheet>
    </FormModal>
  );
}

function NameChangeModal({
  targetPos,
  handleClose,
  handleNameChange,
}: {
  targetPos: Point;
  handleClose: () => void;
  handleNameChange: (newName: string, pos: Point) => void;
}) {
  const [name, setName] = useState("");
  const handleChange = () => {
    handleNameChange(name, targetPos);
    handleClose();
  };
  return (
    <FormModal
      title="좌석/구조물 이름 변경"
      open
      handleClose={handleClose}
      actions={[
        <Button key="submit" onClick={handleChange}>
          설정
        </Button>,
      ]}
    >
      <Sheet sx={{ m: 1 }}>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleChange();
          }}
        >
          <FormControl>
            <FormLabel>새로운 이름</FormLabel>
            <Input
              autoFocus
              placeholder="A1, 중앙 책상"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
          </FormControl>
        </form>
      </Sheet>
    </FormModal>
  );
}

function SubmitProgressModal({
  progress,
  setProgress,
}: {
  progress: SubmitProgress;
  setProgress: React.Dispatch<React.SetStateAction<SubmitProgress>>;
}) {
  const { progress: percentage, label } = progress;
  const error = !!progress?.error;
  return (
    <FormModal
      open={percentage >= 0}
      closeButton={error}
      handleClose={error ? () => setProgress(defaultProgress) : undefined}
    >
      <Stack>
        <Stack
          direction="row"
          alignItems="flex-end"
          justifyContent="space-between"
        >
          <Typography fontWeight="bold">
            {error ? "오류 발생" : label}
          </Typography>
          <Typography level="body-xs">{percentage}%</Typography>
        </Stack>
        <LinearProgress
          determinate
          value={percentage}
          color={error ? "danger" : undefined}
        />
        <div style={{ height: "10px" }} />
        {!error && (
          <Typography level="body-xs">
            처리 완료시까지 페이지를 닫지 마세요!
          </Typography>
        )}
      </Stack>
    </FormModal>
  );
}

function DeleteAllModal({
  open,
  handleClose,
  handleDeleteAll,
}: {
  open: boolean;
  handleClose: () => void;
  handleDeleteAll: () => void;
}) {
  return (
    <FormModal
      open={open}
      title="좌석/구조물을 전부 지울까요?"
      icon={<FiAlertTriangle />}
      actions={[
        <Button
          key="cancel"
          color="neutral"
          variant="plain"
          onClick={handleClose}
        >
          아니오
        </Button>,
        <Button
          key="delete"
          color="danger"
          onClick={() => {
            handleDeleteAll();
            handleClose();
          }}
        >
          네
        </Button>,
      ]}
    />
  );
}

function HiddenRect({
  x,
  y,
  mouseDownPoint,
  customPropsGen,
}: Point & {
  mouseDownPoint: React.Dispatch<React.SetStateAction<Point | null>>;
  customPropsGen: (pos: Point) => any;
}) {
  const { size, gap } = seatConfig;

  const left = (size + gap) * (x - 1);
  const top = (size + gap) * (y - 1);

  return (
    <Box
      sx={{
        width: `${size + gap}px`,
        height: `${size + gap}px`,
        position: "absolute",
        left: `${left}px`,
        top: `${top}px`,
        opacity: 0,
      }}
      onMouseDown={() => mouseDownPoint({ x, y })}
      onMouseUp={() => mouseDownPoint(null)}
      {...customPropsGen({ x, y })}
    />
  );
}

function Shadow({
  x,
  y,
  w = 1,
  h = 1,
  color,
}: Point & { w?: number; h?: number; color: string }) {
  const { size, gap } = seatConfig;

  const left = size * (x - 1) + gap * (x - 0.5);
  const top = size * (y - 1) + gap * (y - 0.5);
  const width = w * size + (w - 1) * gap;
  const height = h * size + (h - 1) * gap;

  return (
    <Box
      sx={{
        width: `${width}px`,
        height: `${height}px`,
        position: "absolute",
        left: `${left}px`,
        top: `${top}px`,
        opacity: 0.4,
        background: color,
      }}
    />
  );
}

const getNewSeatName = (prevSeats: { name: string }[]) => {
  const numberNames = prevSeats.map((seat) => {
    const num = +seat.name;
    return num;
  });

  let firstAvaliableNumber = 1;
  while (true) {
    if (!numberNames.includes(firstAvaliableNumber)) break;
    firstAvaliableNumber++;
  }

  return `${firstAvaliableNumber}`;
};

const shadowColor: { [key in Mode]: string } = {
  normal: "#207FDE",
  seat: "rgba(128, 128, 128)",
  object: "#9a5b13",
  remove: "#c41c1c",
};

const defaultProgress: SubmitProgress = { progress: -1, label: "" };
